import { autoUpdate, flip, offset, Placement, size, useFloating } from '@floating-ui/react-dom';
import clamp from 'lodash/clamp';
import { CSSProperties, RefObject, useCallback, useEffect, useState } from 'react';

import useSyncedRef from 'hooks/useSyncedRef';

interface Dimensions {
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
}

export default function usePopupMenu({
  onOpen: externalOnOpen,
  onClose: externalOnClose,
  onBlur: externalOnBlur,
  menuOffset = 0,
  syncWidth = true,
  placement = 'bottom-start',
  ...dimentions
}: {
  onOpen?: () => void;
  onClose?: () => void;
  onBlur?: () => void;
  menuOffset?: number;
  syncWidth?: boolean;
  placement?: Placement;
} & Dimensions) {
  const [isOpen, setIsOpen] = useState(false);

  const externalOnOpenRef = useSyncedRef(externalOnOpen);
  const externalOnCloseRef = useSyncedRef(externalOnClose);
  const externalOnBlurRef = useSyncedRef(externalOnBlur);

  const {
    reference: setActuator,
    floating: setMenu,
    strategy,
    placement: actualPlacement,
    middlewareData,
    x,
    y,
    refs: { reference: actuatorRef, floating: menuRef },
  } = usePositioning(dimentions, placement, menuOffset, syncWidth);

  const open = useCallback(() => {
    setIsOpen(true);
    externalOnOpenRef.current?.();
  }, [externalOnOpenRef]);

  const close = useCallback(() => {
    setIsOpen(false);
    externalOnCloseRef.current?.();
  }, [externalOnCloseRef]);

  const blur = useCallback(() => {
    externalOnBlurRef.current?.();
  }, [externalOnBlurRef]);

  useCloseOnFocusLeave({
    onClose: close,
    onBlur: blur,
    actuatorRef,
    menuRef,
    isOpen,
  });

  const isFlipped = actualPlacement !== placement && middlewareData.flip?.index !== undefined;

  const menuStyles: CSSProperties = {
    position: strategy,
    top: 0,
    left: 0,
    transform: x !== null && y !== null ? `translate(${x}px, ${y}px)` : undefined,
    zIndex: 'var(--z-input-popup)',

    width: 'auto',
    maxHeight: 1,
    maxWidth: 1,
    overflow: 'hidden auto',

    backgroundColor: 'var(--white)',
    border: '1px solid var(--gray-light)',
    borderRadius: 4,
    boxShadow: `${!isFlipped ? '0 4px 10px' : '0 -1px 10px'} hsl(var(--black-h) var(--black-s) var(--black-l) / 0.2)`,
  };

  return {
    setActuator,
    setMenu,
    actuatorRef,
    menuRef,
    menuStyles,
    isOpen,
    isFlipped,
    open,
    close,
  };
}

function usePositioning(
  { minWidth = 224, maxWidth = 512, minHeight = 224, maxHeight = 512 }: Dimensions,
  placement: Placement,
  menuOffset: number,
  syncWidth: boolean
) {
  return useFloating<HTMLElement>({
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    placement,
    middleware: [
      offset({ mainAxis: menuOffset, alignmentAxis: -1 }),
      flip(),
      size({
        apply({ availableWidth, availableHeight, elements, rects }) {
          const referenceWidth = rects.reference.width;
          const isVisible = getComputedStyle(elements.floating).getPropertyValue('display') !== 'none';
          if (!isVisible) return;

          Object.assign(elements.floating.style, {
            maxWidth: `${clamp(syncWidth ? referenceWidth : availableWidth, minWidth, maxWidth)}px`,
            maxHeight: `${clamp(availableHeight, minHeight, maxHeight)}px`,
          });
        },
      }),
    ],
  });
}

function useCloseOnFocusLeave({
  onClose,
  onBlur,
  actuatorRef,
  menuRef,
  isOpen,
}: {
  onClose: () => void;
  onBlur: () => void;
  actuatorRef: RefObject<HTMLElement>;
  menuRef: RefObject<HTMLElement>;
  isOpen: boolean;
}) {
  useEffect(() => {
    const actuator = actuatorRef.current;
    const menu = menuRef.current;

    if (!actuator || !menu) return;

    const onFocusOut = (event: FocusEvent) => {
      const focusedElement = (event.relatedTarget || document.body) as HTMLElement;

      if (!menu.contains(focusedElement)) {
        if (!actuator.contains(focusedElement)) {
          if (isOpen) onClose();
          onBlur();
        } else {
          requestAnimationFrame(() => actuator.focus());
        }
      }
    };

    actuator.addEventListener('focusout', onFocusOut);
    menu.addEventListener('focusout', onFocusOut);

    return () => {
      actuator.removeEventListener('focusout', onFocusOut);
      menu.removeEventListener('focusout', onFocusOut);
    };
  }, [actuatorRef, menuRef, isOpen, onClose, onBlur]);
}
