import { ReactElement, useEffect } from 'react';

import { Capabilities, useCapabilities } from 'hooks/useConfig';

import Loading from 'components/Loading';

export default function CapabilitiesProvider({
  children,
  mode,
}: {
  children: ReactElement;
  mode: Capabilities['mode'];
}) {
  const { capabilities, isLoading, isFetching, refetch } = useCapabilities();

  useEffect(() => {
    if (!isFetching && capabilities?.mode && capabilities?.mode !== mode) {
      refetch();
    }
  }, [isFetching, capabilities?.mode, mode, refetch]);

  return isLoading || !capabilities || mode !== capabilities.mode ? <Loading /> : children;
}
