import { defineMessages, IntlShape } from 'react-intl';

export const long = defineMessages({
  sunday: { id: 'date_weekday_long_sunday', defaultMessage: 'Sunday' },
  monday: { id: 'date_weekday_long_monday', defaultMessage: 'Monday' },
  tuesday: { id: 'date_weekday_long_tuesday', defaultMessage: 'Tuesday' },
  wednesday: { id: 'date_weekday_long_wednesday', defaultMessage: 'Wednesday' },
  thursday: { id: 'date_weekday_long_thursday', defaultMessage: 'Thursday' },
  friday: { id: 'date_weekday_long_friday', defaultMessage: 'Friday' },
  saturday: { id: 'date_weekday_long_saturday', defaultMessage: 'Saturday' },
});

export const short = defineMessages({
  sunday: { id: 'date_weekday_short_sun', defaultMessage: 'Sun' },
  monday: { id: 'date_weekday_short_mon', defaultMessage: 'Mon' },
  tuesday: { id: 'date_weekday_short_tue', defaultMessage: 'Tue' },
  wednesday: { id: 'date_weekday_short_wed', defaultMessage: 'Wed' },
  thursday: { id: 'date_weekday_short_thu', defaultMessage: 'Thu' },
  friday: { id: 'date_weekday_short_fri', defaultMessage: 'Fri' },
  saturday: { id: 'date_weekday_short_sat', defaultMessage: 'Sat' },
});

function shiftOrderByStartOfWeek(list: any[], weekDayOffset: WeekDay) {
  const beginingOfWeek = list.splice(weekDayOffset);
  const endOfWeek = list.slice(0, weekDayOffset);

  return [...beginingOfWeek, ...endOfWeek];
}

function translate(intl: IntlShape, translations) {
  return Object.keys(translations).map<string>((weekDay) => intl.formatMessage(translations[weekDay]));
}

function weekDaysOptions(intl: IntlShape, translations, weekDayOffset: WeekDay) {
  const days = Object.keys(translations);
  const sortedDays = shiftOrderByStartOfWeek(days, weekDayOffset);

  return sortedDays.map<OptionT>((day) => ({
    label: intl.formatMessage(translations[day]),
    value: day,
  }));
}

export const weekDaysLong = (intl: IntlShape) => translate(intl, long);

export const weekDaysShort = (intl: IntlShape) => translate(intl, short);

export const sortedWeekDaysLong = (intl: IntlShape, weekDayOffset: WeekDay) =>
  shiftOrderByStartOfWeek(weekDaysLong(intl), weekDayOffset);

export const sortedWeekDaysShort = (intl: IntlShape, weekDayOffset: WeekDay) =>
  shiftOrderByStartOfWeek(weekDaysShort(intl), weekDayOffset);

export const weekDaysLongOptions = (intl: IntlShape, weekDayOffset: WeekDay) =>
  weekDaysOptions(intl, long, weekDayOffset);

export const weekDaysShortOptions = (intl: IntlShape, weekDayOffset: WeekDay) =>
  weekDaysOptions(intl, short, weekDayOffset);
