import { useEffect, useState } from 'react';

import { LocalStorage } from 'utils/storage';

const narrowSidebarWidth = 60;
const wideSidebarWidth = 240;

export default function useSidebarSettings() {
  const [isWide, setIsWide] = useState(LocalStorage.get<boolean>('wide_sidebar') || false);

  const toggleIsWide = () => {
    setIsWide((value) => !value);
  };

  useEffect(() => {
    LocalStorage.set<boolean>('wide_sidebar', isWide);

    document.documentElement.style.setProperty(
      '--size-sidebar-width',
      isWide ? `${wideSidebarWidth}px` : `${narrowSidebarWidth}px`
    );
  }, [isWide]);

  return {
    isWide,
    toggleIsWide,
  };
}
