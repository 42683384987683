import { getIn } from 'formik';

import { camelize } from 'utils/camelize';

import { DependencyT, FieldT, FormT, TabularFieldT } from 'components/Form/schema';

import deserialize from './deserialize';
import parseDate from './parseDate';
import parseDateTime from './parseDateTime';

export default function parseForm(
  form: FormT,
  options?: { shouldCamelizeId?: boolean; values?: Record<string, any> | null | undefined }
): FormT {
  const { values, shouldCamelizeId = true } = options || {};

  return deserialize(form, {
    'sections[].id': deserializeId,
    'sections[].fields[]': deserializeField,
  });

  function deserializeField<T extends FieldT>(field: T): T {
    return deserialize(field, {
      'id': deserializeId,
      'value': (value) => deserializeValue(value, field),
      'dependency': deserializeDependency,
      'columns[]': deserializeField,
      'options[].environment[].id': deserializeId,
    });
  }

  function deserializeValue<T extends FieldT>(value: any, field: T) {
    value = values ? getIn(values, field.id) : value;

    switch (field.type) {
      case 'date':
        return parseDate(value) || null;

      case 'time':
      case 'date_time':
        return parseDateTime(value) || null;

      case 'boolean':
        return typeof value === 'string' ? value === 'true' : value;

      case 'tabular':
        return (value as TabularFieldT['value']).map((row) =>
          row.map((val, i) => deserializeValue(val, field.columns[i]))
        );

      default:
        return value;
    }
  }

  function deserializeDependency<T extends DependencyT>(dep: T | null): T | null {
    if (dep === null) return null;

    switch (dep.type) {
      case 'not':
      case 'eq':
      case 'present':
      case 'contains':
      case 'except':
        return deserialize(dep, { 'args[0]': camelize });

      case 'or':
      case 'and':
        return deserialize(dep, { 'args[]': deserializeDependency });

      default:
        return dep;
    }
  }

  function deserializeId(id: any) {
    return shouldCamelizeId ? camelize(id) : id;
  }
}
