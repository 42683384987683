import { useAppSelector } from 'hooks/redux';

import Flash from 'components/Flash';
import Section from 'components/Section';

type Props = {
  substituteMessage?: string;
  close?: boolean;
};

export default function ErrorFlash({ close = true, substituteMessage }: Props) {
  const activity = useAppSelector((state) => state.activity);
  const errors = useAppSelector((state) => state.errors);

  if (!activity.name && errors.length > 0 && errors.find((e) => e.handlerType === 'form')) {
    return (
      <>
        {errors.map(({ detail }, index) => (
          <Section key={index} withoutPadding>
            <Flash close={close} type="danger">
              {detail || substituteMessage || ''}
            </Flash>
          </Section>
        ))}
      </>
    );
  }

  return null;
}
