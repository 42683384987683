import parseDate from './parseDate';

export default function parseDateTime(date: unknown) {
  if (date === null) return null;
  if (typeof date !== 'string' && !(date instanceof Date)) return undefined;
  if (typeof date === 'string' && !date.includes('T')) return parseDate(date);

  const localDate = new Date(date);
  if (localDate.toString() === 'Invalid Date') return undefined;

  return localDate;
}
