import { ChangeEvent as ReactChangeEvent, ReactNode, WheelEvent as ReactWheelEvent, useEffect, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import { PrecisionT } from 'components/Form/schema';

import { Affix } from './shared';
import { BaseInputProps } from './types';

type BaseProps = BaseInputProps<number>;

type CustomProps = {
  prefix?: ReactNode;
  suffix?: MessageDescriptor | ReactNode;
  precision?: PrecisionT | number;
  autoComplete?: boolean;
  placeholder?: MessageDescriptor | string;
};

type HtmlProps = Omit<JSX.IntrinsicElements['input'], keyof BaseProps | keyof CustomProps | 'name'>;

export type FloatInputProps = BaseProps & CustomProps & HtmlProps;

export default function FloatInput({
  defaultValue,
  value: externalValue,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  id,
  disabled = false,
  autoFocus = false,
  autoComplete = false,
  prefix,
  suffix,
  precision,
  placeholder: rawPlaceholder,
}: FloatInputProps) {
  const { formatMessage } = useIntl();

  const isControlled = !!externalOnChange;

  const [value, setValue] = useState(isControlled ? externalValue ?? null : defaultValue ?? null);

  useEffect(() => {
    if (isControlled && value !== externalValue) {
      setValue(externalValue ?? null);
    }
  }, [isControlled, value, externalValue]);

  const onChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const float = parseFloat(inputValue);
    const val = Number.isNaN(float) ? null : float;

    setValue(val);
    externalOnChange?.(val);
  };

  const onBlur = () => {
    if (value !== null && precision) {
      const fractionalLength =
        typeof precision === 'number' ? String(precision).split('.')[1].length : precision.length;

      const rounded = parseFloat(value.toFixed(fractionalLength));

      if (value !== rounded) {
        setValue(rounded);
        externalOnChange?.(rounded);
      }
    }

    externalOnBlur?.();
  };

  const step = (() => {
    if (typeof precision === 'number') return precision;

    if (precision === '#') return 0.1;
    if (precision === '##') return 0.01;
    if (precision === '###') return 0.001;

    return 0.1;
  })();

  const formattedPlaceholder = isMessageDescriptor(rawPlaceholder) ? formatMessage(rawPlaceholder) : rawPlaceholder;

  // Firefox uses lang attribute first to determine decimal separator,
  // then `Accept-Language` of responses lastly the browser's language
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#localization

  // Chrome uses different settings to determine the decimal separator according to OS
  // https://developer.chrome.com/docs/extensions/reference/i18n/#how-to-set-browsers-locale

  // Microsoft Edge uses the region of the OS to determine the decimal separator.
  // i.e. If you have english language but belgian region the decimal separator will be `,`

  return (
    <div className="base-input -type-float">
      {prefix ? <Affix prefix>{prefix}</Affix> : null}
      <input
        id={id}
        type="number"
        value={value !== null ? String(value) : ''}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        autoFocus={autoFocus}
        autoComplete={autoComplete ? 'on' : 'off'}
        placeholder={rawPlaceholder ? formattedPlaceholder : undefined}
        step={step}
        onWheel={onWheel}
      />
      {suffix ? <Affix suffix>{isMessageDescriptor(suffix) ? formatMessage(suffix) : suffix}</Affix> : null}
    </div>
  );
}

function onWheel(event: ReactWheelEvent<HTMLInputElement>) {
  event.currentTarget.blur();
}
