import { FunctionComponent } from 'react';

import { CLASSNAME } from '../constants';

export interface PlaceholderProps {
  placeholder: string;
}

export const Placeholder: FunctionComponent<PlaceholderProps> = ({ placeholder }) => {
  return <span className={`${CLASSNAME}__placeholder`}>{placeholder}</span>;
};
