import { NormalizedSchema, schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { registerFormErrorAction } from 'store/modules/errors';
import { networkActionTypes } from 'store/utils';

import { LabObservationType, LabResult } from 'sharedComponents/LabResultsGrid';

import { TherapyPlanType } from 'views/settings/clinicalSettings/therapyPlanTypes/fetchers';

const labResultSchema = new schema.Entity(
  'labResults',
  {},
  {
    processStrategy: schemaDeserializers({
      effectiveDateTime: parseDateTime,
    }),
  }
);

const observationTypeSchema = new schema.Entity('observationTypes');

const therapyPlanTypeSchema = new schema.Entity(
  'therapyPlanTypes',
  {},
  {
    processStrategy: schemaDeserializers({
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

const therapyPlanSchema = new schema.Entity(
  'therapyPlans',
  {
    therapyPlanType: therapyPlanTypeSchema,
  },
  {
    processStrategy: schemaDeserializers({
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

const therapyPlanCalendarDateEntrySchema = new schema.Entity('therapyPlanCalendarDateEntries');

const therapyPlanCalendarDateSchema = new schema.Entity(
  'therapyPlanCalendarDates',
  {
    entries: [therapyPlanCalendarDateEntrySchema],
  },
  {
    idAttribute(value, parent) {
      return `${parent.therapyPlanId}-${value.date}`;
    },
  }
);

const therapyPlanCalendarSchema = new schema.Entity(
  'therapyPlanCalendars',
  {
    dates: [therapyPlanCalendarDateSchema],
  },
  {
    idAttribute: 'therapyPlanId',
  }
);

export const GET_MEDICINAL_THERAPY_PLANS = networkActionTypes('GET_MEDICINAL_THERAPY_PLANS');

export type GetMedicinalTherapyPlansResponse = NetworkSuccessResponse<
  NormalizedSchema<
    {
      therapyPlans: Record<string, TherapyPlanT>;
      therapyPlanTypes: Record<string, TherapyPlanType>;
    },
    { therapyPlans: string[] }
  >,
  { patientId: string }
>;

export function getMedicinalTherapyPlans(patientId: string, params?: { therapyPlanType?: string }): NetworkAction {
  return {
    type: 'CALL_API',
    payload: {
      types: GET_MEDICINAL_THERAPY_PLANS,
      method: 'GET',
      url: `patients/${patientId}/therapy_plans`,
      params,
      actionPayload: { patientId },
      normalizeSchema: { therapyPlans: [therapyPlanSchema] },
    },
  };
}

export const GET_MEDICINAL_THERAPY_PLAN = networkActionTypes('GET_MEDICINAL_THERAPY_PLAN');

export type GetMedicinalTherapyPlanResponse = NetworkSuccessResponse<
  NormalizedSchema<
    {
      therapyPlans: Record<string, TherapyPlanT>;
      therapyPlanTypes: Record<string, TherapyPlanType>;
    },
    { therapyPlan: string }
  >,
  { patientId: string }
>;

export function getMedicinalTherapyPlan(patientId: string, therapyPlanId: string): NetworkAction {
  return {
    type: 'CALL_API',
    payload: {
      types: GET_MEDICINAL_THERAPY_PLAN,
      method: 'GET',
      url: `therapy_plans/${therapyPlanId}`,
      actionPayload: { patientId },
      normalizeSchema: { therapyPlan: therapyPlanSchema },
    },
  };
}

export const CREATE_MEDICINAL_THERAPY_PLAN = networkActionTypes('CREATE_MEDICINAL_THERAPY_PLAN');

registerFormErrorAction(CREATE_MEDICINAL_THERAPY_PLAN.FAILURE);

export type CreateMedicinalTherapyPlanResponse = NetworkSuccessResponse<
  NormalizedSchema<{ therapyPlans: Record<string, TherapyPlanT> }, { therapyPlan: string }>,
  { patientId: string }
>;

export function createMedicinalTherapyPlan(patientId: string, therapyPlanTypeId: string): NetworkAction {
  return {
    type: 'CALL_API',
    payload: {
      types: CREATE_MEDICINAL_THERAPY_PLAN,
      method: 'POST',
      url: `patients/${patientId}/therapy_plans`,
      payload: { therapyPlan: { therapyPlanTypeId } },
      actionPayload: { patientId },
      normalizeSchema: { therapyPlan: therapyPlanSchema },
    },
  };
}

export type StopMedicinalTherapyPlanResponse = NetworkSuccessResponse<
  undefined,
  { patientId: string; therapyPlanId: string }
>;

export const STOP_MEDICINAL_THERAPY_PLAN = networkActionTypes('STOP_MEDICINAL_THERAPY_PLAN');

export function stopMedicinalTherapyPlan(
  patientId: string,
  therapyPlanId: string,
  options?: { stopIntradialyticPrescriptions: boolean }
): NetworkAction {
  return {
    type: 'CALL_API',
    payload: {
      types: STOP_MEDICINAL_THERAPY_PLAN,
      method: 'POST',
      url: `therapy_plans/${therapyPlanId}/stop`,
      payload: {
        stopIntradialyticPrescriptions: options?.stopIntradialyticPrescriptions || false,
      },
      actionPayload: { patientId, therapyPlanId },
    },
  };
}

export const DELETE_MEDICINAL_THERAPY_PLAN = networkActionTypes('DELETE_MEDICINAL_THERAPY_PLAN');

export function deleteMedicinalTherapyPlan(patientId: string, therapyPlanId: string): NetworkAction {
  return {
    type: 'CALL_API',
    payload: {
      types: DELETE_MEDICINAL_THERAPY_PLAN,
      method: 'DELETE',
      url: `patients/${patientId}/therapy_plans/${therapyPlanId}`,
    },
  };
}

export const GET_MEDICINAL_THERAPY_PLAN_CALENDAR = networkActionTypes('GET_MEDICINAL_THERAPY_PLAN_CALENDAR');

export type GetMedicinalTherapyPlanCalendarResponse = NetworkSuccessResponse<
  NormalizedSchema<
    {
      therapyPlanCalendars: Record<string, TherapyPlanCalendarT>;
      therapyPlanCalendarDates: Record<string, TherapyPlanCalendarDateT>;
      therapyPlanCalendarDateEntries: Record<string, TherapyPlanCalendarDateEntryT>;
    },
    { therapyPlanCalendar: string }
  >
>;

export function getMedicinalTherapyPlanCalendar(
  patientId: string,
  therapyPlanId: string,
  startDate: Date,
  endDate: Date
): NetworkAction {
  return {
    type: 'CALL_API',
    payload: {
      types: GET_MEDICINAL_THERAPY_PLAN_CALENDAR,
      method: 'GET',
      url: `therapy_plans/${therapyPlanId}/calendar`,
      params: { startDate, endDate },
      normalizeSchema: { therapyPlanCalendar: therapyPlanCalendarSchema },
    },
  };
}

export const GET_MEDICINAL_THERAPY_PLAN_LAB_RESULTS = networkActionTypes('GET_MEDICINAL_THERAPY_PLAN_LAB_RESULTS');

export type GetMedicinalTherapyPlanLabResultsResponse = NetworkSuccessResponse<
  NormalizedSchema<
    {
      labResults: Record<string, LabResult>;
      observationTypes: Record<string, LabObservationType>;
    },
    { results: string[]; observationTypes: string[] }
  >,
  { patientId: string; therapyPlanId: string }
>;

export function getMedicinalTherapyPlanLabResults(patientId: string, therapyPlanId: string): NetworkAction {
  return {
    type: 'CALL_API',
    payload: {
      types: GET_MEDICINAL_THERAPY_PLAN_LAB_RESULTS,
      method: 'GET',
      url: `therapy_plans/${therapyPlanId}/lab_results?period=3_months`,
      actionPayload: { patientId, therapyPlanId },
      normalizeSchema: {
        results: [labResultSchema],
        observationTypes: [observationTypeSchema],
      },
    },
  };
}
