import { FunctionComponent, ReactNode } from 'react';

import { ClearIcon } from 'components/Inputs/shared';

import { CLASSNAME } from '../constants';
import { SelectInputOption } from '../types';

export interface MultiValueProps {
  option: SelectInputOption;
  deselectOption: () => void;
  children?: ReactNode;
}

export const MultiValue: FunctionComponent<MultiValueProps> = ({ option, deselectOption, children }) => {
  return (
    <div className={`${CLASSNAME}__multi-value`}>
      {children || <span>{option.label}</span>}
      {!option.disabled ? (
        <div
          className={`${CLASSNAME}__multi-value__clear-icon`}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            deselectOption();
          }}
        >
          <ClearIcon />
        </div>
      ) : null}
    </div>
  );
};
