import { useQuery } from '@tanstack/react-query';
import { acknowledgements } from 'acknowledgements.json';
import chunk from 'lodash/chunk';
import { ReactElement, useRef, useState } from 'react';
import { defineMessages, FormattedDate, FormattedMessage } from 'react-intl';

import { http } from 'api/client';

import { useRecordEnabled } from 'hooks/useConfig';
import useFeatureFlag from 'hooks/useFeatureFlag';

import Button from 'components/Button';
import Grid from 'components/Grid';
import { ToggleInput } from 'components/Inputs';
import Modal from 'components/Modal';
import { Pages } from 'components/Paginator';
import Section from 'components/Section';
import Stackable from 'components/Stackable';
import Table from 'components/Table';
import TableActiveCell from 'components/TableActiveCell';
import Tabs from 'components/Tabs';

import { ReactComponent as CeIndicator } from 'assets/images/ce.svg';
import { ReactComponent as DateOfManufactureIndicator } from 'assets/images/date-of-manufacture.svg';
import { ReactComponent as ManufacturerIndicator } from 'assets/images/manufacturer.svg';

import { company, version } from '../../../../package.json';

export default function InfoModal({ show, cancel }: { show: boolean; cancel: () => void }) {
  return (
    <Modal show={show} size="large" onClose={cancel}>
      <Modal.Header>
        <Modal.Title>{`NephroFlow Manager ${version}`}</Modal.Title>
        <Modal.CloseButton onClick={cancel} />
      </Modal.Header>

      <Modal.Body contentClassName="without-padding">
        <ModalContent />
      </Modal.Body>
    </Modal>
  );
}

const TABS = {
  MOD: 'moduleInformation',
  ACK: 'acknowledgements',
  FLGS: 'featureFlags',
};

function ModalContent() {
  const [tab, setTab] = useState(TABS.MOD);

  const modalContentRef = useRef<HTMLDivElement>(null);

  const onLoadAcknowledgmentsPage = () => {
    modalContentRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    });
  };

  return (
    <div className="info-modal" ref={modalContentRef}>
      <Stackable>
        <Tabs.List>
          <Tabs.Tab id={TABS.MOD} active={tab === TABS.MOD} onClick={() => setTab(TABS.MOD)}>
            <FormattedMessage {...t.moduleInformation} />
          </Tabs.Tab>

          <Tabs.Tab id={TABS.ACK} active={tab === TABS.ACK} onClick={() => setTab(TABS.ACK)}>
            <FormattedMessage {...t.acknowledgements} />
          </Tabs.Tab>

          <Tabs.Tab id={TABS.FLGS} active={tab === TABS.FLGS} onClick={() => setTab(TABS.FLGS)}>
            <FormattedMessage {...t.featureFlags} />
          </Tabs.Tab>
        </Tabs.List>
      </Stackable>

      <Tabs.Panels>
        <Tabs.Panel id={TABS.MOD} active={tab === TABS.MOD}>
          <ModuleInformation />
        </Tabs.Panel>

        <Tabs.Panel id={TABS.ACK} active={tab === TABS.ACK}>
          <Acknowledgments packages={acknowledgements} onLoadPage={onLoadAcknowledgmentsPage} />
        </Tabs.Panel>

        <Tabs.Panel id={TABS.FLGS} active={tab === TABS.FLGS}>
          <FeatureFlags />
        </Tabs.Panel>
      </Tabs.Panels>
    </div>
  );
}

interface PackageT {
  name: string;
  version: string;
  repository?: string;
  license: string | string[];
}

function Acknowledgments({ packages, onLoadPage }: { packages?: PackageT[]; onLoadPage: () => void }) {
  const chunkedPackages = chunk(packages, 100);
  const [page, setPage] = useState(0);

  return (
    <>
      <Table
        isSticky
        headers={[t.packageLabel, t.versionLabel, t.licenseLabel]}
        items={chunkedPackages[page] || []}
        fixedColumnWidths={['2fr', '1fr', '1fr']}
        renderItem={({ name, repository, version, license }) => (
          <tr key={`${name}_${version}`}>
            <td>
              <a href={repository} rel="noopener noreferrer" target="_blank">
                {name}
              </a>
            </td>
            <td>{version}</td>
            <td>{Array.isArray(license) ? license.join(', ') : license}</td>
          </tr>
        )}
      />

      <Pages
        currentPage={page}
        pageSize={100}
        totalCount={packages?.length || 0}
        totalPages={chunkedPackages.length}
        loadPage={(page) => {
          setPage(page);
          onLoadPage();
        }}
      />
    </>
  );
}

const MODULES = {
  MAN: 'manager',
  SER: 'server',
  KTV: 'ktv',
  UFV: 'ufv',
};

function ModuleInformation() {
  const [module, setModule] = useState(MODULES.MAN);

  const items = {
    [MODULES.MAN]: <FormattedMessage {...t.manager} />,
    [MODULES.SER]: <FormattedMessage {...t.server} />,
    [MODULES.KTV]: <FormattedMessage {...t.ktvModule} />,
    [MODULES.UFV]: <FormattedMessage {...t.ufvModule} />,
  };

  return (
    <Grid columns="1fr 2fr" withoutMargin>
      <Grid.Item style={{ borderRight: '1px solid var(--gray-lighter)' }}>
        <Section withoutPadding>
          <Table
            isSticky
            headers={[t.module]}
            items={Object.entries(items)}
            renderItem={([value, label]) => (
              <tr key={value} onClick={() => setModule(value)} className="is-clickable">
                <TableActiveCell isActive={value === module}>{label}</TableActiveCell>
              </tr>
            )}
          />
        </Section>
      </Grid.Item>

      <Grid.Item>
        <Section>
          <Modules module={module} items={items} />
        </Section>
      </Grid.Item>
    </Grid>
  );
}

function Modules({ module, items }: { module: string; items: Record<string, ReactElement> }) {
  const { data: versionInfo } = useVersionInfo();
  const recordEnabled = useRecordEnabled();

  switch (module) {
    case MODULES.MAN:
      return <ModuleDetail title={items[MODULES.MAN]} version={version} />;

    case MODULES.SER:
      return <ModuleDetail title={items[MODULES.SER]} version={versionInfo?.version} />;

    case MODULES.KTV:
      return (
        <ModuleDetail
          title={items[MODULES.KTV]}
          date={versionInfo?.ktv.dateOfManufacture}
          version={versionInfo?.ktv.version}
          showCEMark={recordEnabled}
        />
      );

    case MODULES.UFV:
      return (
        <ModuleDetail
          title={items[MODULES.UFV]}
          date={versionInfo?.ufv.dateOfManufacture}
          version={versionInfo?.ufv.version}
          showCEMark={recordEnabled}
        />
      );

    default:
      return null;
  }
}

function ModuleDetail({
  title,
  date,
  version,
  showCEMark = false,
}: {
  title: ReactElement;
  date?: string | undefined;
  version: string | undefined;
  showCEMark?: boolean;
}) {
  return (
    <>
      <div className="flex flex-row">
        <strong>{title}</strong>
      </div>

      <div className="flex flex-row">
        <FormattedMessage {...t.version} values={{ version: version || 'x.x.x' }} />
      </div>

      <div className="flex flex-row justify-start items-start pt-4">
        <div className="flex" style={{ width: '50px' }}>
          <ManufacturerIndicator style={{ width: 20 }} />
        </div>

        <div style={{ textAlign: 'left' }}>
          {company.name}
          <br />
          {company.address}
          <br />
          {company.postalCode} <FormattedMessage {...t.bruges} />, <FormattedMessage {...t.belgium} />
          <br />
          <FormattedMessage {...t.rpr} /> {company.rpr}
        </div>
      </div>

      {date ? (
        <div className="flex flex-row justify-start items-center pt-4">
          <div className="flex" style={{ width: '50px' }}>
            <DateOfManufactureIndicator style={{ width: 20 }} />
          </div>

          <div>
            {/* YYYY-MM-DD string format */}
            <FormattedDate value={date} />
          </div>
        </div>
      ) : null}

      {showCEMark ? (
        <div className="flex flex-row justify-start items-center pt-4">
          <div className="flex" style={{ width: '50px' }}>
            <CeIndicator style={{ width: 20 }} />
          </div>

          <div>
            <FormattedMessage {...t.ceMark} />
          </div>
        </div>
      ) : null}
    </>
  );
}

function FeatureFlags() {
  const { flags, isEnabled, setFlag } = useFeatureFlag();

  return (
    <>
      <Table
        tableWrapperStyle={{ borderBottom: '1px solid var(--gray-lighter)' }}
        headers={[t.feature, t.enabled]}
        items={flags}
        renderItem={(flag) => (
          <tr key={flag}>
            <td>{flag}</td>
            <td>
              <ToggleInput id={flag} value={isEnabled(flag)} onChange={(value) => setFlag(flag, value)} />
            </td>
          </tr>
        )}
      />

      <Button importance="secondary" className="mt-4 mb-4" onClick={() => window.location.reload()}>
        {t.reload}
      </Button>
    </>
  );
}

interface VersionInfo {
  version: string;
  ktv: {
    version: string;
    dateOfManufacture: string;
  };
  ufv: {
    version: string;
    dateOfManufacture: string;
  };
}

function useVersionInfo() {
  return useQuery(
    ['versionInfo'],
    async () => {
      const { data } = await http.get<VersionInfo>('version_info');

      return data;
    },
    {
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );
}

const t = defineMessages({
  bruges: {
    id: 'app_info_modal_bruges',
    defaultMessage: 'Bruges',
  },
  belgium: {
    id: 'app_info_modal_belgium',
    defaultMessage: 'Belgium',
  },
  userDocumentation: {
    id: 'app_info_modal_user_documentation',
    defaultMessage: 'User documentation',
  },
  acknowledgements: {
    id: 'app_info_modal_acknowledgements',
    defaultMessage: 'Acknowledgments',
  },
  featureFlags: {
    id: 'app_info_modal_feature_flags',
    defaultMessage: 'Feature Flags',
  },
  moduleInformation: {
    id: 'app_info_modal_module_information',
    defaultMessage: 'Module information',
  },
  detail: {
    id: 'app_info_modal_detail',
    defaultMessage: 'Detail',
  },
  ceMark: {
    id: 'app_info_modal_ce_mark',
    defaultMessage: 'European conformity marking',
  },
  rpr: {
    id: 'app_info_modal_rpr',
    defaultMessage: 'RPR',
  },
  component: {
    id: 'app_info_modal_component',
    defaultMessage: 'Component',
  },
  module: {
    id: 'app_info_modal_module',
    defaultMessage: 'Module',
  },
  version: {
    id: 'app_info_modal_version',
    defaultMessage: 'Version {version}',
  },
  versionLabel: {
    id: 'app_info_modal_version_label',
    defaultMessage: 'Version',
  },
  packageLabel: {
    id: 'app_info_modal_npm_package',
    defaultMessage: 'Package',
  },
  licenseLabel: {
    id: 'app_info_modal_license',
    defaultMessage: 'License',
  },
  manager: {
    id: 'app_info_modal_manager',
    defaultMessage: 'NephroFlow Manager',
  },
  server: {
    id: 'app_info_modal_server',
    defaultMessage: 'NephroFlow API Server',
  },
  ktvModule: {
    id: 'app_info_modal_ktv_module',
    defaultMessage: 'NephroFlow Kt/V Module',
  },
  ufvModule: {
    id: 'app_info_modal_ufv_module',
    defaultMessage: 'NephroFlow UFV Module',
  },
  feature: {
    id: 'app_info_modal_feature',
    defaultMessage: 'Feature',
  },
  enabled: {
    id: 'app_info_modal_enabled',
    defaultMessage: 'Enabled?',
  },
  reload: {
    id: 'app_info_modal_reload',
    defaultMessage: 'Reload',
  },
});
