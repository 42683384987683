import { Dispatch, MutableRefObject, useEffect } from 'react';

import { CancelablePromise } from 'hooks/useCancelableAsyncFn';

import { Action, State } from '../state';
import { Value } from '../types';

export function useStopCreating({
  dispatch,
  state,
  internalValue,
  cleanInputValue,
  cancelableCreateOptionControllerRef,
  cancelableCreateOptionPromiseRef,
}: {
  dispatch: Dispatch<Action>;
  state: State;
  internalValue: Value | null;
  cleanInputValue: string;
  cancelableCreateOptionControllerRef: MutableRefObject<AbortController | undefined>;
  cancelableCreateOptionPromiseRef: MutableRefObject<CancelablePromise<any> | undefined>;
}) {
  useEffect(() => {
    dispatch({ type: 'STOP_CREATING_OPTION' });
    cancelableCreateOptionControllerRef.current?.abort();
    cancelableCreateOptionPromiseRef.current?.cancel();
  }, [
    dispatch,
    cancelableCreateOptionControllerRef,
    cancelableCreateOptionPromiseRef,

    // Re-run when these change
    internalValue,
    cleanInputValue,
    state.isMenuOpen,
  ]);
}
