import { createContext, ReactNode, useContext } from 'react';
import { LocaleUtils } from 'react-day-picker';

export type LocaleUtilsContext = Partial<LocaleUtils> | undefined;

const LocaleUtilsContext = createContext<LocaleUtilsContext>(undefined);

export function LocaleUtilsContextProvider({
  localeUtils,
  children,
}: {
  localeUtils: LocaleUtilsContext;
  children: ReactNode;
}) {
  return <LocaleUtilsContext.Provider value={localeUtils}>{children}</LocaleUtilsContext.Provider>;
}

// Assert is not required as this is an optional context
export function useLocaleUtilsContext() {
  const localeUtils = useContext(LocaleUtilsContext);

  return localeUtils;
}
