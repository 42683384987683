import { FormikErrors, FormikValues, getIn, setIn } from 'formik';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

export default function useErrorParser() {
  const { formatMessage } = useIntl();

  return useCallback(
    <Values extends FormikValues = FormikValues>(errors: ErrorT[], values: Values | undefined) => {
      const errorToString = (error: ErrorT) => {
        return error.detail || error.title || formatMessage(t.unknownError);
      };

      const fieldExists = (key: string) => {
        return !!values && getIn(values, key) !== undefined;
      };

      const isFieldError = (error: ErrorT) => {
        return !!error.source && fieldExists(error.source);
      };

      const globalFormErrors = errors.filter((error) => !isFieldError(error)).map(errorToString);

      const fieldErrors = errors
        .filter(isFieldError)
        .reduce<FormikErrors<Values>>((result, error) => setIn(result, error.source!, errorToString(error)), {});

      return { globalFormErrors, fieldErrors };
    },
    [formatMessage]
  );
}

const t = defineMessages({
  unknownError: {
    id: 'form_unknown_error',
    defaultMessage: 'Unknown error',
  },
});
