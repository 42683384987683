import Body from './Body';
import CancelButton from './CancelButton';
import CloseButton from './CloseButton';
import Footer from './Footer';
import Header from './Header';
import HeaderActions from './HeaderActions';
import List from './List';
import ListAction from './ListAction';
import Modal from './Modal';
import SubmitButton from './SubmitButton';
import Title from './Title';

export default Object.assign(Modal, {
  List,
  ListAction,
  Header,
  HeaderActions,
  Title,
  Body,
  Footer,
  CloseButton,
  CancelButton,
  SubmitButton,
});
