import { ReactNode } from 'react';

export interface TabProps {
  id: string;
  active?: boolean | ((id: string) => boolean);
  onClick?: (id: string) => void;
  children: ReactNode;
}

export default function Tab({ id, active = false, onClick, children }: TabProps) {
  const isActive = active instanceof Function ? active(id) : active;

  return (
    <li className="tablist__tab">
      <button
        id={id}
        aria-controls={`panel-${id}`}
        className={isActive ? 'is-active' : ''}
        onClick={() => onClick?.(id)}
        aria-selected={isActive}
        role="tab"
        tabIndex={0}
      >
        {children}
      </button>
    </li>
  );
}
