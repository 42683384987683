import { forwardRef, ReactNode } from 'react';

export interface MenuProps {
  htmlProps: Omit<JSX.IntrinsicElements['div'], 'ref'> & { 'data-testid'?: string };
  children: ReactNode;
  context: {
    inputValue: string;
    isFetchingOptions: boolean;
  };
}

const Menu = forwardRef<HTMLDivElement, MenuProps>(function Menu({ children, htmlProps }, ref) {
  return (
    <div ref={ref} {...htmlProps}>
      {children}
    </div>
  );
});

Menu.displayName = 'Menu';

export default Menu;
