import { ReactElement } from 'react';

import { cancelActionBarActivity } from 'store/modules/activity';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import SideSheet from './SideSheet';

type Activity = { name: string; payload: any };
type ActionBarFunction = (activity: Activity) => ReactElement | null;

interface Props {
  formMapping: Record<string, ActionBarFunction>;
}

export default function ActionBarManager({ formMapping }: Props) {
  const dispatch = useAppDispatch();
  const activity = useAppSelector((state) => state.activity);

  const actionBarFn = activity.name ? formMapping[activity.name] : null;
  const content = actionBarFn && activity ? actionBarFn(activity as Activity) : null;
  const isOpen = !!content;

  const onClose = () => {
    dispatch(cancelActionBarActivity());
  };

  return (
    <SideSheet isOpen={isOpen} onClose={onClose} width={730} focusFirst={false}>
      {content}
    </SideSheet>
  );
}
