import cx from 'classnames';
import { ReactNode, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useStagingRibbon } from 'hooks/useConfig';
import useSidebarSettings from 'hooks/useSidebarSettings';

import Icon from 'components/Icon';

import { version } from '../../../../package.json';
import InfoModal from './InfoModal';
import SettingsNavigation from './SettingsNavigation';

export const t = defineMessages({
  stagingEnvironment: {
    id: 'sidebar_staging_environment',
    defaultMessage: 'Staging environment',
  },
  unsupportedBrowser: {
    id: 'sidebar_unsupported_browser',
    defaultMessage: 'Unsupported browser',
  },
  toggleNarrowSidebar: {
    id: 'sidebar_toggle_narrow_sidebar',
    defaultMessage: 'Switch to narrow sidebar',
  },
  toggleWideSidebar: {
    id: 'sidebar_toggle_wide_sidebar',
    defaultMessage: 'Switch to wide sidebar',
  },
});

export default function Sidebar({ children }: { children: ReactNode }) {
  const { formatMessage } = useIntl();
  const [infoModalVisibility, setInfoModalVisibility] = useState(false);

  const isStaging = useStagingRibbon();

  const openInfoModal = () => setInfoModalVisibility(true);
  const closeInfoModal = () => setInfoModalVisibility(false);

  const { isWide, toggleIsWide } = useSidebarSettings();
  const [showToggleButton, setShowToggleButton] = useState(false);

  return (
    <>
      <nav
        className="sidebar"
        onMouseEnter={() => setShowToggleButton(true)}
        onMouseLeave={() => setShowToggleButton(false)}
      >
        <div
          className={cx('sidebar__wide-toggle', {
            '-show': showToggleButton,
          })}
        >
          <button onClick={toggleIsWide} title={formatMessage(isWide ? t.toggleNarrowSidebar : t.toggleWideSidebar)}>
            <Icon>{isWide ? 'chevron_left' : 'chevron_right'}</Icon>
          </button>
        </div>

        <ul className="sidebar__list">
          {isStaging && (
            <li role="presentation">
              <div title={formatMessage(t.stagingEnvironment)} className="sidebar__item -is-warning -is-not-clickable">
                <Icon>warning</Icon>
              </div>
            </li>
          )}

          {children}

          <span className="sidebar__spacer" role="presentation" />

          <SettingsNavigation />

          <li role="presentation">
            <button title={`v${version}`} onClick={openInfoModal} className="sidebar__item">
              <Icon>info_outline</Icon>
            </button>
          </li>
        </ul>
      </nav>

      <InfoModal show={infoModalVisibility} cancel={closeInfoModal} />
    </>
  );
}
