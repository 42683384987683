import { Action } from 'redux';

import { LOCATION_CHANGE } from 'store/modules/router';

// Actions
//==============================================================================

export const CANCEL_ACTIVITY = 'CANCEL_ACTIVITY';
export const CANCEL_ACTION_BAR_ACTIVITY = 'CANCEL_ACTION_BAR_ACTIVITY';
export const START_ACTIVITY = 'START_ACTIVITY';

// Action Creators
//==============================================================================

export function cancelActivity() {
  return { type: CANCEL_ACTIVITY };
}

export function cancelActionBarActivity() {
  return { type: CANCEL_ACTION_BAR_ACTIVITY };
}

export function startActivity(name: string, payload?: any) {
  return {
    type: START_ACTIVITY,
    payload: { name, payload },
  };
}

// Reducer
//==============================================================================

export interface ActivityState {
  name?: string;
  payload?: any;
}

interface ActivityAction extends Action<string> {
  payload: {
    name?: string;
    payload?: any;
  };
}

export const initialState: ActivityState = {};

export default function activityReducer(state: ActivityState = initialState, action: ActivityAction): ActivityState {
  const { type } = action;

  switch (true) {
    case type === START_ACTIVITY: {
      const { name, payload } = action.payload;

      return { name, payload };
    }

    case type === CANCEL_ACTIVITY:
    case type === CANCEL_ACTION_BAR_ACTIVITY:
    case type === LOCATION_CHANGE:
    case type.startsWith('DELETE') && type.endsWith('SUCCESS'): {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
