type SizeCustomProperty =
  | '--size-max-width'
  | '--size-spacing'
  | '--size-form-label-width'
  | '--size-border-radius'
  | '--size-sidebar-width'
  | '--size-header-height'
  | '--size-min-content-width'
  | '--size-screen-small'
  | '--size-screen-medium'
  | '--size-screen-large';

type FontCustomProperty = '--font-stack-sans' | '--font-stack-serif' | '--font-color' | '--font-color-dimmed';

type AnimCustomProperty = '--anim-transition-speed';

type LayerCustomProperty =
  | '--z-datepicker-day-in-week'
  | '--z-float'
  | '--z-infomodal-navbar'
  | '--z-infomodal-pagination'
  | '--z-section-title-sticky'
  | '--z-scrolltable-head'
  | '--z-calendar-month-headers'
  | '--z-planning-header'
  | '--z-medication-schedule-heading'
  | '--z-devicelog-filters'
  | '--z-devicelog-title'
  | '--z-calendar-scope-select-menu'
  | '--z-scrolltable-overlap-shadow'
  | '--z-popover-floating-modal'
  | '--z-vertical-drag-indicator'
  | '--z-reactselect-menu-portal'
  | '--z-medication-import-flash'
  | '--z-nav-arrow'
  | '--z-table-remove-overlay'
  | '--z-daypicker-month'
  | '--z-daypicker-navbutton'
  | '--z-modal'
  | '--z-sidesheet'
  | '--z-sidesheet-backdrop'
  | '--z-rte-menu'
  | '--z-input-popup'
  | '--z-popover-floating'
  | '--z-daypicker'
  | '--z-timelinechart-tooltip'
  | '--z-rte-full-screen'
  | '--z-admin-nav'
  | '--z-toggle-nav';

type BrandCustomProperty =
  | '--success'
  | '--success-dark'
  | '--danger'
  | '--info'
  | '--warning'
  | '--info-alternative'
  | '--info-highlighted'
  | '--black'
  | '--gray-darkest'
  | '--gray-darker'
  | '--gray-dark'
  | '--gray'
  | '--gray-light'
  | '--gray-lighter'
  | '--gray-lightest'
  | '--white';

type CustomProperty =
  | SizeCustomProperty
  | FontCustomProperty
  | AnimCustomProperty
  | LayerCustomProperty
  | BrandCustomProperty;

export function getString(property: CustomProperty, element: Element = document.documentElement) {
  return getComputedStyle(element).getPropertyValue(property).trim();
}

export function getInt(property: CustomProperty, element: Element = document.documentElement) {
  return parseInt(getComputedStyle(element).getPropertyValue(property), 10);
}
