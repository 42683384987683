import { fromJS, Set } from 'immutable';

import {
  CREATE_PATIENT_CONDITION,
  DELETE_PATIENT_CONDITION,
  GET_PATIENT_CONDITIONS,
  UPDATE_PATIENT_CONDITION,
} from 'store/modules/entities/actions/conditions/patient';

const CONDS = ['conditions'];
const CONDS_BY_ID = [...CONDS, 'byId'];
const PAT_CONDS = ['patientConditions'];
const PAT_CONDS_BY_ID = [...PAT_CONDS, 'byId'];
const PAT_CONDS_BY_PAT_ID = [...PAT_CONDS, 'byPatientId'];
const ENTITY_CODES = ['entityCodes'];
const ENTITY_CODES_BY_ID = [...ENTITY_CODES, 'byId'];

export function mergePatientConditions(state: any, patientId: string, patientConditions: any) {
  if (!patientConditions) return state;

  const patientConditionIds = Object.keys(patientConditions);
  const patientConditionsById = patientConditions;
  const byPatientId =
    state.getIn([...PAT_CONDS_BY_PAT_ID, patientId])?.union(Set(patientConditionIds)) || Set(patientConditionIds);

  return state
    .mergeIn(PAT_CONDS_BY_ID, fromJS(patientConditionsById || {}))
    .setIn([...PAT_CONDS_BY_PAT_ID, patientId], byPatientId);
}

export default function patientConditionsReducer(state: any, action: any) {
  switch (action.type) {
    case GET_PATIENT_CONDITIONS.SUCCESS: {
      const { response, patientId } = action.payload;
      const { patientConditions = {}, conditions = {}, codes = {} } = response.entities;

      return mergePatientConditions(state, patientId, patientConditions)
        .mergeIn(CONDS_BY_ID, fromJS(conditions))
        .mergeIn(ENTITY_CODES_BY_ID, fromJS(codes));
    }

    case DELETE_PATIENT_CONDITION.SUCCESS: {
      const { patientId, conditionId } = action.payload;

      const patient = state.getIn(['patients', patientId])?.toJS() || {};

      const byPatientId = state.getIn([...PAT_CONDS_BY_PAT_ID, patientId]).filter((id) => id !== conditionId);

      return state
        .mergeIn(
          ['patients', patientId],
          fromJS({
            ...patient,
            ongoingConditionsCount: (patient?.ongoingConditionsCount || 0) - 1,
          })
        )
        .deleteIn([...PAT_CONDS_BY_ID, conditionId])
        .setIn([...PAT_CONDS_BY_PAT_ID, patientId], byPatientId);
    }

    case CREATE_PATIENT_CONDITION.SUCCESS: {
      const { response, patientId } = action.payload;
      const { 'patients/patientCondition': conditionId } = response.result;
      const { patientConditions = {}, conditions = {}, codes = {} } = response.entities;

      const patient = state.getIn(['patients', patientId])?.toJS() || {};

      const byPatientId =
        state.getIn([...PAT_CONDS_BY_PAT_ID, patientId])?.union(Set([conditionId])) || Set([conditionId]);

      return state
        .mergeIn(
          ['patients', patientId],
          fromJS({
            ...patient,
            ongoingConditionsCount: 1 + (patient?.ongoingConditionsCount || 0),
          })
        )
        .mergeIn(PAT_CONDS_BY_ID, fromJS(patientConditions))
        .mergeIn(CONDS_BY_ID, fromJS(conditions))
        .mergeIn(ENTITY_CODES_BY_ID, fromJS(codes))
        .setIn([...PAT_CONDS_BY_PAT_ID, patientId], byPatientId);
    }

    case UPDATE_PATIENT_CONDITION.SUCCESS: {
      const { response } = action.payload;
      const { patientConditions = {}, conditions = {}, codes = {} } = response.entities;

      return state
        .mergeIn(PAT_CONDS_BY_ID, fromJS(patientConditions))
        .mergeIn(CONDS_BY_ID, fromJS(conditions))
        .mergeIn(ENTITY_CODES_BY_ID, fromJS(codes));
    }

    default: {
      return state;
    }
  }
}

export const patientConditionsSelector = (
  state: any,
  patientId: string | null | undefined,
  conditionType?: string
): PatientConditionT[] | null | undefined => {
  if (!patientId) return undefined;

  const isFetching = !!state.network.GET_PATIENT_CONDITIONS || state.network.GET_PATIENT_CONDITIONS === undefined;

  if (isFetching) return undefined;

  const { byId: patientConditionsById, byPatientId: patientConditionsByPatientId } = state.entities
    .getIn(PAT_CONDS)
    .toJS();

  const patientConditionIds = patientConditionsByPatientId[patientId] || [];

  const { byId: codesById } = state.entities.getIn(ENTITY_CODES).toJS();
  const { byId: conditionsById } = state.entities.getIn(CONDS).toJS();

  const conditionTypeFilter = conditionType ? (condition) => conditionType === condition?.conditionType : () => true;

  return patientConditionIds
    .map((id) => patientConditionsById[id])
    .filter(conditionTypeFilter)
    .map((patientCondition) => ({
      ...patientCondition,
      codes: patientCondition.codes?.map((codeId) => codesById[codeId]) || [],
      condition: conditionsById[patientCondition.condition] || null,
    }))
    .filter((patientCondition) => !!patientCondition);
};
