import { MessageDescriptor } from 'react-intl';

import { SearchInput, SearchInputProps } from 'components/Inputs';

import * as Filter from '../filterComponents';

interface Props extends Omit<SearchInputProps, 'id'> {
  name: string;
  label: MessageDescriptor | string;
  renderValues?: boolean;
}

const clearValue = '';

export default function SearchFilter({ name, label, renderValues = false, ...props }: Props) {
  const hasValue = !!props.value && props.value.length > 0;

  return (
    <Filter.Input hasValue={hasValue}>
      <Filter.InputPopup
        id={name}
        content={<SearchInput {...props} id={name} />}
        onChange={props.onChange}
        clearValue={clearValue}
      >
        <Filter.InputButton>{label}</Filter.InputButton>

        {renderValues && hasValue ? (
          <Filter.ValuePortal name={name}>
            <Filter.ValueLabel>{label}</Filter.ValueLabel>
            <Filter.ValueValue>
              <Filter.ValueText>{props.value}</Filter.ValueText>
              <Filter.ValueClearIcon onClick={() => props.onChange?.(clearValue)} />
            </Filter.ValueValue>
          </Filter.ValuePortal>
        ) : null}
      </Filter.InputPopup>
    </Filter.Input>
  );
}
