import cx from 'classnames';
import { cloneElement, ReactElement, KeyboardEvent as ReactKeyboardEvent, ReactNode, useId, useRef } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import usePopupMenu from 'hooks/usePopupMenu';

import ButtonComponent from 'components/Button';
import { ClearIcon, DropdownIcon } from 'components/Inputs/shared';
import Portal from 'components/Portal';

import t from './translations';

export function Input({ hasValue, children }: { hasValue: boolean; children: ReactElement }) {
  return <div className={cx('filter-input', { '-has-value': hasValue })}>{children}</div>;
}

export function InputButton({ children }: { children: MessageDescriptor | string }) {
  const { formatMessage } = useIntl();
  const label = isMessageDescriptor(children) ? formatMessage(children) : children;

  return <div className="filter-input__button">{label}</div>;
}

export function InputPopupActions({ onClear }: { onClear: () => void }) {
  return (
    <div className="filter-input__popup-actions">
      <ButtonComponent size="compact" importance="tertiary" onClick={onClear}>
        <FormattedMessage {...t.clear} />
      </ButtonComponent>
    </div>
  );
}

export function InputPopup({
  id,
  content,
  onChange,
  children,
  clearValue,
}: {
  id: string;
  content: ReactElement;
  onChange: ((value: any) => void) | undefined;
  children: ReactNode;
  clearValue: any;
}) {
  const popupId = `popup-${useId()}`;
  const inputRef = useRef<HTMLInputElement>(null);

  const onOpen = () => {
    window.setTimeout(() => {
      inputRef.current?.focus();
    });
  };

  const { setActuator, actuatorRef, setMenu, menuStyles, isOpen, open, close } = usePopupMenu({
    syncWidth: false,
    onOpen,
    menuOffset: 4,
    minWidth: 224,
    maxWidth: 512,
  });

  const closeMenu = () => {
    if (isOpen) {
      close();
      actuatorRef.current?.focus();
    }
  };

  const onClear = () => {
    onChange?.(clearValue);
    closeMenu();
  };

  const onButtonClick = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  const onInputKeyDown = (event: ReactKeyboardEvent) => {
    switch (event.key) {
      case 'Tab':
      case 'Escape': {
        event.preventDefault();
        if (isOpen) closeMenu();
        break;
      }
    }
  };

  return (
    <>
      <button
        ref={setActuator}
        id={id}
        type="button"
        aria-haspopup="menu"
        aria-expanded={isOpen}
        aria-controls={popupId}
        onClick={onButtonClick}
        className="filter-input__actuator"
      >
        {children}

        <div className={cx(`filter-input__actuator__dropdown-icon`, { '-is-flipped': isOpen })}>
          <DropdownIcon />
        </div>
      </button>

      {isOpen ? (
        <Portal>
          <div ref={setMenu} id={popupId} className={cx('filter-input__floating', '-is-visible')} style={menuStyles}>
            {cloneElement(content, {
              ref: inputRef,
              onKeyDown: onInputKeyDown,
            })}
            {onChange ? <InputPopupActions onClear={onClear} /> : null}
          </div>
        </Portal>
      ) : null}
    </>
  );
}

export function Value({ name }: { name: string }) {
  return <div className="filter-value" data-name={name} />;
}

export function ValuePortal({ name, children }: { name: string; children: ReactNode }) {
  return <Portal target={document.querySelector(`.filter-value[data-name="${name}"]`)}>{children}</Portal>;
}

export function ValueLabel({ children }: { children: MessageDescriptor | string }) {
  return (
    <span className="filter-value__label" onClick={(event) => event.stopPropagation()}>
      {isMessageDescriptor(children) ? <FormattedMessage {...children} /> : children}
    </span>
  );
}

export function ValueValue({ children }: { children: ReactNode }) {
  return (
    <span className="filter-value__value" onClick={(event) => event.stopPropagation()}>
      {children}
    </span>
  );
}

export function ValueText({ children }: { children: ReactNode }) {
  return (
    <span
      className="filter-value__text"
      title={typeof children === 'string' || typeof children === 'number' ? `${children}` : undefined}
    >
      {children}
    </span>
  );
}

export function ValueClearIcon({ onClick }: { onClick: () => void }) {
  return (
    <div className="filter-value__clear-icon" onClick={onClick}>
      <ClearIcon />
    </div>
  );
}
