import { useEffect, useState } from 'react';

type AsyncFn = (...args: any[]) => Promise<any>;
type CancelableAsyncFn<T extends AsyncFn> = (...args: Parameters<T>) => CancelablePromise<Awaited<ReturnType<T>>>;
type CancelablePromise<T> = Promise<T> & { cancel: () => void };

function useCancelableAsyncFn<T extends AsyncFn>(asyncFn: T | null | undefined) {
  const make = <T extends AsyncFn>(asyncFn: T | null | undefined) => (asyncFn ? makeCancelableAsyncFn(asyncFn) : null);

  const [cancelable, setCancelable] = useState(() => make(asyncFn));

  useEffect(() => {
    setCancelable(() => make(asyncFn));
  }, [asyncFn]);

  return cancelable;
}

function makeCancelableAsyncFn<T extends AsyncFn>(asyncFn: T): CancelableAsyncFn<T> {
  function cancelable(...args: Parameters<T>) {
    let canceled = false;

    const promise: any = new Promise((resolve, reject) => {
      asyncFn(...args)
        .then((...args) => {
          if (!canceled) resolve(...args);
        })
        .catch((...args) => {
          if (!canceled) reject(...args);
        });
    });

    promise.cancel = () => {
      canceled = true;
    };

    return promise;
  }

  return cancelable;
}

export type { CancelableAsyncFn, CancelablePromise };
export { useCancelableAsyncFn };
