import cx from 'classnames';
import { forwardRef, useMemo, useRef } from 'react';
import { MessageDescriptor } from 'react-intl';

import useSyncedRef from 'hooks/useSyncedRef';

import { SelectInput, SelectInputImparativeHandle, SelectInputProps } from 'components/Inputs';
import { MultiValueContainerProps } from 'components/Inputs/SelectInput/components/MultiValueContainer';
import { SingleValueProps } from 'components/Inputs/SelectInput/components/SingleValue';
import { SelectInputOption } from 'components/Inputs/SelectInput/types';
import { MenuProps } from 'components/Inputs/shared';

import * as Filter from '../filterComponents';

interface Props<IsMulti extends boolean> extends Omit<SelectInputProps<IsMulti>, 'id'> {
  name: string;
  label: MessageDescriptor | string;
  renderValues?: boolean;
}

export default function SelectFilter<IsMulti extends boolean>({
  name,
  label,
  renderValues = false,
  ...props
}: Props<IsMulti>) {
  const selectRef = useRef<SelectInputImparativeHandle>(null);

  const onClear = () => {
    selectRef.current?.clear();
    selectRef.current?.closeMenu();
  };

  const components = useComponents(label, renderValues, name, props.components, props.clearable, onClear);

  const clearable = props.clearable === undefined ? false : props.clearable;
  const hasValue = Array.isArray(props.value) ? props.value.length > 0 : !!props.value;

  return (
    <Filter.Input hasValue={hasValue}>
      <SelectInput
        {...props}
        id={name}
        ref={selectRef}
        menuOffset={4}
        placeholder={label}
        clearable={false}
        components={components}
        afterListboxContent={clearable && hasValue ? <Filter.InputPopupActions onClear={onClear} /> : undefined}
      />
    </Filter.Input>
  );
}

function createMenuComponent() {
  return forwardRef<HTMLDivElement, MenuProps>(({ children, htmlProps }, ref) => {
    const { className, ...props } = htmlProps;

    return (
      <div ref={ref} className={cx(className, 'filter-input__floating')} {...props}>
        {children}
      </div>
    );
  });
}

function createSingleValueComponent<IsMulti extends boolean>(
  label: MessageDescriptor | string,
  renderValues: boolean,
  name: string,
  clearable: SelectInputProps<IsMulti>['clearable'],
  onClear: () => void
) {
  return (props: SingleValueProps) => {
    const option = props.options.find((o) => o.value === props.value);
    const value = option?.label || option?.value || '';

    return (
      <>
        <Filter.InputButton>{label}</Filter.InputButton>

        {renderValues && option ? (
          <Filter.ValuePortal name={name}>
            <Filter.ValueLabel>{label}</Filter.ValueLabel>
            <Filter.ValueValue>
              <Filter.ValueText>{value}</Filter.ValueText>
              {clearable ? <Filter.ValueClearIcon onClick={onClear} /> : null}
            </Filter.ValueValue>
          </Filter.ValuePortal>
        ) : null}
      </>
    );
  };
}

function createMultiValueContainerComponent(label: MessageDescriptor | string, renderValues: boolean, name: string) {
  return (props: MultiValueContainerProps) => {
    const getOption = (value: string | number) => props.options.find((o) => o.value === value);
    const getLabel = (option: SelectInputOption | undefined) => option?.label || option?.value;

    return (
      <>
        <Filter.InputButton>{label}</Filter.InputButton>

        {renderValues && props.value && Array.isArray(props.value) && props.value.length ? (
          <Filter.ValuePortal name={name}>
            <Filter.ValueLabel>{label}</Filter.ValueLabel>
            {props.value.map((value, index) => {
              const option = getOption(value);
              const label = getLabel(option) || value;

              return (
                <Filter.ValueValue key={index}>
                  <Filter.ValueText>{label}</Filter.ValueText>
                  {option ? <Filter.ValueClearIcon onClick={() => props.toggleOption(option)} /> : null}
                </Filter.ValueValue>
              );
            })}
          </Filter.ValuePortal>
        ) : null}
      </>
    );
  };
}

function createPlaceholderComponent(label: MessageDescriptor | string) {
  return () => <Filter.InputButton>{label}</Filter.InputButton>;
}

export function useComponents<IsMulti extends boolean>(
  label: MessageDescriptor | string,
  renderValues: boolean,
  name: string,
  components: SelectInputProps<IsMulti>['components'],
  clearable: SelectInputProps<IsMulti>['clearable'],
  onClear: () => void
) {
  const onClearRef = useSyncedRef(onClear);

  return useMemo(
    () => ({
      ...components,
      Menu: createMenuComponent(),
      SingleValue: createSingleValueComponent(label, renderValues, name, clearable, onClearRef.current),
      MultiValueContainer: createMultiValueContainerComponent(label, renderValues, name),
      Placeholder: createPlaceholderComponent(label),
    }),
    [label, renderValues, name, components, clearable, onClearRef]
  );
}
