import isEqual from 'lodash/isEqual';
import {
  Children,
  cloneElement,
  CSSProperties,
  isValidElement,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import useKeydownListener from 'hooks/useKeydownListener';

import ActionButton from 'components/ActionButton';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { BaseInputProps } from 'components/Inputs/types';
import SideSheet from 'components/SideSheet';

import * as Filter from './filterComponents';
import t from './translations';

export default function Filters<T extends Record<string, any>>({
  value,
  onChange,
  getResetValue,
  children,
  style,
}: {
  value: T;
  onChange: (value: T) => void;
  getResetValue?: () => T;
  children: ReactNode;
  style?: CSSProperties;
}) {
  const filtersId = useId();

  return (
    <div className="filters" style={style}>
      <Icon className="filters__filter-icon">filter_list</Icon>

      <FilterInputs filtersId={filtersId} value={value} onChange={onChange} getResetValue={getResetValue}>
        {children}
      </FilterInputs>

      <FilterValues filtersId={filtersId}>{children}</FilterValues>
    </div>
  );
}

function FilterInputs<T extends Record<string, any>>({
  filtersId,
  value,
  onChange,
  getResetValue,
  children,
}: {
  filtersId: string;
  value: T;
  onChange: (value: T) => void;
  getResetValue?: () => T;
  children: ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isTouched, setIsTouched] = useState(() =>
    getResetValue ? !filterStatesAreEqual(value, getResetValue()) : false
  );
  const [initialValue, setInitialValue] = useState<T>();

  const filtersRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const filters = Children.toArray(children);

  useInlineFilterUpdater({ filtersRef });
  useKeydownListener('Escape', () => {
    if (isOpen) closeButtonRef.current?.focus();
  });

  const renderFilter = (renderValues: boolean) => (element: FilterElement) => {
    const { name } = element.props;

    return cloneElement(element, {
      renderValues,
      name: `${name}-${filtersId}`,
      value: value[name],
      onChange: (newValue: any) => {
        const nextValue: T = { ...value, [name]: newValue !== null ? newValue : undefined };

        if (!isTouched) {
          if (!filterStatesAreEqual(nextValue, value)) {
            setIsTouched(true);
            setInitialValue(value);

            onChange(nextValue);
          }
        } else {
          const resetValue = getResetValue?.() || initialValue;
          if (resetValue && filterStatesAreEqual(nextValue, resetValue)) {
            setIsTouched(false);
          }

          onChange(nextValue);
        }
      },
    });
  };

  const onResetAll = () => {
    setIsTouched(false);

    const resetValue = getResetValue?.() || initialValue;
    if (resetValue) onChange(resetValue);

    window.setTimeout(() => {
      const selector = '.filter-input:not(.-hidden) button';
      const filterButtons = [...(filtersRef.current?.querySelectorAll<HTMLElement>(selector) || [])];
      const lastFilterButton: HTMLElement | undefined = filterButtons[filterButtons.length - 1];
      lastFilterButton?.focus();
    });
  };

  return (
    <>
      <div ref={filtersRef} className="filters__inputs">
        {filters.filter(extendsBaseInput).map(renderFilter(true))}

        <Button
          className="filters__all-filters-button"
          onClick={() => setIsOpen(true)}
          importance="secondary"
          size="compact"
        >
          <FormattedMessage {...t.allFilters} />
        </Button>

        {isTouched ? (
          <Button onClick={onResetAll} icon="replay" importance="tertiary" size="compact" iconRotation={-90}>
            <FormattedMessage {...t.resetAll} />
          </Button>
        ) : null}
      </div>

      <SideSheet isOpen={isOpen} onClose={() => setIsOpen(false)} sheetClassName="filters-sidesheet">
        {(isVisible) =>
          isVisible ? (
            <div className="actionbar">
              <div className="actionbar__title">
                <h5 className="uppercase">
                  <FormattedMessage {...t.allFilters} />
                </h5>
              </div>

              <div className="actionbar__content">{filters.filter(extendsBaseInput).map(renderFilter(false))}</div>

              <div className="actionbar__close">
                <ActionButton
                  ref={closeButtonRef}
                  onClick={() => setIsOpen(false)}
                  title={t.close}
                  icon="close"
                  style={{ marginLeft: 0 }}
                />
              </div>
            </div>
          ) : null
        }
      </SideSheet>
    </>
  );
}

function FilterValues({ filtersId, children }: { filtersId: string; children: ReactNode }) {
  const filters = Children.toArray(children);

  return (
    <div className="filters__values">
      {filters.filter(extendsBaseInput).map((element) => {
        const { name } = element.props;

        return <Filter.Value key={`${name}-${filtersId}`} name={`${name}-${filtersId}`} />;
      })}
    </div>
  );
}

function useInlineFilterUpdater({ filtersRef }: { filtersRef: RefObject<HTMLDivElement> }) {
  const update = useCallback(() => {
    const parent = filtersRef.current;
    if (!parent) return;

    const children = [...parent.children] as HTMLElement[];
    const filters = children.filter((node) => node.classList.contains('filter-input'));
    const button = children.find((node) => node.classList.contains('filters__all-filters-button'))!;

    parent.style.visibility = 'hidden';
    filters.forEach((filter) => filter.classList.remove('-hidden'));
    button.classList.add('-hidden');

    const overflows = () => parent.scrollWidth > parent.clientWidth;

    if (overflows()) {
      button.classList.remove('-hidden');

      while (overflows()) {
        filters[filters.length - 1].classList.add('-hidden');
        filters.pop();
      }
    }

    parent.style.visibility = 'visible';
  }, [filtersRef]);

  useLayoutEffect(() => {
    update();

    const observer = new ResizeObserver(() => update());
    observer.observe(filtersRef.current!);

    return () => {
      observer.disconnect();
    };
  });
}

type FilterElement = ReactElement<Omit<BaseInputProps, 'id'> & { name: string; renderValues: boolean }>;

function extendsBaseInput(child: ReactNode): child is FilterElement {
  return isValidElement(child);
}

function filterStatesAreEqual(a: Record<string, any> | undefined, b: Record<string, any> | undefined) {
  if (!a && !b) return true;
  if (!a || !b) return false;

  const aKeys = Object.keys(a).filter((key) => a[key] !== null && a[key] !== undefined);
  const bKeys = Object.keys(b).filter((key) => b[key] !== null && b[key] !== undefined);

  if (aKeys.length !== bKeys.length) return false;

  return aKeys.every((key) => isEqual(a[key], b[key]));
}
