import { ReactNode } from 'react';

import Logo from 'sharedComponents/Logo';

interface FormProps {
  title: string;
  children: ReactNode;
}

export default function SigninContent({ title, children }: FormProps) {
  return (
    <div className="login__content">
      <Logo className="login__content__logo" />
      <h2 className="login__content__heading">{title}</h2>
      {children}
    </div>
  );
}
