import { autoUpdate, flip, offset, Placement, size, useFloating } from '@floating-ui/react-dom';

import { CLASSNAME } from '../constants';

const MIN_MENU_WIDTH = 224;
const MAX_MENU_WIDTH = 512;
const NR_OF_VISIBLE_OPTIONS = 7;
const MIN_NR_OF_VISIBLE_OPTIONS = 2;
const PLACEMENT: Placement = 'bottom-start';

export function usePopupMenu(menuOffset: number, minMenuWidth: number | undefined) {
  function getOptionHeights(options: HTMLDivElement[]) {
    return options.map((option) => option.getBoundingClientRect().height);
  }

  function getOptions(parentElement: HTMLElement) {
    return [...parentElement.querySelectorAll<HTMLDivElement>(`.${CLASSNAME}__option`)];
  }

  /**
   * Select x options starting from the first selected option if there is any. Fallback to the first option.
   * If there are not enough options after the first selected option, offset the start so we still
   * have x options.
   */
  function getVisibleOptions(allOptions: HTMLDivElement[]) {
    const firstSelectedOptionIndex = allOptions.findIndex((option) => option.classList.contains('-is-selected'));

    let start = 0;

    if (firstSelectedOptionIndex > -1) {
      start = firstSelectedOptionIndex;

      if (allOptions.length - start < NR_OF_VISIBLE_OPTIONS) {
        start = Math.max(0, start - (NR_OF_VISIBLE_OPTIONS - (allOptions.length - start)));
      }
    }

    return allOptions.slice(start, start + NR_OF_VISIBLE_OPTIONS);
  }

  function calculateTruncatedMenuHeight(optionHeights: number[], nrOfOptions: number) {
    const normalizedNrOfOptions = Math.min(optionHeights.length, nrOfOptions);
    const allButLastHeight = optionHeights.slice(0, normalizedNrOfOptions - 1);
    const lastHeight = optionHeights[normalizedNrOfOptions - 1];

    return sumHeight(allButLastHeight) + (lastHeight / 2 + 2);
  }

  function sumHeight(optionHeights: number[]) {
    return optionHeights.reduce((acc, height) => acc + height, 0);
  }

  const { reference, floating, update, refs, ...floatingHookResult } = useFloating<HTMLButtonElement>({
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    placement: PLACEMENT,
    middleware: [
      offset({ mainAxis: menuOffset, alignmentAxis: -1 }),
      flip(),
      size({
        apply: ({ availableWidth, availableHeight, elements, rects }) => {
          const referenceWidth = rects.reference.width;
          const menuHeight = rects.floating.height;
          const isVisible = getComputedStyle(elements.floating).getPropertyValue('display') !== 'none';
          if (!isVisible) return;

          const listboxHeight =
            elements.floating.querySelector(`.${CLASSNAME}__listbox`)!.getBoundingClientRect().height || 0;
          const nonListboxHeight = Math.round(menuHeight - listboxHeight);

          const maxWidth = `${Math.max(
            minMenuWidth ?? MIN_MENU_WIDTH,
            Math.min(MAX_MENU_WIDTH, availableWidth, referenceWidth)
          )}px`;
          let maxHeight = 'none';

          const options = getOptions(elements.floating);
          const visibleOptions = getVisibleOptions(options);
          const visibleOptionHeights = getOptionHeights(visibleOptions);

          const allOptionsFit =
            options.length <= NR_OF_VISIBLE_OPTIONS &&
            nonListboxHeight + sumHeight(visibleOptionHeights) <= availableHeight;

          if (!allOptionsFit) {
            let height: number | undefined;
            let nrOfOptions = NR_OF_VISIBLE_OPTIONS;
            const getHeight = () => calculateTruncatedMenuHeight(visibleOptionHeights, nrOfOptions) + nonListboxHeight;

            while ((height = getHeight()) > availableHeight && nrOfOptions > MIN_NR_OF_VISIBLE_OPTIONS) {
              nrOfOptions--;
            }

            maxHeight = `${height}px`;
          }

          Object.assign(elements.floating.style, {
            maxWidth,
            maxHeight,
          });
        },
      }),
    ],
  });

  const isFlipped =
    floatingHookResult.placement !== PLACEMENT && floatingHookResult.middlewareData.flip?.index !== undefined;

  return {
    ...floatingHookResult,
    setActuator: reference,
    actuatorRef: refs.reference,
    setMenu: floating,
    menuRef: refs.floating,
    updateMenu: update,
    isFlipped,
  };
}
