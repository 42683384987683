import { http } from 'api/client';
import { deserialize, parseDateTime } from 'api/deserialize';

export interface RoleT {
  id: string;
  name: string;
  rights: number[];
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export async function fetchRoles() {
  const { data } = await http.get<{ 'users/roles': RoleT[] }>('roles');

  return data['users/roles'].map((role) =>
    deserialize(role, {
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
      deletedAt: parseDateTime,
    })
  );
}
