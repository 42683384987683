import Actions from './Actions';
import AppendixRow from './AppendixRow';
import FullWidthCell from './FullWidthCell';
import Group from './Group';
import GroupToggle from './GroupToggle';
import RemoveOverlay from './RemoveOverlay';
import SortableHeader from './SortableHeader';
import Table from './Table';

export { SortOrder } from './SortableHeader';

export { default as actionColWidth } from './utils/actionColWidth';
export { default as generateGroupHeaders } from './utils/generateGroupHeaders';

export { default as usePaginatedTableSelection } from './hooks/usePaginatedTableSelection';
export { default as useTableSelection } from './hooks/useTableSelection';

export type { Props as TableProps } from './Table';

export default Object.assign(Table, {
  Actions,
  AppendixRow,
  FullWidthCell,
  Group: Object.assign(Group, {
    Toggle: GroupToggle,
  }),
  RemoveOverlay,
  SortableHeader,
});
