import { defineMessages } from 'react-intl';

export default defineMessages({
  noItems: {
    id: 'table_no_items',
    defaultMessage: 'Nothing here yet.',
  },

  allItemsSelected: {
    id: 'use_table_selection_all_items_selected',
    defaultMessage: 'All {amount} items are selected',
  },
  nItemsSelected: {
    id: 'use_table_selection_n_items_selected',
    defaultMessage: '{amountA} of {amountB} items are selected',
  },
  deselectAllItems: {
    id: 'use_table_selection_deselect_all_items',
    defaultMessage: 'Deselect all ({amount}) items',
  },
  selectAllItems: {
    id: 'use_table_selection_select_all_items',
    defaultMessage: 'Select all ({amount}) items',
  },

  show: {
    id: 'table_group_toggle_show',
    defaultMessage: 'Show',
  },
  hide: {
    id: 'table_group_toggle_hide',
    defaultMessage: 'Hide',
  },

  defaultConfirmationQuestion: {
    id: 'delete_overlay_default_confirmation_question',
    defaultMessage: 'Are you sure you want to delete this item?',
  },
  confirmDelete: {
    id: 'delete_overlay_confirm_button_title',
    defaultMessage: 'Yes, delete',
  },
  cancelDelete: {
    id: 'delete_overlay_cancel_button_title',
    defaultMessage: "No, don't delete",
  },

  sort: {
    id: 'table_header_sort',
    defaultMessage: 'Sort',
  },
});
