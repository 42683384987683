import { MouseEvent as ReactMouseEvent, ReactNode } from 'react';

import Icon from 'components/Icon';

interface Props {
  icon: string;
  onClick: (event: ReactMouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
}

export default function ListAction({ icon, onClick, children }: Props) {
  return (
    <button type="button" className="modal-list-action" onClick={onClick}>
      <Icon>{icon}</Icon>
      <strong>{children}</strong>
    </button>
  );
}
