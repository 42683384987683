import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { LocationState } from 'views/login/login';

import Login from '../login';
import { ForbiddenPage } from './ErrorPages';

export default function UnauthenticatedApp() {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="login/*" element={<Login />} />
      <Route path="forbidden" element={<ForbiddenPage />} />
      <Route path="*" element={<Navigate to="/login" state={{ from: location } as LocationState} />} />
    </Routes>
  );
}
