import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { entityCodeSchema } from 'store/modules/entities/actions/entityCodes';
import { networkActionTypes } from 'store/utils';

export const conditionSchema = new schema.Entity(
  'conditions',
  {
    codes: [entityCodeSchema],
  },
  {
    processStrategy: schemaDeserializers({
      discardedAt: parseDateTime,
    }),
  }
);

export const getConditionNetworkTypes = (conditionType: ConditionTypeT) =>
  networkActionTypes(`GET_${conditionType.toUpperCase()}_CONDITIONS`);

export const getConditions = (conditionType: ConditionTypeT, page = 0, discarded?: void | boolean): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    // eslint-disable-next-line
    types: getConditionNetworkTypes(conditionType),
    url: 'conditions',
    method: 'GET',
    params: {
      conditionType,
      page,
      discarded: (() => {
        if (discarded === true) return '1';
        if (discarded === false) return '0';

        return '';
      })(),
    },
    normalizeSchema: { data: [conditionSchema] },
  },
});

export const CREATE_CONDITION = networkActionTypes('CREATE_CONDITION');

export const createCondition = (condition: {
  conditionType: string;
  name: string;
  note?: ConditionNoteT;
  description?: string;
  codes?: string[];
}): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_CONDITION,
    url: 'conditions',
    method: 'POST',
    payload: { condition },
    normalizeSchema: { 'conditions/condition': conditionSchema },
  },
});

export const UPDATE_CONDITION = networkActionTypes('UPDATE_CONDITION');

export const updateCondition = (
  conditionId: string,
  condition: {
    name?: string;
    conditionType?: ConditionTypeT;
    description?: string;
    note?: ConditionNoteT;
    codes?: string[];
  }
): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_CONDITION,
    url: `conditions/${conditionId}`,
    method: 'PUT',
    payload: { condition },
    normalizeSchema: { 'conditions/condition': conditionSchema },
  },
});

export const DELETE_CONDITION = networkActionTypes('DELETE_CONDITION');

export const deleteCondition = ({ id }: ConditionT) => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_CONDITION,
    url: `conditions/${id}`,
    method: 'DELETE',
    actionPayload: { conditionId: id },
  },
});

export const SEARCH_CONDITIONS = networkActionTypes('SEARCH_CONDITIONS');

export const searchConditions = (conditionType: string, query: string): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: SEARCH_CONDITIONS,
    url: 'conditions/search',
    method: 'GET',
    params: { conditionType, discarded: '0', q: query },
    normalizeSchema: { 'conditions/conditions': [conditionSchema] },
  },
});
