import { SelectInputOption } from './types';

// https://stackoverflow.com/a/37511463
export function removeDiacritics(string: string) {
  return string.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');
}

export function stringifyOption(option: SelectInputOption) {
  return `${option.label} ${option.value}`;
}

export function filterOptions(options: SelectInputOption[], search: string): SelectInputOption[] {
  return options.filter((option) => {
    const haystack = removeDiacritics(stringifyOption(option).toLowerCase());
    const needle = removeDiacritics(search.toLowerCase());

    return haystack.includes(needle);
  });
}

export function hasExactOptionMatch(options: SelectInputOption[], search: string): boolean {
  return options.some((option) => {
    const a = removeDiacritics(option.label.toLowerCase());
    const b = removeDiacritics(search.toLowerCase());

    return a === b;
  });
}
