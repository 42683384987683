import { ToastContainer } from 'react-toastify';

import Portal from 'components/Portal';

export default function ToastProvider() {
  return (
    <Portal>
      <ToastContainer />
    </Portal>
  );
}
