import isEqual from 'lodash/isEqual';
import { Element, Text } from 'slate';

import { RTE_BLOCK, RTE_INLINE } from './constants';
import { Rte } from './domain';

function createParagraph(): Rte.Element {
  return {
    type: RTE_BLOCK.paragraph,
    children: [{ text: '' }],
  };
}

function createVariable(variable: string): Rte.VariableELement {
  return {
    type: RTE_INLINE.variable,
    children: [{ text: '' }],
    variable,
  };
}

function isTextLike(node: any): node is Rte.TextLike {
  return Text.isText(node) || isVariable(node);
}

function isVariable(node: any): node is Rte.VariableELement {
  return Element.isElement(node) && node.type === 'variable';
}

function isEmpty(value: Rte.Node[] | null | undefined): value is null | undefined {
  if (!value) return true;

  return value.every((node) => !hasValue(node));
}

function hasValue(node: Rte.Node) {
  if (isVariable(node)) {
    return true;
  } else if (Text.isText(node)) {
    return !!node.text;
  } else {
    return node.children.some(hasValue);
  }
}

function getStyleAttributes(node: any): Rte.Style | null {
  const result: Rte.Style = {};
  const style = isTextLike(node) ? node.style : undefined;

  if (style?.color) result.color = style.color;

  return Object.keys(result).length ? result : null;
}

function valuesDiffer(external: Rte.Node[] | null | undefined, internal: Rte.Node[] | null | undefined): boolean {
  if (!external && isEmpty(internal)) return false;
  if (isEqual(external, internal)) return false;

  return true;
}

export const slateHelpers = {
  createParagraph,
  createVariable,
  isTextLike,
  isVariable,
  isEmpty,
  hasValue,
  getStyleAttributes,
  valuesDiffer,
};
