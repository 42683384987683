import cx from 'classnames';
import { KeyboardEvent as ReactKeyboardEvent, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';

import { SECOND_IN_MILLIS } from 'utils/date';

import useConstant from 'hooks/useConstant';
import useDelayedAnimation from 'hooks/useDelayedAnimation';

import { Rte, slateHelpers, withDomain, withVariables } from '../domain';
import useFullScreen from '../hooks/useFullScreen';
import useVariables from '../hooks/useVariables';
import Element from './Element';
import Leaf from './Leaf';
import Placeholder from './Placeholder';
import Toolbar from './Toolbar';

const BACKDROP_ANIMATION_DURATION = SECOND_IN_MILLIS;

const FALLBACK_VALUE: Rte.Node[] = [slateHelpers.createParagraph()];

export interface Props {
  defaultValue?: Rte.Node[] | null;
  value?: Rte.Node[] | null;
  onChange?: (value: Rte.Node[] | null) => void;
  onBlur?: () => void;
  id?: string;
  placeholder?: string;
  isDisabled?: boolean;
  autoFocus?: boolean;
  disableFullScreen?: boolean;
  variables?: Rte.Variables;
  headings?: (1 | 2 | 3 | 4 | 5 | 6)[];
}

export default function EditorComponent({
  defaultValue,
  value: externalValue,
  onChange: externalOnChange,
  onBlur,
  id,
  placeholder,
  isDisabled = false,
  autoFocus = false,
  disableFullScreen = false,
  variables,
  headings = [1, 2, 3, 4, 5, 6],
}: Props) {
  const editor = useConstant(() => {
    const enhancedEditor = withVariables(withDomain(withHistory(withReact(createEditor()))));
    enhancedEditor.variables = variables || {};

    return enhancedEditor;
  });

  const editorNode = useRef<HTMLDivElement>(null);
  const modalNode = useRef<HTMLDivElement>(null);

  const [isFullScreen, toggleFullScreen, editorRect] = useFullScreen(editor, editorNode.current);

  const shouldRenderBackdrop = useDelayedAnimation(isFullScreen, BACKDROP_ANIMATION_DURATION);

  const {
    onChange: variablesOnChange,
    onKeyDown: variablesOnKeyDown,
    renderOptionsList: variablesRenderOptionsList,
  } = useVariables(editor);

  const isControlled = !!externalOnChange;

  const [value, setValue] = useState<Rte.Node[]>(() => (isControlled ? externalValue : defaultValue) || FALLBACK_VALUE);

  const onChange = (contents: Rte.Node[]) => {
    const externalValue = !slateHelpers.isEmpty(contents) ? contents : null;

    setValue(contents);
    externalOnChange?.(externalValue);

    variablesOnChange(contents);
  };

  const onKeyDown = (event: ReactKeyboardEvent<HTMLDivElement>) => {
    editor.keyDown(event);

    variablesOnKeyDown(event);
  };

  const handleBlur = () => {
    onBlur?.();
  };

  useEffect(() => {
    if (isControlled && slateHelpers.valuesDiffer(externalValue, value)) {
      const internalValue = !slateHelpers.isEmpty(externalValue) ? externalValue : FALLBACK_VALUE;

      editor.children = internalValue;
      setValue(internalValue);
    }
  }, [editor, externalValue, isControlled, value]);

  return (
    <>
      <div
        className={cx('rte', { 'is-disabled': isDisabled })}
        style={{
          width: editorRect?.width,
          height: editorRect?.height,
        }}
      >
        <Placeholder />

        <div className="rte-editor" ref={editorNode}>
          <Slate editor={editor} value={value} onChange={onChange}>
            <Toolbar
              isDisabled={isDisabled}
              isFullScreen={isFullScreen}
              headings={headings}
              toggleFullScreen={!disableFullScreen ? toggleFullScreen : undefined}
            />

            <Editable
              id={id}
              className="rte-content rte-html"
              onKeyDown={onKeyDown}
              renderElement={(props) => <Element {...props} />}
              renderLeaf={(props) => <Leaf {...props} />}
              readOnly={isDisabled}
              placeholder={!isDisabled ? placeholder : undefined}
              autoFocus={autoFocus}
              onBlur={handleBlur}
            />

            {variablesRenderOptionsList()}
          </Slate>
        </div>
      </div>

      <CSSTransition in={isFullScreen} timeout={BACKDROP_ANIMATION_DURATION} classNames="modal" nodeRef={modalNode}>
        {isFullScreen || shouldRenderBackdrop ? (
          <div ref={modalNode}>
            <div className="modal-backdrop" role="presentation" onClick={() => isFullScreen && toggleFullScreen()} />
          </div>
        ) : (
          <></>
        )}
      </CSSTransition>
    </>
  );
}
