import cx from 'classnames';
import { useIntl } from 'react-intl';
import { useSlate } from 'slate-react';

import Icon from 'components/Icon';

import { RTE_LIST, RTE_MARK } from '../domain';
import t from '../translations';
import BlockButton from './BlockButton';
import Button from './Button';
import ColorRow from './ColorRow';
import HeadingRow from './HeadingRow';
import MarkButton from './MarkButton';
import VariableRow from './VariableRow';

interface Props {
  isDisabled: boolean;
  isFullScreen: boolean;
  headings: (1 | 2 | 3 | 4 | 5 | 6)[];
  toggleFullScreen?: (() => void) | undefined;
}

export default function Toolbar({ isDisabled, isFullScreen, toggleFullScreen, headings }: Props) {
  const editor = useSlate();
  const { formatMessage } = useIntl();

  const doAction = (callback: () => void) => {
    return () => {
      callback();
      editor.focus();
    };
  };

  return (
    <div className={cx('rte-toolbar', { 'is-disabled': isDisabled })}>
      <div className="rte-toolbar-tools">
        <MarkButton format={RTE_MARK.bold} icon="format_bold" />
        <MarkButton format={RTE_MARK.italic} icon="format_italic" />
        <MarkButton format={RTE_MARK.underline} icon="format_underline" />

        <BlockButton format={RTE_LIST.ul} icon="format_list_bulleted" />
        <BlockButton format={RTE_LIST.ol} icon="format_list_numbered" />

        <VariableRow />
        <HeadingRow headings={headings} />
        <ColorRow />
      </div>

      <div className="rte-toolbar-separator" />

      <Button
        onClick={doAction(editor.clearFormatting)}
        className="rte-button-fullscreen"
        title={formatMessage(t.clearFormatting)}
        isDisabled={!editor.hasSelection()}
      >
        <Icon>format_clear</Icon>
      </Button>

      <Button onClick={doAction(editor.undo)} title={formatMessage(t.undo)} isDisabled={!editor.canUndo()}>
        <Icon>undo</Icon>
      </Button>

      <Button onClick={doAction(editor.redo)} title={formatMessage(t.redo)} isDisabled={!editor.canRedo()}>
        <Icon>redo</Icon>
      </Button>

      {toggleFullScreen ? (
        <Button
          onClick={toggleFullScreen}
          className="rte-button-fullscreen"
          title={formatMessage(isFullScreen ? t.exitFullScreen : t.enterFullScreen)}
        >
          <Icon>{isFullScreen ? 'fullscreen_exit' : 'fullscreen'}</Icon>
        </Button>
      ) : null}
    </div>
  );
}
