import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { useCurrentUser } from 'providers';

import Identity from 'components/Identity';

const t = defineMessages({
  accountTitle: { id: 'navbar_accountTitle', defaultMessage: 'My account' },
});

export default function HeaderCurrentUser() {
  const { formatMessage } = useIntl();

  const currentUser = useCurrentUser();

  return (
    <Link to="profile" title={formatMessage(t.accountTitle)} className="header-account">
      <Identity person={currentUser} withTitle />
    </Link>
  );
}
