import { RefObject, useEffect, useRef } from 'react';

export function useRestoreFocus(enabled: boolean) {
  const originalFocusRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (enabled) originalFocusRef.current = document.activeElement as any;

    return () => {
      if (enabled) originalFocusRef.current?.focus();
    };
  }, [enabled]);
}

export function useFocusFirst(rootRef: RefObject<HTMLElement>, enabled: boolean) {
  useEffect(() => {
    const root = rootRef.current;

    if (enabled && root) {
      window.setTimeout(() => {
        getFirstFocusableElement(root)?.focus();
      });
    }
  }, [enabled, rootRef]);
}

export function useTrapFocus(rootRef: RefObject<HTMLElement>, enabled: boolean) {
  useEffect(() => {
    const reactRoot = document.querySelector('#root');
    const root = rootRef.current;

    if (enabled && root) {
      let elements = getFocusableElements(root);

      const observer = new MutationObserver(() => (elements = getFocusableElements(root)));

      const onKeydown = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          const first = elements[0];
          const last = elements[elements.length - 1];

          if (document.activeElement === last && !event.shiftKey) {
            first.focus();
            event.preventDefault();
          }

          if (document.activeElement === first && event.shiftKey) {
            last.focus();
            event.preventDefault();
          }
        }
      };

      reactRoot?.setAttribute('inert', '');
      reactRoot?.setAttribute('aria-hidden', 'true');
      observer.observe(root, { subtree: true, childList: true, attributes: true });
      root.addEventListener('keydown', onKeydown);

      return () => {
        reactRoot?.removeAttribute('inert');
        reactRoot?.removeAttribute('aria-hidden');
        observer.disconnect();
        root.removeEventListener('keydown', onKeydown);
      };
    }
  }, [enabled, rootRef]);
}

function getFocusableElements(root: HTMLElement) {
  return [...root.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS.join(', '))].filter(hasNoHiddenParents);
}

function getFirstFocusableElement(root: HTMLElement) {
  return [...root.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS.join(', '))].find(hasNoHiddenParents);
}

function hasNoHiddenParents(root: HTMLElement) {
  let node: HTMLElement | null = root;

  while (node) {
    if (getComputedStyle(node).getPropertyValue('display') === 'none') {
      return false;
    }

    node = node.parentElement;
  }

  return true;
}

// https://github.com/ghosh/Micromodal/blob/master/lib/src/index.js
const FOCUSABLE_ELEMENTS = [
  'a[href]',
  'area[href]',
  'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
  'select:not([disabled]):not([aria-hidden])',
  'textarea:not([disabled]):not([aria-hidden])',
  'button:not([disabled]):not([aria-hidden])',
  'iframe',
  'object',
  'embed',
  '[contenteditable]',
  '[tabindex]:not([tabindex^="-"])',
];
