import cx from 'classnames';
import {
  CSSProperties,
  forwardRef,
  MutableRefObject,
  ChangeEvent as ReactChangeEvent,
  ReactElement,
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
  ReactNode,
  Ref,
  RefAttributes,
  useCallback,
  useId,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import { CancelablePromise, useCancelableAsyncFn } from 'hooks/useCancelableAsyncFn';
import useComposedRefs from 'hooks/useComposedRefs';
import useIsMounted from 'hooks/useIsMounted';
import useSyncedRef from 'hooks/useSyncedRef';

import Icon from 'components/Icon';
import Portal from 'components/Portal';

import { ClearIcon, DropdownIcon, Menu } from '../shared';
import { BaseInputProps } from '../types';
import { LoadingOptions } from './components/LoadingOptions';
import { MultiValue } from './components/MultiValue';
import { MultiValueContainer } from './components/MultiValueContainer';
import { NoOptions } from './components/NoOptions';
import { Option } from './components/Option';
import { OptionWrapper } from './components/OptionWrapper';
import { Placeholder } from './components/PlaceholderComponent';
import { SingleValue } from './components/SingleValue';
import { CLASSNAME, TESTID } from './constants';
import { useClearInputOnMultipleChange } from './hooks/useClearInputOnMultipleChange';
import { useDebouncedAsyncFn } from './hooks/useDebouncedAsyncFn';
import { useFilterOptionsOnInput } from './hooks/useFilterOptionsOnInput';
import { useHandleFocusLeave } from './hooks/useHandleFocusLeave';
import { usePopupMenu } from './hooks/usePopupMenu';
import { useSetMenuVisibility } from './hooks/useSetMenuVisibility';
import { useStopCreating } from './hooks/useStopCreating';
import { useSyncOptions } from './hooks/useSyncOptions';
import { useSyncValue } from './hooks/useSyncValue';
import { createInitialState, createReducer, selectAllOptions, selectVisibleOptions } from './state';
import { t } from './translations';
import { Components, SelectInputOption, Value } from './types';
import { filterOptions, hasExactOptionMatch } from './utils';

export interface SelectInputProps<IsMulti extends boolean = false, Val extends SelectInputOption['value'] = string>
  extends BaseInputProps<IsMulti extends true ? Val[] : Val> {
  options: SelectInputOption[];
  multiple?: IsMulti;
  openMenuOnFocus?: boolean;
  clearable?: boolean;
  loading?: boolean;
  searchable?: boolean;
  placeholder?: MessageDescriptor | string;
  searchPlaceholder?: MessageDescriptor | string;
  noOptionsMessage?: MessageDescriptor | string;
  loadingMessage?: MessageDescriptor | string;
  createOptionLabel?: (search: string) => string;
  components?: Components;
  onFetchOptions?: (search: string, signal: AbortSignal) => Promise<SelectInputOption[]>;
  onCreateOption?: (search: string, signal: AbortSignal) => Promise<SelectInputOption>;
  targetRef?: MutableRefObject<HTMLButtonElement | null>;
  className?: string;
  menuClassName?: string;
  style?: CSSProperties;
  menuRef?: MutableRefObject<HTMLDivElement | null>;
  menuOffset?: number;
  menuMinWidth?: number;
  afterListboxContent?: ReactNode;
  __test__debounceTime?: number;
}

export interface SelectInputImparativeHandle {
  closeMenu: () => void;
  clear: () => void;
}

const SelectInput = forwardRef(function SelectInput<
  IsMulti extends boolean = false,
  Val extends SelectInputOption['value'] = string
>(props: SelectInputProps<IsMulti, Val>, ref: Ref<SelectInputImparativeHandle | null>) {
  const {
    id,
    value,
    defaultValue,
    options,
    multiple = false as IsMulti,
    openMenuOnFocus = false,
    clearable = true,
    loading = false,
    disabled = false,
    searchable,
    placeholder,
    searchPlaceholder,
    noOptionsMessage,
    loadingMessage,
    createOptionLabel,
    components,
    onChange,
    onBlur,
    onFetchOptions,
    onCreateOption,
    targetRef: externalTargetRef,
    className,
    menuClassName,
    style,
    menuRef: externalMenuRef,
    menuOffset = 0,
    menuMinWidth,
    afterListboxContent,
    __test__debounceTime,
  } = props;

  const popupId = `popup-${useId()}`;

  const { formatMessage } = useIntl();
  const text = (text: string | MessageDescriptor) => (isMessageDescriptor(text) ? formatMessage(text) : text);

  const isControlled = !!onChange;
  const [state, dispatch] = useReducer(createReducer(), createInitialState({ options }));
  const [internalValue, setInternalValue] = useState<Value | null>(isControlled ? value || null : defaultValue || null);
  const [isVisible, setIsVisible] = useState(false);

  const selectRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const listBoxRef = useRef<HTMLDivElement | null>(null);

  const enableFocusCallbackRef = useRef(true);
  const cancelableCreateOptionControllerRef = useRef<AbortController>();
  const cancelableCreateOptionPromiseRef = useRef<CancelablePromise<any>>();

  const onBlurRef = useSyncedRef(onBlur);

  const allOptions = selectAllOptions(state);
  const visibleOptions = selectVisibleOptions(state);
  const cleanInputValue = state.inputValue.trim();
  const hasInputValue = !!cleanInputValue.length;
  const hasValue = Array.isArray(internalValue) ? internalValue.length > 0 : internalValue !== null;
  const noMenuScrollNeeded = allOptions.length <= 7;

  const shouldRenderSearch =
    !!onFetchOptions || !!onCreateOption || searchable === true || (!noMenuScrollNeeded && searchable !== false);
  const shouldRenderLoading = loading || state.isFetchingOptions;
  const shouldRenderOptions = !shouldRenderLoading;
  const shouldRenderNoOptions = !shouldRenderLoading && !visibleOptions.length;
  const shouldRenderCreateOption =
    hasInputValue === true &&
    state.isFetchingOptions === false &&
    onCreateOption !== undefined &&
    (filterOptions(visibleOptions, cleanInputValue).length === 0 ||
      !hasExactOptionMatch(visibleOptions, cleanInputValue));

  const renderedOptionsCount = visibleOptions.length + (shouldRenderCreateOption ? 1 : 0);

  const { setActuator, actuatorRef, setMenu, menuRef, updateMenu, x, y, strategy, isFlipped } = usePopupMenu(
    menuOffset,
    menuMinWidth
  );

  const listboxId = `nf-select-listbox-${useId()}`;

  const isMounted = useIsMounted();
  const debouncedOnFetchOptions = useDebouncedAsyncFn(onFetchOptions, __test__debounceTime || 500);
  const cancelableOnCreateOption = useCancelableAsyncFn(onCreateOption);

  const focusTarget = useCallback(() => {
    requestAnimationFrame(() => {
      enableFocusCallbackRef.current = false;
      actuatorRef.current?.focus();
      onBlurRef.current?.();
      queueMicrotask(() => {
        enableFocusCallbackRef.current = true;
      });
    });
  }, [actuatorRef, onBlurRef]);

  const focusInput = useCallback(() => {
    window.setTimeout(() => {
      inputRef.current?.focus();
    });
  }, []);

  const openMenu = useCallback(() => {
    dispatch({ type: 'OPEN_MENU' });
  }, [dispatch]);

  const closeMenu = useCallback(() => {
    dispatch({ type: 'CLOSE_MENU' });
    dispatch({ type: 'CLEAR_INPUT' });
    onBlurRef.current?.();
  }, [dispatch, onBlurRef]);

  const setValue = useCallback(
    (value: Value | null) => {
      setInternalValue(value);
      onChange?.(value as any);
    },
    [onChange]
  );

  const clear = useCallback(() => {
    if (multiple && Array.isArray(internalValue)) {
      const selectedDisabledOptions = allOptions.filter(
        (option) => option.disabled && internalValue.includes(option.value)
      );
      setValue(selectedDisabledOptions.map((option) => option.value));
    }

    if (!multiple && !Array.isArray(internalValue)) {
      setValue(null);
    }
  }, [allOptions, internalValue, multiple, setValue]);

  const enhancedSetActuator = useComposedRefs(setActuator, externalTargetRef);
  const enhancedSetMenu = useComposedRefs(setMenu, externalMenuRef);

  useImperativeHandle(ref, () => ({
    closeMenu,
    clear,
  }));

  useSetMenuVisibility({ state, setIsVisible, listBoxRef });
  useFilterOptionsOnInput({ dispatch, cleanInputValue, debouncedOnFetchOptions });
  useSyncValue({ value: value as any, isControlled, setInternalValue });
  useSyncOptions({ options, state, dispatch });
  useClearInputOnMultipleChange({ setValue, dispatch, multiple, updateMenu });
  useHandleFocusLeave({ actuatorRef, menuRef, closeMenu, focusTarget });
  useStopCreating({
    dispatch,
    state,
    cleanInputValue,
    internalValue,
    cancelableCreateOptionControllerRef,
    cancelableCreateOptionPromiseRef,
  });

  const focusOption = (index: number) => {
    const option = listBoxRef.current?.querySelector<HTMLDivElement>(`.${CLASSNAME}__option[data-index="${index}"]`);
    if (option) {
      option.focus();
    }
  };

  const isSelected = (value: SelectInputOption['value']) => {
    if (multiple && Array.isArray(internalValue)) {
      return internalValue.includes(value);
    }
    if (!multiple && !Array.isArray(internalValue)) {
      return internalValue === value;
    }

    return false;
  };

  const toggleOption = (option: SelectInputOption) => {
    const { disabled, value } = option;

    if (disabled) {
      return;
    }

    if (multiple) {
      if (Array.isArray(internalValue)) {
        if (isSelected(value)) {
          setValue(internalValue.filter((v) => v !== value));
        } else {
          const values = [...internalValue, value];
          const allValues = allOptions.map((option) => option.value);
          setValue(values.sort((a, b) => allValues.indexOf(a) - allValues.indexOf(b)));
        }
      } else {
        setValue([value]);
      }
    } else {
      if (internalValue !== value) {
        setValue(value);
      } else {
        if (clearable) {
          setValue(null);
        }
      }
      if (state.isMenuOpen) {
        closeMenu();
        focusTarget();
      }
    }

    updateMenu();
  };

  const onInputChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    dispatch({ type: 'SET_INPUT', payload: value });
  };

  const toggleMenu = () => {
    if (state.isMenuOpen) {
      closeMenu();
      focusTarget();
    } else {
      openMenu();
      window.setTimeout(() => {
        focusInput();
      });
    }
  };

  const focusFirstOption = () => {
    if (noMenuScrollNeeded) {
      focusOption(0);
    } else {
      const options = [...(listBoxRef.current?.querySelectorAll<HTMLDivElement>(`.${CLASSNAME}__option`) || [])];

      const firstSelectedOptionIndex = options.findIndex((option) => option.classList.contains('-is-selected'));
      if (firstSelectedOptionIndex > -1) {
        focusOption(firstSelectedOptionIndex);

        return;
      }

      const firstEnabledOptionIndex = options.findIndex((option) => !option.classList.contains('-is-disabled'));
      if (firstEnabledOptionIndex) {
        focusOption(firstEnabledOptionIndex);

        return;
      }

      focusOption(0);

      return;
    }
  };

  const onTargetClick = () => {
    toggleMenu();
  };

  const onTargetKeyDown = (event: ReactKeyboardEvent<HTMLButtonElement>) => {
    switch (event.key) {
      case ' ':
      case 'Enter': {
        event.preventDefault();
        toggleMenu();
        break;
      }

      case 'ArrowDown': {
        event.preventDefault();
        focusFirstOption();
        break;
      }

      case 'Escape': {
        if (state.isMenuOpen) {
          event.preventDefault();
          closeMenu();
        }
        break;
      }

      case 'Tab': {
        if (state.isMenuOpen && !event.shiftKey) {
          event.preventDefault();
          closeMenu();
        }
        break;
      }
    }
  };

  const onTargetFocus = () => {
    if (!state.isMenuOpen && openMenuOnFocus && enableFocusCallbackRef.current) {
      toggleMenu();
    }
  };

  const onInputKeyDown = (event: ReactKeyboardEvent) => {
    if (!state.isMenuOpen) return;

    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        focusFirstOption();
        break;
      }

      case 'Tab':
      case 'Escape': {
        event.preventDefault();
        closeMenu();
        focusTarget();
        break;
      }

      case 'Enter': {
        event.preventDefault();
        closeMenu();
        focusTarget();
        break;
      }
    }
  };

  const onOptionChoose = (option: SelectInputOption) => {
    if (option.disabled) {
      return;
    }

    addOptionIfMissing(option);
    toggleOption(option);
  };

  const onOptionKeyDown = (event: ReactKeyboardEvent, option?: SelectInputOption) => {
    const focusedOptionIndex = (() => {
      const option = listBoxRef.current?.querySelector<HTMLDivElement>(`.${CLASSNAME}__option:focus`);
      const index = option?.dataset.index;

      return index ? parseInt(index, 10) : undefined;
    })();

    if (focusedOptionIndex === undefined) {
      return;
    }

    switch (event.key) {
      case 'ArrowUp': {
        event.preventDefault();
        if (focusedOptionIndex === 0) {
          focusInput();
        } else {
          const options = [...(listBoxRef.current?.querySelectorAll<HTMLDivElement>(`.${CLASSNAME}__option`) || [])];
          const enabledOptions = options.filter((option) => !option.classList.contains('-is-disabled'));
          const indeces = enabledOptions.map((option) => parseInt(option.dataset.index!, 10));
          const index = indeces.reverse().find((index) => index < focusedOptionIndex);
          if (index !== undefined) {
            focusOption(index);
          }
        }
        break;
      }

      case 'ArrowDown': {
        event.preventDefault();
        const options = [...(listBoxRef.current?.querySelectorAll<HTMLDivElement>(`.${CLASSNAME}__option`) || [])];
        const enabledOptions = options.filter((option) => !option.classList.contains('-is-disabled'));
        const indeces = enabledOptions.map((option) => parseInt(option.dataset.index!, 10));
        const index = indeces.find((index) => index > focusedOptionIndex);
        if (index !== undefined) {
          focusOption(index);
        }
        break;
      }

      case 'Tab':
      case 'Escape': {
        event.preventDefault();
        if (shouldRenderSearch) {
          focusInput();
        } else {
          focusTarget();
          closeMenu();
        }
        break;
      }

      case ' ':
      case 'Enter': {
        event.preventDefault();
        if (option) {
          onOptionChoose(option);
        }
        break;
      }
    }
  };

  const onCreateOptionChoose = (value: string) => {
    dispatch({ type: 'STOP_CREATING_OPTION' });
    cancelableCreateOptionControllerRef.current?.abort();
    cancelableCreateOptionPromiseRef.current?.cancel();

    if (cancelableOnCreateOption) {
      dispatch({ type: 'START_CREATING_OPTION' });
      const controller = new AbortController();
      const promise = cancelableOnCreateOption(value, controller.signal);
      cancelableCreateOptionControllerRef.current = controller;
      cancelableCreateOptionPromiseRef.current = promise;

      promise
        .then((option) => {
          const createdOption: SelectInputOption = { ...option, __isNew__: true };

          if (isMounted()) {
            dispatch({ type: 'STOP_CREATING_OPTION' });
            dispatch({ type: 'ADD_OPTION', payload: createdOption });
            dispatch({ type: 'CLEAR_INPUT' });
            toggleOption(createdOption);
            if (multiple) {
              focusInput();
            }
          }
        })
        .catch(() => {
          dispatch({ type: 'STOP_CREATING_OPTION' });
        });
    }
  };

  const onClearIconClick = (event: ReactMouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    clear();
  };

  const addOptionIfMissing = (option: SelectInputOption) => {
    if (!allOptions.find((o) => o.value === option.value)) {
      dispatch({ type: 'ADD_OPTION', payload: option });
    }
  };

  const onCreateOptionKeyDown = (event: ReactKeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onCreateOptionChoose(cleanInputValue);
    } else {
      onOptionKeyDown(event);
    }
  };

  const SingleValueComponent = components?.SingleValue || SingleValue;
  const MultiValueComponent = components?.MultiValue || MultiValue;
  const MultiValueContainerComponent = components?.MultiValueContainer || MultiValueContainer;
  const PlaceholderComponent = components?.Placeholder || Placeholder;
  const LoadingOptionsComponent = components?.LoadingOptions || LoadingOptions;
  const NoOptionsComponent = components?.NoOptions || NoOptions;
  const CreateOptionComponent = components?.CreateOption || Option;
  const OptionComponent = components?.Option || Option;
  const MenuComponent = components?.Menu || Menu;

  return (
    <div
      ref={selectRef}
      className={cx(className, CLASSNAME, 'base-input', '-type-select', {
        '-is-multi': multiple,
        '-is-single': !multiple,
        '-menu-open': state.isMenuOpen,
        '-menu-closed': !state.isMenuOpen,
      })}
      style={style}
      tabIndex={-1}
      data-testid={TESTID}
    >
      <button
        id={id}
        className={`${CLASSNAME}__target`}
        type="button"
        aria-label="Select"
        aria-haspopup="listbox"
        aria-expanded={state.isMenuOpen}
        aria-controls={popupId}
        ref={enhancedSetActuator}
        onClick={onTargetClick}
        onKeyDown={onTargetKeyDown}
        onFocus={onTargetFocus}
        data-testid={`${TESTID}-target`}
        disabled={disabled}
      >
        <div className={`${CLASSNAME}__target__content`}>
          {hasValue ? (
            <>
              {multiple ? (
                <MultiValueContainerComponent
                  value={internalValue}
                  options={allOptions}
                  toggleOption={toggleOption}
                  Component={MultiValueComponent}
                />
              ) : (
                <SingleValueComponent value={internalValue} options={allOptions} />
              )}
            </>
          ) : (
            <PlaceholderComponent
              placeholder={
                placeholder
                  ? isMessageDescriptor(placeholder)
                    ? formatMessage(placeholder)
                    : placeholder
                  : formatMessage(t.placeholder)
              }
            />
          )}
        </div>

        <div className={`${CLASSNAME}__target__controls`}>
          {hasValue && clearable && !disabled ? (
            <div
              className={`${CLASSNAME}__clear-icon`}
              data-testid={`${TESTID}-clear`}
              onClick={onClearIconClick}
              title={formatMessage(t.clear)}
            >
              <ClearIcon />
            </div>
          ) : null}

          <div
            className={cx(`${CLASSNAME}__dropdown-icon`, { '-is-flipped': state.isMenuOpen })}
            title={state.isMenuOpen ? formatMessage(t.closeMenu) : formatMessage(t.openMenu)}
          >
            <DropdownIcon />
          </div>
        </div>
      </button>

      {state.isMenuOpen ? (
        <Portal>
          <MenuComponent
            ref={enhancedSetMenu}
            htmlProps={{
              'id': popupId,
              'className': cx(menuClassName, `${CLASSNAME}__menu`, {
                [`-placement-${isFlipped ? 'top' : 'bottom'}`]: isVisible,
                '-is-visible': isVisible,
              }),
              'style': { position: strategy, transform: `translate(${x ?? 0}px, ${y ?? 0}px)` },
              'tabIndex': -1,
              'data-testid': `${TESTID}-menu`,
            }}
            context={{
              inputValue: cleanInputValue,
              isFetchingOptions: state.isFetchingOptions,
            }}
          >
            {shouldRenderSearch ? (
              <div className={`${CLASSNAME}__search`} tabIndex={-1}>
                <div className={`${CLASSNAME}__search__search-icon`}>
                  <Icon size="small">search</Icon>
                </div>

                <input
                  ref={inputRef}
                  type="text"
                  placeholder={text(searchPlaceholder || t.searchPlaceholder)}
                  role="combobox"
                  aria-controls={listboxId}
                  aria-expanded={true}
                  className={`${CLASSNAME}__search__input`}
                  value={state.inputValue}
                  onChange={onInputChange}
                  onKeyDown={onInputKeyDown}
                  data-testid={`${TESTID}-input`}
                />
              </div>
            ) : null}

            <div
              ref={listBoxRef}
              id={listboxId}
              role="listbox"
              tabIndex={state.isMenuOpen ? 0 : -1}
              className={`${CLASSNAME}__listbox`}
              data-testid={`${TESTID}-listbox`}
            >
              {shouldRenderLoading ? <LoadingOptionsComponent text={loadingMessage} /> : null}

              {shouldRenderOptions
                ? visibleOptions.map((option, index) => (
                    <OptionWrapper
                      key={option.value}
                      showCheckbox
                      option={option}
                      index={index}
                      isLoading={false}
                      isMultiple={multiple}
                      isSelected={isSelected}
                      onChoose={() => onOptionChoose(option)}
                      onKeyDown={(event) => onOptionKeyDown(event, option)}
                      Component={OptionComponent}
                    />
                  ))
                : null}

              {shouldRenderNoOptions ? <NoOptionsComponent text={noOptionsMessage} /> : null}

              {shouldRenderCreateOption ? (
                <OptionWrapper
                  showCheckbox={false}
                  option={{
                    label: createOptionLabel
                      ? createOptionLabel(cleanInputValue)
                      : formatMessage(t.createOption, { text: cleanInputValue }),
                    value: '__optionToCreate__',
                  }}
                  index={renderedOptionsCount - 1}
                  isLoading={state.isCreatingOption}
                  isMultiple={multiple}
                  isSelected={() => false}
                  onChoose={() => onCreateOptionChoose(cleanInputValue)}
                  onKeyDown={onCreateOptionKeyDown}
                  Component={CreateOptionComponent}
                />
              ) : null}
            </div>

            {afterListboxContent}
          </MenuComponent>
        </Portal>
      ) : null}
    </div>
  );
});

SelectInput.displayName = 'SelectInput';

const TypedSelectInput = SelectInput as <
  IsMulti extends boolean = false,
  Val extends SelectInputOption['value'] = string
>(
  props: SelectInputProps<IsMulti, Val> & RefAttributes<SelectInputImparativeHandle | null>
) => ReactElement;

export { TypedSelectInput as SelectInput };
