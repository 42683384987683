import cx from 'classnames';
import { FocusEvent, MouseEvent, ReactNode } from 'react';

interface Props {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  onFocus?: (event: FocusEvent<HTMLButtonElement>) => void;
  onBlur?: (event: FocusEvent<HTMLButtonElement>) => void;
  title?: string;
  isDisabled?: boolean;
  isActive?: boolean;
  className?: string;
  children: ReactNode;
}

export default function ToolbarButton({
  onClick,
  onFocus,
  onBlur,
  title,
  isDisabled = false,
  isActive = false,
  className = '',
  children,
}: Props) {
  return (
    <button
      type="button"
      className={cx('rte-button', className, { 'is-active': isActive })}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      title={title}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
}
