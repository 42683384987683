import { defineMessages, useIntl } from 'react-intl';

import ActionButton from 'components/ActionButton';
import { Tooltip } from 'components/Popover';

export default function LaunchIDButton({ machineId, uri }: { machineId: string; uri: string | null }) {
  const { formatMessage } = useIntl();

  const onClick = () => {
    if (uri) window.location.href = uri;
  };

  return machineId === 'nipro_surdial_x' ? (
    <Tooltip placement="top" content={formatMessage(t.tooltip)}>
      <ActionButton icon="credit_card" title="" onClick={onClick} />
    </Tooltip>
  ) : null;
}

const t = defineMessages({
  tooltip: {
    id: 'nephroflow_id_tooltip',
    defaultMessage: "Launch NephroFlow ID. Please verify you've installed NephroFlow ID, before clicking this button.",
  },
});
