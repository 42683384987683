import { MutableRefObject, useEffect } from 'react';

export function useHandleFocusLeave({
  actuatorRef,
  menuRef,
  closeMenu,
  focusTarget,
}: {
  actuatorRef: MutableRefObject<HTMLButtonElement | null>;
  menuRef: MutableRefObject<HTMLElement | null>;
  closeMenu: () => void;
  focusTarget: () => void;
}) {
  const actuator = actuatorRef.current;
  const menu = menuRef.current;

  useEffect(() => {
    if (!actuator || !menu) return;

    const onFocusOut = (event: FocusEvent) => {
      const focusedElement = (event.relatedTarget || document.body) as HTMLElement;

      if (!menu.contains(focusedElement)) {
        if (!actuator.contains(focusedElement)) {
          closeMenu();
        } else {
          focusTarget();
        }
      }
    };

    actuator.addEventListener('focusout', onFocusOut);
    menu.addEventListener('focusout', onFocusOut);

    return () => {
      actuator.removeEventListener('focusout', onFocusOut);
      menu.removeEventListener('focusout', onFocusOut);
    };
  }, [actuator, menu, closeMenu, focusTarget]);
}
