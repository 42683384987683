import { ReactNode } from 'react';

import { Rte, slateHelpers } from '../domain';

interface Props {
  children: ReactNode;
  attributes: any;
  leaf: Rte.TextLike;
}

export default function Leaf({ children, attributes, leaf }: Props) {
  let contents = children;

  if (leaf.bold) contents = <strong>{contents}</strong>;
  if (leaf.italic) contents = <em>{contents}</em>;
  if (leaf.underline) contents = <u>{contents}</u>;

  return (
    <span {...attributes} style={slateHelpers.getStyleAttributes(leaf)}>
      {contents}
    </span>
  );
}
