interface NamedPerson {
  lastName?: null | string;
  firstName?: null | string;
  title?: null | string;
}

export default function fullName<P extends NamedPerson>(person?: P | null | undefined, withTitle = false): string {
  if (!person) return '';

  const { lastName, firstName, title } = person;

  return [withTitle ? title : '', lastName, firstName].filter((str) => str && str.length > 0).join(' ');
}
