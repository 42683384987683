import Icon from 'components/Icon';

interface Props {
  error: string;
  detailsList?: string[];
}

export default function FormError({ error, detailsList }: Props) {
  return (
    <div role="alert" className="formv3__error">
      <Icon>error</Icon>
      <div>
        <span>{error}</span>
        {detailsList ? (
          <ul>
            {detailsList.map((line, index) => (
              <li key={`${line}_${index}`}>{line}</li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
}
