import isEqual from 'lodash/isEqual';
import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import searchString from 'utils/searchString';
import { SessionStorage } from 'utils/storage';

import useSyncedRef from 'hooks/useSyncedRef';

type Values = Record<string, any>;
type UpdateType = 'push' | 'replace';
type SetSearchString<V extends Values> = (callback: SetStateAction<V>, updateType?: UpdateType) => void;
type Config = { cacheKey?: string };

export type { SetSearchString };

export default function useSearchString<V extends Values>(defaultValues: V, { cacheKey }: Config = {}) {
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState<V>({
    ...defaultValues,
    ...(cacheKey ? getCache(cacheKey) : {}),
    ...searchString.decode(location.search),
  });

  const valuesRef = useSyncedRef(values);
  const ranOnceRef = useRef(false);
  const defaultValuesRef = useRef(defaultValues);

  const shouldUpdate = useCallback((nextValues: V) => !isEqual(valuesRef.current, nextValues), [valuesRef]);

  // Remember the search string during this browser session.
  useEffect(() => {
    if (cacheKey) {
      setCache(cacheKey, values);
    }
  }, [values, cacheKey]);

  // Only runs once to merge current search string with initial values.
  useEffect(() => {
    if (!ranOnceRef.current) {
      navigate('?' + searchString.encode(values), { replace: true });
    }
  }, [navigate, values]);

  // Runs every time the location changes to update the values state.
  useEffect(() => {
    if (ranOnceRef.current) {
      const nextValues = { ...defaultValuesRef.current, ...searchString.decode<V>(location.search) };

      if (shouldUpdate(nextValues)) setValues(nextValues);
    }
  }, [location.search, shouldUpdate]);

  useEffect(() => {
    ranOnceRef.current = true;
  }, []);

  const enchancedSetValues: SetSearchString<V> = useCallback(
    (callback, updateType = 'replace') => {
      const nextValues = callback instanceof Function ? callback(valuesRef.current) : callback;

      if (shouldUpdate(nextValues)) {
        navigate('?' + searchString.encode(nextValues), {
          replace: updateType === 'replace',
        });
        setValues(nextValues);
      }
    },
    [valuesRef, shouldUpdate, navigate]
  );

  return [values, enchancedSetValues] as const;
}

function prefixStorageKey(key: string) {
  return `search_string_${key.toLowerCase()}`;
}

function getCache(key: string): Values {
  return searchString.decode(SessionStorage.getRaw(prefixStorageKey(key)) || '');
}

function setCache(key: string, value: Values) {
  return SessionStorage.setRaw(prefixStorageKey(key), searchString.encode(value));
}
