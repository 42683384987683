export default async function fontLoader() {
  const { default: appIconsLoader } = await import('./app-icons/appIconsLoader');
  const { default: materialIconsLoader } = await import('./material-icons/materialIconsLoader');
  const { default: openSansLoader } = await import('./open-sans/openSansLoader');

  const styleTag = document.createElement('style');
  styleTag.setAttribute('type', 'text/css');

  [appIconsLoader, materialIconsLoader, openSansLoader]
    .flatMap((loader) => loader())
    .forEach((fontFace) => {
      styleTag.appendChild(document.createTextNode(fontFace));
    });

  document.head.appendChild(styleTag);
}
