import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { medicinalProductProviderSchema } from 'store/modules/entities/actions/medicationManagement/providers';
import { registerFormErrorAction } from 'store/modules/errors';
import { networkActionTypes } from 'store/utils';

export const INGREDIENT = new schema.Entity(
  'ingredients',
  { source: medicinalProductProviderSchema },
  { processStrategy: schemaDeserializers({ updatedAt: parseDateTime }) }
);

export const GET_MEDICINAL_INGREDIENTS = networkActionTypes('GET_MEDICINAL_INGREDIENTS');

const PAGE_SIZE = 30;

export const getIngredients = ({
  page,
  sourceId,
  name,
}: {
  page: number;
  sourceId: string | null | undefined;
  name: string | null | undefined;
}): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: GET_MEDICINAL_INGREDIENTS,
    url: 'medications/ingredients',
    method: 'GET',
    params: {
      page,
      sourceId,
      name,
      pageSize: PAGE_SIZE,
      codesAsTables: true,
    },
    normalizeSchema: { data: [INGREDIENT] },
  },
});

export const CREATE_MEDICINAL_INGREDIENT = networkActionTypes('CREATE_MEDICINAL_INGREDIENT');

registerFormErrorAction(CREATE_MEDICINAL_INGREDIENT.FAILURE);

export const createIngredient = (ingredient: {
  name: string;
  sourceId: string;
  ingredientType: string;
  codes: CodesT;
}): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: CREATE_MEDICINAL_INGREDIENT,
    url: 'medications/ingredients',
    method: 'POST',
    payload: { codesAsTables: true, ingredient },
    normalizeSchema: { ingredient: INGREDIENT },
  },
});

export const UPDATE_MEDICINAL_INGREDIENT = networkActionTypes('UPDATE_MEDICINAL_INGREDIENT');

registerFormErrorAction(UPDATE_MEDICINAL_INGREDIENT.FAILURE);

export const updateIngredient = (ingredient: {
  id: string;
  name: string;
  ingredientType: string;
  codes: CodesT;
}): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_MEDICINAL_INGREDIENT,
    url: `medications/ingredients/${ingredient.id}`,
    method: 'PUT',
    payload: { codesAsTables: true, ingredient },
    normalizeSchema: { ingredient: INGREDIENT },
  },
});

export const DELETE_MEDICINAL_INGREDIENT = networkActionTypes('DELETE_MEDICINAL_INGREDIENT');

export const deleteIngredient = (id: string): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: DELETE_MEDICINAL_INGREDIENT,
    url: `medications/ingredients/${id}`,
    method: 'DELETE',
    actionPayload: { id },
  },
});
