import { useEffect } from 'react';

import useSyncedRef from './useSyncedRef';

type Key = 'Escape';

export default function useKeydownListener(key: Key, callback: (event: KeyboardEvent) => void) {
  const callbackRef = useSyncedRef(callback);

  useEffect(() => {
    const onKeydown = (event: KeyboardEvent) => {
      if (event.key === key && !event.defaultPrevented) {
        callbackRef.current(event);
      }
    };

    window.addEventListener('keydown', onKeydown);

    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  }, [key, callbackRef]);
}
