import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import { Value } from '../types';

export function useSyncValue({
  value,
  isControlled,
  setInternalValue,
}: {
  value: Value | null;
  isControlled: boolean;
  setInternalValue: Dispatch<SetStateAction<Value | null>>;
}) {
  const lastValueRef = useRef(value);

  useEffect(() => {
    if (!valuesAreEqual(lastValueRef.current, value)) {
      lastValueRef.current = value;

      if (isControlled) {
        setInternalValue(value || null);
      }
    }
  }, [value, isControlled, setInternalValue]);
}

function valuesAreEqual(value1: Value | null, value2: Value | null) {
  return stringValue(value1) === stringValue(value2);
}

function stringValue(value: Value | null) {
  return value ? (Array.isArray(value) ? value.join('') : value) : null;
}
