import { createContext, CSSProperties, Dispatch, MutableRefObject, ReactNode, SetStateAction, useContext } from 'react';

import assert from 'utils/assert';

export interface TableGroupContextT {
  ref: MutableRefObject<HTMLTableSectionElement | null>;
  style: CSSProperties;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const TableGroupContext = createContext<TableGroupContextT | undefined>(undefined);

interface TableGroupProviderProps {
  children: ReactNode;
  value: TableGroupContextT;
}

export function TableGroupProvider({ children, value }: TableGroupProviderProps) {
  return <TableGroupContext.Provider value={value}>{children}</TableGroupContext.Provider>;
}

export function useOptionalTableGroup() {
  return useContext(TableGroupContext);
}

export function useTableGroup() {
  const context = useContext(TableGroupContext);

  assert(context, 'The hook `useTableGroup` must be used within a `TableGroupProvider`.');

  return context;
}
