import { useCallback, useEffect, useRef, useState } from 'react';

import useIsMounted from 'hooks/useIsMounted';
import useSyncedRef from 'hooks/useSyncedRef';

import { Rte } from '../domain';
import { disableFullScreen, enableFullScreen, Rect } from '../utils/animateFullScreen';

const ANIMATION_DURATION = 250;

export default function useFullScreen(editor: Rte.Editor, editorNode: HTMLElement | null) {
  const isMounted = useIsMounted();

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [debounce, setDebounce] = useState(false);

  const toggleFullScreen = useCallback(() => {
    if (!debounce) {
      setDebounce(true);
      setIsFullScreen(!isFullScreen);
    }
  }, [debounce, isFullScreen]);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (isFullScreen && editorNode) {
        const clickedNode: HTMLElement | null = event.target as any;

        if (clickedNode && !editorNode.contains(clickedNode)) {
          toggleFullScreen();
        }
      }
    },
    [editorNode, isFullScreen, toggleFullScreen]
  );

  useEffect(() => {
    if (debounce) window.setTimeout(() => setDebounce(false), ANIMATION_DURATION + 200);
  }, [debounce]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => document.removeEventListener('mousedown', handleClick);
  }, [handleClick]);

  const editorNodeRef = useSyncedRef(editorNode);
  const editorRef = useSyncedRef(editor);
  const editorRectRef = useRef<Rect>();

  useEffect(() => {
    (async () => {
      if (editorNodeRef.current) {
        if (isFullScreen) {
          editorRectRef.current = editorNodeRef.current.getBoundingClientRect();

          await enableFullScreen(editorNodeRef.current, ANIMATION_DURATION);

          if (isMounted()) {
            editorRef.current.focus();
          }
        } else {
          if (editorRectRef.current) {
            await disableFullScreen(editorNodeRef.current, editorRectRef.current, ANIMATION_DURATION);

            if (isMounted()) {
              editorRef.current.focus();
              editorRectRef.current = undefined;
            }
          }
        }
      }
    })();
  }, [isMounted, isFullScreen, editorRef, editorNodeRef]);

  return [isFullScreen, toggleFullScreen, editorRectRef.current] as const;
}
