import { useCallback } from 'react';

import { cancelActionBarActivity } from 'store/modules/activity';

import { useAppDispatch } from 'hooks/redux';
import useIsMounted from 'hooks/useIsMounted';

export default function useCloseActionBar() {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();

  return useCallback(() => {
    if (isMounted()) dispatch(cancelActionBarActivity());
  }, [dispatch, isMounted]);
}
