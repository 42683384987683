import jwtDecode, { JwtPayload } from 'jwt-decode';

import { SECOND_IN_MILLIS } from 'utils/date';

import { UserToken } from './types';

export function getJwtUserId(userToken: UserToken): string | undefined {
  if (!userToken.access) return;

  const { sub } = jwtDecode<JwtPayload>(userToken.access);

  return sub;
}

export function isJwtValid(jwt: JwtPayload): boolean {
  const secondsSinceEpoch = Math.floor(Date.now() / SECOND_IN_MILLIS);

  if (!jwt.exp) {
    return false;
  }

  return secondsSinceEpoch < jwt.exp;
}

export function authenticated({ access, refresh }: UserToken): boolean {
  if (!access || !refresh) return false;

  try {
    const jwtToken = jwtDecode<JwtPayload>(access);
    const jwtRefresh = jwtDecode<JwtPayload>(refresh);

    return isJwtValid(jwtToken) || isJwtValid(jwtRefresh);
  } catch (error) {
    return false;
  }
}
