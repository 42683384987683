import { ChangeEvent, FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from 'components/Button';

import t from '../translations';

interface RecoverAccountFormProps {
  onSubmit: (values: { email: string }) => void;
}

export default function RecoverAccountForm({ onSubmit }: RecoverAccountFormProps) {
  const [email, setEmail] = useState('');
  const { formatMessage } = useIntl();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setEmail(value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit({ email });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="email" className="sr-only">
        <FormattedMessage {...t.email} />
      </label>

      <input
        autoFocus
        type="email"
        id="email"
        name="email"
        placeholder={formatMessage(t.email)}
        value={email}
        onChange={handleChange}
      />

      <Button type="submit">{t.requestSubmit}</Button>
    </form>
  );
}
