import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'patient_cards_title',
    defaultMessage: 'Patient cards',
  },

  centerFilter: {
    id: 'patient_cards_center_filter',
    defaultMessage: 'Center',
  },
  searchFilter: {
    id: 'patient_cards_search_filter',
    defaultMessage: 'Search',
  },
  all: {
    id: 'patient_cards_center_filter_all',
    defaultMessage: 'All',
  },

  type: {
    id: 'patient_card_type',
    defaultMessage: 'Type',
  },
  identifier: {
    id: 'patient_card_identifier',
    defaultMessage: 'Identifier',
  },
  externalIdentifier: {
    id: 'patient_card_external_identifier',
    defaultMessage: 'External Identifier',
  },
  firstName: {
    id: 'patient_card_first_name',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'patient_card_last_name',
    defaultMessage: 'Last name',
  },
  sex: {
    id: 'patient_card_sex',
    defaultMessage: 'Sex',
  },
  birthdate: {
    id: 'patient_card_birthdate',
    defaultMessage: 'Birthdate',
  },

  center: {
    id: 'patient_card_center',
    defaultMessage: 'Center',
  },
  patientInformation: {
    id: 'patient_card_patient_information',
    defaultMessage: 'Patient',
  },

  create: {
    id: 'patient_card_create',
    defaultMessage: 'Create patient card',
  },
  createSuccess: {
    id: 'patient_card_create_success',
    defaultMessage: 'Successfully created patient card.',
  },
  update: {
    id: 'patient_card_update',
    defaultMessage: 'Update patient card',
  },
  updateSuccess: {
    id: 'patient_card_update_success',
    defaultMessage: 'Successfully updated patient card.',
  },
  delete: {
    id: 'patient_card_delete',
    defaultMessage: 'Delete patient card',
  },
  deleteConfirmation: {
    id: 'patient_card_delete_confirmation',
    defaultMessage: 'Are you sure you want to delete this patient card?',
  },
  deleteSuccess: {
    id: 'patient_card_delete_success',
    defaultMessage: 'Successfully deleted patient card.',
  },
});
