import { http } from 'api/client';
import { deserialize, parseDateTime } from 'api/deserialize';

import labelize from 'utils/labelize';
import sortInline from 'utils/sort';

import { StockLocationT } from './stocks';

interface FetchCenterOptionsArg {
  include?: string;
  sortKeys?: string;
}

export interface CenterT {
  id: string;
  name: string;
  campusCode: string | null;
  crrtServiceCode: string | null;
  pdServiceCode: string | null;
  rooms: RoomT[];
  shifts: ShiftT[];
}

export interface RoomT {
  id: string;
  name: string;
  color: string;
  centerId: string;
  displayMode: string;
  dimension: number;
  stockId: string;
  stock: StockLocationT | undefined;
  serviceCode: string | null;
  positions: PositionT[];
  externalIdentifier?: string;
}

export interface PositionT {
  id: string;
  used: boolean;
  order: number;
  number: number;
  name: string | null;
  deviceFullName: string | null;
  deviceId: string | null;
}

export interface ShiftT {
  id: string;
  name: string;
  type: string;
  startsAt: Date;
  endsAt: Date;
}

export async function fetchCenterOptions({ include, sortKeys = 'name asc' }: FetchCenterOptionsArg = {}) {
  const { data } = await http.get<{ centers: CenterT[] }>('centers', {
    params: { include, sortKeys },
  });

  return data.centers.map(labelize()).sort(sortInline('label'));
}

interface CenterFetchT extends Omit<CenterT, 'shifts'> {
  shifts?: ShiftT[];
}

export async function fetchCenters(discardedOnly = false, limited = false) {
  const { data } = await http.get<{ centers: CenterFetchT[] }>('centers', {
    params: {
      include: limited ? 'shifts,rooms' : 'shifts,rooms,rooms.positions,rooms.stock',
      sortKeys: 'name asc',
      scope: discardedOnly ? 'discarded' : undefined,
    },
  });

  const centers = data.centers;

  const rooms: Record<string, RoomT[]> = centers.reduce((acc, center) => {
    return {
      ...acc,
      [center.id]: center.rooms?.map((room) =>
        deserialize(room, {
          'createdAt': parseDateTime,
          'updatedAt': parseDateTime,
          'positions[].createdAt': parseDateTime,
          'positions[].updatedAt': parseDateTime,
          'stock.createdAt': parseDateTime,
          'stock.updatedAt': parseDateTime,
        })
      ),
    };
  }, {});

  const shifts: Record<string, ShiftT[]> = centers.reduce((acc, center) => {
    return {
      ...acc,
      [center.id]: center.shifts?.map((shift) =>
        deserialize(shift, {
          startsAt: parseDateTime,
          endsAt: parseDateTime,
        })
      ),
    };
  }, {});

  return {
    centers: centers.map((center) =>
      deserialize(
        { ...center, shifts: center.shifts || [] },
        {
          createdAt: parseDateTime,
          updatedAt: parseDateTime,
        }
      )
    ),
    rooms,
    shifts,
  };
}

export function selectShifts(allShifts?: Record<string, ShiftT[]>, centerId?: string | null) {
  if (!allShifts || !centerId || !allShifts[centerId]) return [];

  const shifts = allShifts[centerId];

  const shiftTypes = { morning: 0, afternoon: 1, evening: 2, night: 3 };

  return shifts.sort((a, b) => shiftTypes[a.type] - shiftTypes[b.type]);
}

export function selectShiftOptions(allShifts?: Record<string, ShiftT[]>, centerId?: string | null) {
  return selectShifts(allShifts, centerId).map(labelize());
}

export function selectRooms(allRooms?: Record<string, RoomT[]>, centerId?: string | null) {
  if (!allRooms || !centerId || !allRooms[centerId]) return [];

  const rooms = allRooms[centerId];

  return rooms.sort(sortInline('name', 'asc'));
}

export function selectRoomOptions(allRooms?: Record<string, RoomT[]>, centerId?: string | null) {
  return selectRooms(allRooms, centerId).map(labelize());
}

export function selectPositionOptions(allRooms?: RoomT[], roomId?: string | null) {
  if (!allRooms || !roomId) return [];

  const selectedRoom = allRooms.find(({ id }) => id === roomId);

  if (!selectedRoom) return [];

  const positions = selectedRoom.positions || [];

  return positions
    .filter((pos) => pos.used === true)
    .map(({ id, name, number }) => ({
      label: name ? `${String(number)} - ${name}` : String(number),
      value: id,
    })) as OptionT[];
}
