import cx from 'classnames';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

import useDelayedAnimation from 'hooks/useDelayedAnimation';

import Popover, { PopoverProps } from './Popover';

interface Props {
  content: ReactNode;
  children: ReactElement;
  title?: string;
  placement?: PopoverProps['placement'];
  actuatorClassName?: PopoverProps['actuatorClassName'];
  actuatorProps?: PopoverProps['actuatorProps'];
  floatingClassName?: PopoverProps['floatingClassname'];
  floatingContentProps?: PopoverProps['floatingContentProps'];
  floatingOffset?: PopoverProps['floatingOffset'];
  onChange?: (isOpen: boolean) => void;
  disabled?: boolean;
  openDelay?: number;
}

export type TooltipProps = Props;

const ANIMATION_DURATION = 200;

export default function Tooltip({
  content,
  children,
  title,
  placement,
  actuatorClassName,
  actuatorProps,
  floatingClassName,
  floatingContentProps,
  floatingOffset = 15,
  disabled = false,
  openDelay = 0,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const isVisible = useDelayedAnimation(isOpen, ANIMATION_DURATION);

  const showTimeoutRef = useRef<number>();

  const onMouseEnterActuator = () => {
    if (isVisible) {
      setIsOpen(true);
    } else {
      showTimeoutRef.current = window.setTimeout(() => setIsOpen(true), openDelay);
    }
  };

  const onMouseEnterFloating = () => {
    setIsOpen(true);
  };

  const onMouseLeave = () => {
    window.clearTimeout(showTimeoutRef.current);
    setIsOpen(false);
  };

  useEffect(() => {
    return () => window.clearTimeout(showTimeoutRef.current);
  }, []);

  return (
    <Popover
      isOpen={!disabled ? isVisible : false}
      floatingContentProps={
        !disabled ? { ...floatingContentProps, onMouseEnter: onMouseEnterFloating, onMouseLeave } : {}
      }
      actuatorProps={!disabled ? { ...actuatorProps, onMouseEnter: onMouseEnterActuator, onMouseLeave } : {}}
      placement={placement}
      actuatorClassName={actuatorClassName}
      floatingClassname={cx('-is-tooltip-floating', floatingClassName)}
      floatingContentClassName="tooltip"
      floatingOffset={floatingOffset}
      content={
        <>
          {title && <h6 className="tooltip__title">{title}</h6>}
          <div className="tooltip__content">{content}</div>
        </>
      }
    >
      {children}
    </Popover>
  );
}
