/* eslint-disable local/humps-imports */

import {
  camelize as _camelize,
  decamelize as _decamelize,
  decamelizeKeys as _decamelizeKeys,
  HumpsOptions,
  HumpsProcessorParameter,
} from 'humps';

import constainsNumber, { numberRegexString } from './containsNumber';
import containsUuid, { uuidRegexString } from './containsUuid';

export { camelizeKeys } from 'humps';

export function camelize(value: string) {
  return containsUuid(value) ? value : _camelize(value);
}

interface Options {
  separator?: string | undefined;
}

export function decamelize(value: string, options?: Options) {
  return processor(value, _decamelize, options);
}

export function decamelizeKeys(str: any, options?: Options) {
  return _decamelizeKeys(str, { ...options, process: processor });
}

function processor(value: string, convert: HumpsProcessorParameter, options?: HumpsOptions) {
  const decamelizeOverride = (regexCheck: (value: string) => boolean, splitRegex: RegExp) => {
    return value
      .split(splitRegex)
      .map((subValue) => {
        const trimmedSubValue = subValue.endsWith('_') || subValue.endsWith('-') ? subValue.slice(0, -1) : subValue;

        return !regexCheck(trimmedSubValue) ? convert(trimmedSubValue, options) : trimmedSubValue;
      })
      .filter((subValue) => !!subValue)
      .join(options?.separator || '_');
  };

  if (containsUuid(value)) {
    return decamelizeOverride(containsUuid, new RegExp(`(${uuidRegexString})`));
  }

  if (constainsNumber(value)) {
    return decamelizeOverride(constainsNumber, new RegExp(`(${numberRegexString})`));
  }

  return convert(value, options);
}
