import { ReactNode, Suspense } from 'react';
import { IntlProvider } from 'react-intl';

import Loading from 'components/Loading';

interface Props {
  children: ReactNode;
  locale: string;
}

export default function LocaleProvider({ locale, children }: Props) {
  return (
    <Suspense fallback={<Loading />}>
      <LocaleLoader locale={locale}>{children}</LocaleLoader>
    </Suspense>
  );
}

function LocaleLoader({ locale, children }: Props) {
  const messages = getMessages(locale);

  const onError = (error: any) => {
    if (import.meta.env.DEV && error.code !== 'MISSING_TRANSLATION') {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <IntlProvider locale={locale} messages={messages} onError={onError}>
      {children}
    </IntlProvider>
  );
}

const messagesCache: Record<string, Record<string, string>> = {};

function getMessages(locale: string) {
  const supportedLanguage = determineMessagesLanguage(locale);

  if (import.meta.env.DEV) {
    /* eslint-disable no-console */
    if (!locale.startsWith(supportedLanguage.split('_')[0])) {
      console.error(
        `You've configured an unsupported locale "${locale}".`,
        `\nFalling back to English "en" for messages.`
      );
    }
    console.info('loading messages from: src/translations/' + supportedLanguage + '.json');
    /* eslint-enable no-console */
  }

  if (messagesCache[supportedLanguage]) return messagesCache[supportedLanguage];

  // Throw a `Promise<Messages>` for React Suspense to catch;
  // `fallback` component will render until this promise resolves.
  throw loadMessages(supportedLanguage);
}

async function loadMessages(language: string) {
  const messages: Record<string, string> = await import(`../translations/${language}.json`);
  messagesCache[language] = messages;

  return messages;
}

function determineMessagesLanguage(locale: string) {
  const [language, countryCode] = locale.split('-');

  if (language === 'es' && ES_419_COUNTRY_CODES.includes(countryCode)) return 'es_419';

  if (SUPPORTED_LANGUAGES.includes(language)) return language;

  return 'en';
}

const SUPPORTED_LANGUAGES = ['de', 'en', 'es', 'es_419', 'fr', 'it', 'nl'];

const ES_SOUTH_AMERICA_CODES = [
  'AR', // Argentina 🇦🇷
  'BO', // Bolivia 🇧🇴
  'BR', // Brazil 🇧🇷
  'CL', // Chile 🇨🇱
  'CO', // Colombia 🇨🇴
  'EC', // Ecuador 🇪🇨
  'PE', // Peru 🇵🇪
  'PY', // Paraguay 🇵🇾
  'UY', // Uruguay 🇺🇾
  'VE', // Venezuela 🇻🇪
];

const ES_CENTERAL_AMERICA_CODES = [
  'BZ', // Belize 🇧🇿
  'CR', // Costa Rica 🇨🇷
  'GT', // Guatemala 🇬🇹
  'HN', // Honduras 🇭🇳
  'MX', // Mexico 🇲🇽
  'NI', // Nicaragua 🇳🇮
  'PA', // Panama 🇵🇦
  'SV', // El Salvador 🇸🇻
];

const ES_CARIBBEAN_CODES = [
  'CU', // Cuba 🇨🇺
  'DO', // Dominican Republic 🇩🇴
  'PR', // Puerto Rico 🇵🇷
];

const ES_419_COUNTRY_CODES = [
  '419', // https://en.wikipedia.org/wiki/UN_M49
  ...ES_SOUTH_AMERICA_CODES,
  ...ES_CENTERAL_AMERICA_CODES,
  ...ES_CARIBBEAN_CODES,
];
