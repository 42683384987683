import * as sentry from '@sentry/browser';
import { Component, ReactNode } from 'react';

import { ErrorPage } from 'views/components/ErrorPages';

export default class ErrorBoundary extends Component<{ children: ReactNode }, { hasError: boolean }> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      sentry.captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <ErrorPage
          onClickCallBack={() => (import.meta.env.PROD ? sentry.showReportDialog() : window.alert('Error reported!'))}
        />
      );
    }

    return children;
  }
}
