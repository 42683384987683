import { Dispatch, useEffect, useRef } from 'react';

import useIsMounted from 'hooks/useIsMounted';

import { Action } from '../state';
import { SelectInputOption } from '../types';
import { DebouncedAsyncFn } from './useDebouncedAsyncFn';

export function useFilterOptionsOnInput({
  cleanInputValue,
  debouncedOnFetchOptions,
  dispatch,
}: {
  cleanInputValue: string;
  debouncedOnFetchOptions: DebouncedAsyncFn<
    (search: string, signal: AbortSignal) => Promise<SelectInputOption[]>
  > | null;
  dispatch: Dispatch<Action>;
}) {
  const isMounted = useIsMounted();
  const lastCleanInputValueRef = useRef<string>(cleanInputValue);
  const controllerRef = useRef<AbortController>();

  useEffect(() => {
    dispatch({ type: 'STOP_FETCHING_OPTIONS' });
    controllerRef.current?.abort();
    debouncedOnFetchOptions?.cancel();

    if (cleanInputValue.length) {
      if (debouncedOnFetchOptions === null) {
        dispatch({ type: 'SET_FILTERED_OPTIONS', payload: cleanInputValue });
      } else {
        dispatch({ type: 'START_FETCHING_OPTIONS' });

        // Before doing anything in .then or .catch, check if we're still mounted AND that the input value
        // hasn't changed since, indicating that we should not update state because this resolved value is stale.
        const controller = new AbortController();
        controllerRef.current = controller;
        debouncedOnFetchOptions(cleanInputValue, controller.signal)
          .then((options = []) => {
            if (isMounted() && cleanInputValue === lastCleanInputValueRef.current) {
              dispatch({ type: 'SET_FILTERED_OPTIONS', payload: options });
              dispatch({ type: 'STOP_FETCHING_OPTIONS' });
            }
          })
          .catch(() => {
            if (isMounted() && cleanInputValue === lastCleanInputValueRef.current) {
              dispatch({ type: 'STOP_FETCHING_OPTIONS' });
            }
          });
      }
    } else {
      dispatch({ type: 'RESET_FILTERED_OPTIONS' });
    }

    lastCleanInputValueRef.current = cleanInputValue;
  }, [cleanInputValue, debouncedOnFetchOptions, dispatch, isMounted]);
}
