import cx from 'classnames';
import { Children, cloneElement, CSSProperties, forwardRef, isValidElement, MouseEvent, ReactNode } from 'react';

import Loading from 'components/Loading';
import { isTabListElement } from 'components/Tabs/TabList';

import SectionSubContent from './SectionSubContent';
import SectionSubTitle from './SectionSubTitle';
import SectionTitle, { isSectionTitleElement } from './SectionTitle';

export interface SectionProps {
  children?: ReactNode;
  footer?: ReactNode;
  className?: string;
  contentClassName?: string;
  style?: CSSProperties;
  isLoading?: boolean;
  withoutPadding?: boolean;
  hasFilter?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

const Section = forwardRef<HTMLElement, SectionProps>(function Section(props, ref) {
  const {
    children,
    footer,
    className,
    contentClassName,
    isLoading = false,
    withoutPadding = false,
    hasFilter = false,
    style,
    onClick,
    ...restProps
  } = props;
  const childArray = Children.toArray(children);

  const content = isLoading ? (
    <Loading type="static" />
  ) : (
    childArray.filter((child: any) => !(isSectionTitleElement(child) || isTabListElement(child)))
  );

  const tabTitleElements = childArray
    .filter((child: any) => isTabListElement(child))
    .map((child) => (isValidElement(child) ? cloneElement<any>(child, { isSectiontitle: true }) : child));

  const hasTabTitleElements = tabTitleElements.length > 0;

  const titleElements = childArray
    .filter((child: any) => isSectionTitleElement(child))
    .map((child) =>
      hasTabTitleElements && isValidElement(child) ? cloneElement<any>(child, { withoutBorder: true }) : child
    );

  const hasTitleElements = titleElements.length > 0;

  const sectionContentClassName = cx(contentClassName, 'section-content', {
    '-without-padding': withoutPadding,
    '-has-filter': hasFilter,
  });

  return (
    <section role="presentation" className={className} style={style} onClick={onClick} ref={ref}>
      {hasTitleElements || hasTabTitleElements ? (
        <>
          {titleElements}
          {tabTitleElements}

          <div data-testid={restProps['data-testid']} className={sectionContentClassName}>
            {content}
          </div>

          {footer && !isLoading ? <div className="section-footer">{footer}</div> : null}
        </>
      ) : (
        <div data-testid={restProps['data-testid']} className={sectionContentClassName}>
          {content}
        </div>
      )}
    </section>
  );
});

Section.displayName = 'Section';

export default Object.assign(Section, {
  Title: SectionTitle,
  SubTitle: SectionSubTitle,
  SubContent: SectionSubContent,
});
