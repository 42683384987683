import { useEffect, useState } from 'react';

import useIsMounted from 'hooks/useIsMounted';

/**
 * useDelayedAnimation
 *
 * This hook will return the same value as `show` but if show is set to `false`,
 * it will wait `animationDuration` milliseconds before setting the return value to `false` as well.
 */
export default function useDelayedAnimation(show: boolean, animationDuration: number) {
  const isMounted = useIsMounted();
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeout: number | undefined;

    const callBack = () => {
      if (isMounted()) setShouldRender(false);
    };

    if (show) {
      setShouldRender(true);
    } else {
      timeout = window.setTimeout(callBack, animationDuration);
    }

    return () => {
      if (timeout) window.clearTimeout(timeout);
    };
  }, [isMounted, show, animationDuration]);

  return shouldRender;
}
