import cx from 'classnames';
import { KeyboardEvent as ReactKeyboardEvent, useEffect, useRef, useState } from 'react';
import { Range } from 'slate';
import { ReactEditor } from 'slate-react';

import Portal from 'components/Portal';
import Title from 'components/Title';

import { Rte } from '../domain';

export default function useVariables(editor: Rte.Editor) {
  const rootRef = useRef<HTMLDivElement>(null);
  const [range, setRange] = useState<Range | undefined>();
  const [rect, setRect] = useState<DOMRect | undefined>();
  const [index, setIndex] = useState(0);
  const [partialVar, setPartialVar] = useState<string>();

  const variables = Object.entries(editor.variables).filter(([key]) =>
    partialVar ? key.startsWith(partialVar) : true
  );

  const scroll = (index: number) => {
    const entry = variables[index];

    if (entry) {
      const [key] = entry;
      const selector = `.rte-variable-option[data-key="${key}"]`;
      const li = rootRef.current?.querySelector<HTMLLIElement>(selector);

      li?.scrollIntoView({ block: 'center' });
    }
  };

  const onChange = (_contents: Rte.Node[]) => {
    editor.completeVariable();
    const [range, partialVar] = editor.isCursorInPartialVariable() || [];
    setRange(range);
    setPartialVar(partialVar);
    setIndex(0);
    scroll(0);
  };

  const onKeyDown = (event: ReactKeyboardEvent<HTMLDivElement>) => {
    if (range) {
      switch (event.key) {
        case 'ArrowUp': {
          event.preventDefault();
          const newIndex = index > 0 ? index - 1 : variables.length - 1;
          setIndex(newIndex);
          scroll(newIndex);
          break;
        }

        case 'ArrowDown': {
          event.preventDefault();
          const newIndex = index < variables.length - 1 ? index + 1 : 0;
          setIndex(newIndex);
          scroll(newIndex);
          break;
        }

        case 'Tab':
        case 'Enter': {
          event.preventDefault();
          const [key] = variables[index];
          onSelect(key);
          setIndex(0);
          break;
        }

        case 'Escape':
          event.preventDefault();
          setRange(undefined);
          setIndex(0);
          break;
      }
    }
  };

  const onSelect = (variable: string) => {
    if (range) {
      editor.insertVariable(variable, range);
    }
  };

  const renderOptionsList = () => {
    if (!range || !rect || !variables.length) {
      return null;
    }

    return (
      <Portal>
        <div
          className="rte-menu -direction-column -is-fixed"
          style={{
            opacity: 1,
            maxHeight: 200,
            transform: `translate(${rect.left}px, ${rect.bottom}px)`,
          }}
          ref={rootRef}
        >
          {variables.map(([key, value], idx) => (
            <button
              key={key}
              type="button"
              data-key={key}
              onClick={() => onSelect(key)}
              className={cx('rte-variable-option', {
                '-is-focused': index === idx,
              })}
              onMouseEnter={() => setIndex(idx)}
            >
              <Title subtext={`{{${key}}}`}>{value.label}</Title>
            </button>
          ))}
        </div>
      </Portal>
    );
  };

  useEffect(() => {
    if (range) {
      const domRange = ReactEditor.toDOMRange(editor, range);
      const rect = domRange.getBoundingClientRect();
      setRect(rect);
    } else {
      setRect(undefined);
    }
  }, [editor, range]);

  return {
    onChange,
    onKeyDown,
    renderOptionsList,
  };
}
