import cx from 'classnames';
import { CSSProperties } from 'react';

import { useAuthenticatedAsset } from 'hooks/assets';

interface Props {
  url?: string | null;
  className?: string;
  style?: CSSProperties;
}

export default function Avatar({ url, className, style }: Props) {
  const { urlObject } = useAuthenticatedAsset(url);

  return (
    <div
      className={cx(className, 'avatar')}
      style={{
        ...style,
        backgroundImage: urlObject ? `url(${urlObject})` : 'none',
      }}
    />
  );
}
