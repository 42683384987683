import { configureStore, MiddlewareArray } from '@reduxjs/toolkit';

import { createNetworkMiddleware } from 'api/storeMiddleware';

import rootReducer from './reducer';

export const store = configureStore({
  reducer: rootReducer,
  middleware: new MiddlewareArray().concat(createNetworkMiddleware<RootState>()),
  devTools: import.meta.env.DEV,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
