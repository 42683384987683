import { Link, useLocation } from 'react-router-dom';

import { useRecordEnabled, useTrackEnabled } from 'hooks/useConfig';

import Logo from 'sharedComponents/Logo';

import HeaderCurrentUser from './CurrentUser';
import PatientNavigator from './PatientNavigator';
import HeaderSignOut from './SignOut';

export default function Header() {
  const { pathname } = useLocation();

  const recordEnabled = useRecordEnabled();
  const trackEnabled = useTrackEnabled();

  return (
    <header className="header">
      <div className="header-left">
        <Link to="/" className="header-logo" title="NephroFlow">
          <Logo isWhite />
        </Link>

        {recordEnabled || trackEnabled ? <PatientNavigator key={pathname} /> : null}
      </div>

      <div className="header-right">
        <HeaderCurrentUser />
        <HeaderSignOut />
      </div>
    </header>
  );
}
