import cx from 'classnames';
import { ReactNode, useRef } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';

import { cancelActionBarActivity } from 'store/modules/activity';

import { isMessageDescriptor } from 'utils/intl';
import submitForm from 'utils/submitForm';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useKeydownListener from 'hooks/useKeydownListener';

import ActionButton from 'components/ActionButton';
import Loading from 'components/Loading';

interface Props {
  children: ReactNode;
  contentClassName?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  title: MessageDescriptor | string;
  usedInPopover?: boolean;
}

export default function ActionBar({ usedInPopover = false, ...props }: Props) {
  const content = <ActionBarContent {...props} />;

  return usedInPopover ? <div className="activity-popover-actionbar">{content}</div> : content;
}

function ActionBarContent({ children, contentClassName, isDisabled, isLoading: externalIsLoading, title }: Props) {
  const dispatch = useAppDispatch();

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<HTMLDivElement>(null);

  const isLoading = useAppSelector((state) => {
    const { network, activity } = state;

    let networkKeyCheck = activity?.name;
    if (activity?.name?.startsWith('EDIT_')) networkKeyCheck = activity?.name.replace('EDIT_', 'UPDATE_');
    if (activity?.name?.startsWith('ADD_')) networkKeyCheck = activity?.name.replace('ADD_', 'CREATE_');

    const networkKey = Object.keys(network).find((n) => n === networkKeyCheck);

    return networkKey ? network[networkKey] : false;
  });

  useKeydownListener('Escape', () => closeButtonRef.current?.focus());

  const dismiss = () => {
    dispatch(cancelActionBarActivity());
  };

  const onSubmit = () => {
    submitForm(formRef.current);
  };

  return (
    <div className="actionbar">
      <div className="actionbar__title">
        <h5 className="uppercase">{isMessageDescriptor(title) ? <FormattedMessage {...title} /> : title}</h5>
      </div>

      <div ref={formRef} className={cx('actionbar__content', contentClassName)}>
        {children}
      </div>

      <div className="actionbar__submit">
        {externalIsLoading || isLoading ? (
          <Loading type="small" />
        ) : (
          <ActionButton
            importance="primary"
            icon="check"
            title={t.submitTitle}
            disabled={isDisabled}
            onClick={onSubmit}
          />
        )}
      </div>

      <div className="actionbar__close">
        <ActionButton ref={closeButtonRef} importance="secondary" icon="close" title={t.closeTitle} onClick={dismiss} />
      </div>
    </div>
  );
}

const t = defineMessages({
  closeTitle: {
    id: 'action_bar_close_title',
    defaultMessage: 'Close',
  },
  submitTitle: {
    id: 'action_bar_submit_title',
    defaultMessage: 'Submit',
  },
});
