import { ReactNode, useState } from 'react';

import useFold from 'hooks/useFold';

import FullWidthCell from './FullWidthCell';
import { TableGroupProvider } from './GroupContext';

interface TableGroupProps {
  children: ReactNode;
  defaultClosed?: boolean;
}

export default function TableGroup({ children, defaultClosed }: TableGroupProps) {
  const [isOpen, setIsOpen] = useState(defaultClosed ? false : true);
  const [ref, style] = useFold<HTMLTableSectionElement>(isOpen);

  const context = { ref, style, isOpen, setIsOpen };

  return (
    <TableGroupProvider value={context}>
      <tr>
        <FullWidthCell className="table-cell -is-group">{children}</FullWidthCell>
      </tr>
    </TableGroupProvider>
  );
}
