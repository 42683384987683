import { ReactNode } from 'react';

import { patientSelector } from 'store/modules/entities/reducers/patients/patients';

import checkAuth, { CheckAuthOptions } from 'utils/checkAuth';

import { useAppSelector } from 'hooks/redux';

import { useCurrentUser } from 'providers';

interface Props extends Pick<CheckAuthOptions, 'rights' | 'booleanMatch' | 'partialRights'> {
  children?: ReactNode | ((shouldRender: boolean) => ReactNode);
  patientId?: string;
  patientStatus?: 'enrolled' | 'not_enrolled';
}

export default function RequireAuth({
  children,
  patientId,
  patientStatus,
  rights,
  booleanMatch = true,
  partialRights = true,
}: Props) {
  const patient = useAppSelector((state) => patientSelector(state, patientId));

  const currentUser = useCurrentUser();
  const shouldRender = checkAuth({ currentUser, patient, rights, patientStatus, booleanMatch, partialRights });

  if (typeof children === 'function') {
    return <>{children(shouldRender)}</>;
  }

  return <>{shouldRender ? children : null}</>;
}
