import cx from 'classnames';
import { CSSProperties, ReactNode } from 'react';

import injectType from 'utils/injectType';

export interface SectionTitleProps {
  children: ReactNode;
  secondaryContent?: ReactNode;
  style?: CSSProperties;
  isSticky?: boolean;
  withoutBorder?: boolean;
}

function SectionTitle({
  children,
  secondaryContent,
  style,
  isSticky = false,
  withoutBorder = false,
}: SectionTitleProps) {
  return (
    <div
      className={cx('section-title', {
        '-is-sticky': isSticky,
        '-without-border': withoutBorder,
      })}
      style={style}
    >
      <SectionTitleHeader>{children}</SectionTitleHeader>
      <div className="section-title__secondary-content">{secondaryContent}</div>
    </div>
  );
}

const [TypedSectionTitle, isSectionTitleElement] = injectType(SectionTitle, 'section.sectiontitle');

export default TypedSectionTitle;
export { isSectionTitleElement };

export function SectionTitleHeader({ children }: { children: ReactNode }) {
  return <h4 className="section-title__header">{children}</h4>;
}
