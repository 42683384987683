import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import {
  FunctionComponent,
  MutableRefObject,
  ChangeEvent as ReactChangeEvent,
  KeyboardEvent as ReactKeyboardEvent,
  ReactNode,
  RefObject,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { Modifiers } from 'react-day-picker';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import { cloneDate, isPastDay, isSameDay, shiftMonth, stringToDate, stringToTime, today } from 'utils/date';
import { isMessageDescriptor } from 'utils/intl';
import omitNullable from 'utils/omitNullable';

import { months } from 'translatedResources/Date/Months';

import useComposedRefs from 'hooks/useComposedRefs';
import usePopupMenu from 'hooks/usePopupMenu';
import useSyncedRef from 'hooks/useSyncedRef';

import ActionButton from 'components/ActionButton';
import DatePicker, { datePickerClassNames, getClassName } from 'components/DatePicker';
import Icon from 'components/Icon';
import Portal from 'components/Portal';

import { ClearIcon, DropdownIcon, Menu, MenuProps } from './shared';
import { BaseInputProps } from './types';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 120, 0, 1, 0, 0);
const toMonth = new Date(currentYear + 100, 11, 31, 23, 59);

const dateUis: UI[] = ['date', 'datetime', 'multidate'];
const timeUis: UI[] = ['time', 'datetime'];

export type UI = 'date' | 'time' | 'datetime' | 'multidate';
type Value<T extends UI> = T extends 'multidate' ? Date[] : Date;

export interface Components {
  Value?: FunctionComponent<ValueProps>;
  Placeholder?: FunctionComponent<PlaceholderProps>;
  Menu?: FunctionComponent<MenuProps>;
}

export interface DateTimeInputProps<T extends UI = 'date'> extends BaseInputProps<Value<T>> {
  ui: T;
  targetRef?: MutableRefObject<HTMLButtonElement | null>;
  placeholder?: MessageDescriptor | string;
  inputPlaceholder?: MessageDescriptor | string;
  clearable?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  disableToday?: boolean;
  menuOffset?: number;
  components?: Components;
  afterUiContent?: ReactNode;
  disableDate?: (date: Date) => boolean;
  onMonthChange?: (date: Date) => void;
}

export default function DateTimeInput<T extends UI = 'date'>({
  defaultValue,
  value,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  id,
  ui,
  targetRef,
  placeholder: externalPlaceholder,
  inputPlaceholder: externalInputPlaceholder,
  clearable = true,
  disabled = false,
  autoFocus = false,
  disablePast = false,
  disableFuture = false,
  disableToday = false,
  menuOffset = 0,
  components,
  afterUiContent,
  disableDate,
  onMonthChange,
}: DateTimeInputProps<T>) {
  const { formatMessage } = useIntl();
  const isControlled = !!externalOnChange;

  const popupId = `popup-${useId()}`;

  const valueToInternalValue = (value: Value<T> | null | undefined) => {
    const val = isControlled ? value || null : defaultValueRef.current || null;
    const valAsArray = val ? (Array.isArray(val) ? val : [val]) : [];

    return valAsArray as Date[];
  };

  const [inputValue, setInputValue] = useState('');
  const [internalValue, setInternalValue] = useState(() => valueToInternalValue(value));

  const expectedInternalValue = valueToInternalValue(value);
  if (!isEqual(internalValue, expectedInternalValue)) {
    setInternalValue(expectedInternalValue);
  }

  const defaultValueRef = useRef(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const externalOnBlurRef = useSyncedRef(externalOnBlur);

  const displayValue = useDisplayValue(internalValue, ui);

  const width = ui === 'datetime' ? 446 : dateUis.includes(ui) ? 284 : 212;
  const { setActuator, actuatorRef, setMenu, menuStyles, isOpen, isFlipped, open, close } = usePopupMenu({
    minWidth: width,
    maxWidth: width,
    menuOffset,
    syncWidth: false,
    onOpen: () => {
      window.setTimeout(() => inputRef.current?.focus());
    },
    onBlur: () => {
      window.setTimeout(() => externalOnBlurRef.current?.());
    },
  });

  const enhancedSetActuator = useComposedRefs(setActuator, targetRef);

  const onChange = (value: Date[]) => {
    setInternalValue(value);

    if (isControlled) {
      const nextValue = ui === 'multidate' ? value : value[0] || null;
      externalOnChange(nextValue as Value<T> | null);
    }
  };

  const onChooseDay = (day: Date) => {
    if (isDateDisabled(modifiers, day)) {
      return;
    }

    const firstSelected: Date | null = internalValue[0] || null;

    const date = cloneDate(day);

    if (ui === 'datetime' && firstSelected) {
      date.setHours(firstSelected.getHours(), firstSelected.getMinutes(), 0, 0);
    } else if (ui !== 'time') {
      date.setHours(12, 0, 0, 0);
    }

    if (ui === 'multidate') {
      const isSelected = internalValue.some((d) => isSameDay(d, date));
      if (isSelected) {
        onChange(internalValue.filter((v) => !isSameDay(v, date)));
      } else {
        onChange([...internalValue, date]);
      }
    } else if (ui === 'time') {
      onChange([date]);
    } else {
      if (clearable && internalValue && firstSelected?.getTime() === date?.getTime()) {
        onChange([]);
      } else {
        onChange([date]);
      }
    }
  };

  const focusTarget = () => {
    requestAnimationFrame(() => actuatorRef.current?.focus());
  };

  const modifiers = useModifiers(disablePast, disableFuture, disableToday, disableDate);

  const onInputChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onInputKeyDown = (event: ReactKeyboardEvent) => {
    switch (event.key) {
      case 'Enter': {
        const value = inputValue.trim();
        if (value !== '') {
          event.preventDefault();

          if (dateUis.includes(ui)) {
            const date = stringToDate(value);
            if (date) {
              setInputValue('');
              onChooseDay(date);
              if (ui !== 'multidate' || ui !== 'datetime') {
                close();
                focusTarget();
              }

              return;
            }
          }

          if (timeUis.includes(ui)) {
            const date = stringToTime(value);

            if (date) {
              setInputValue('');
              onChooseDay(date);
              close();
              focusTarget();

              return;
            }
          }
        }
        break;
      }

      case 'Tab':
      case 'Escape': {
        event.preventDefault();
        close();
        focusTarget();
        break;
      }
    }
  };

  const onUiKeyDown = (event: ReactKeyboardEvent) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      close();
    }
  };

  const onClearIconClick = () => {
    if (clearable) onChange([]);
  };

  const placeholder = (() => {
    if (externalPlaceholder) {
      return isMessageDescriptor(externalPlaceholder) ? formatMessage(externalPlaceholder) : externalPlaceholder;
    }
    if (ui === 'date') return formatMessage(t.selectDate);
    if (ui === 'time') return formatMessage(t.selectTime);
    if (ui === 'datetime') return formatMessage(t.selectDateTime);
    if (ui === 'multidate') return formatMessage(t.selectMultipleDates);

    return formatMessage(t.selectDate);
  })();

  const inputPlaceholder = (() => {
    if (externalInputPlaceholder) {
      return isMessageDescriptor(externalInputPlaceholder)
        ? formatMessage(externalInputPlaceholder)
        : externalInputPlaceholder;
    }
    if (dateUis.includes(ui)) return formatMessage(t.inputDatePlaceholder);
    if (timeUis.includes(ui)) return formatMessage(t.inputTimePlaceholder);
  })();

  const ValueComponent = components?.Value || Value;
  const PlaceholderComponent = components?.Placeholder || Placeholder;
  const MenuComponent = components?.Menu || Menu;

  return (
    <div className="base-input -type-datetime datetimepicker">
      <button
        ref={enhancedSetActuator}
        id={id}
        type="button"
        aria-label="Select"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-controls={popupId}
        onClick={() => (isOpen ? close() : open())}
        disabled={disabled}
        className="datetimepicker__target"
        autoFocus={autoFocus}
      >
        <div className="datetimepicker__target__content">
          {displayValue ? <ValueComponent value={displayValue} /> : <PlaceholderComponent placeholder={placeholder} />}
        </div>

        <div className="datetimepicker__target__controls">
          {internalValue.length && clearable && !disabled ? (
            <div className="datetimepicker__clear-icon" onClick={onClearIconClick} title={formatMessage(t.clear)}>
              <ClearIcon />
            </div>
          ) : null}

          <div
            className={cx('datetimepicker__dropdown-icon', { '-is-flipped': isOpen })}
            title={isOpen ? formatMessage(t.closeMenu) : formatMessage(t.openMenu)}
          >
            <DropdownIcon />
          </div>
        </div>
      </button>

      {isOpen ? (
        <Portal>
          <MenuComponent
            ref={setMenu}
            htmlProps={{
              id: popupId,
              style: menuStyles,
              tabIndex: -1,
              className: cx(
                'datetimepicker__menu',
                '-is-visible',
                `-ui-${ui}`,
                `-placement-${isFlipped ? 'top' : 'bottom'}`
              ),
            }}
            context={{ inputValue, isFetchingOptions: false }}
          >
            <div className="datetimepicker__input">
              <div className="datetimepicker__input__search-icon">
                <Icon size="small">edit</Icon>
              </div>

              <input
                ref={inputRef}
                type="text"
                className="datetimepicker__input__element"
                value={inputValue}
                placeholder={inputPlaceholder}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
              />
            </div>

            <div className="datetimepicker__ui" onKeyDown={onUiKeyDown}>
              {dateUis.includes(ui) ? (
                <DateUI
                  internalValue={internalValue}
                  ui={ui}
                  modifiers={modifiers}
                  onChooseDay={onChooseDay}
                  onMonthChange={onMonthChange}
                  close={close}
                />
              ) : null}

              {timeUis.includes(ui) ? <TimeUI internalValue={internalValue} onChange={onChange} /> : null}
            </div>

            {afterUiContent}
          </MenuComponent>
        </Portal>
      ) : null}
    </div>
  );
}

export type ValueProps = { value: string };

function Value({ value }: ValueProps) {
  return <>{value}</>;
}

export type PlaceholderProps = { placeholder: string };

function Placeholder({ placeholder }: PlaceholderProps) {
  return <span className="datetimepicker__placeholder">{placeholder}</span>;
}

function DateUI({
  internalValue,
  ui,
  modifiers,
  onChooseDay,
  onMonthChange,
  close,
}: {
  internalValue: Date[];
  ui: UI;
  modifiers: Partial<Modifiers>;
  onChooseDay: (date: Date) => void;
  onMonthChange: ((date: Date) => void) | undefined;
  close: () => void;
}) {
  const intl = useIntl();
  const firstSelected: Date | null = internalValue[0] || null;
  const [shownMonth, setShownMonth] = useState(() => firstSelected || new Date());

  const monthOptions = months(intl);
  const yearOptions = range(fromMonth.getFullYear(), toMonth.getFullYear());

  return (
    <DatePicker
      onDayClick={(date, modifiers) => {
        const isDisabled = modifiers[datePickerClassNames.disabled] === true;
        const isOutside = modifiers[datePickerClassNames.outside] === true;
        if (isDisabled || isOutside) return;

        onChooseDay(date);
        if (ui !== 'multidate' && ui !== 'datetime') close();
      }}
      month={shownMonth}
      selectedDays={internalValue}
      modifiers={modifiers}
      fixedWeeks
      captionElement={
        <DayPickerCaption
          shownMonth={shownMonth}
          setShownMonth={(date) => {
            setShownMonth(date);
            onMonthChange?.(date);
          }}
          monthOptions={monthOptions}
          yearOptions={yearOptions}
        />
      }
    />
  );
}

function TimeUI({ internalValue, onChange }: { internalValue: Date[]; onChange: (value: Date[]) => void }) {
  const { formatMessage } = useIntl();

  const firstSelected: Date | null = internalValue[0] || null;

  const hourOptions = Array.from({ length: 24 }).map((_, i) => i);
  const minuteOptions = Array.from({ length: 12 }).map((_, i) => i * 5);

  const hourRef = useRef<HTMLUListElement>(null);
  const minuteRef = useRef<HTMLUListElement>(null);

  const selectedHour = firstSelected?.getHours();
  const selectedMinute = firstSelected?.getMinutes();

  const shouldAnimateScroll = useRef(false);

  const scrollTo = (ref: RefObject<HTMLUListElement>, nthItem: number) => {
    ref.current?.scrollTo({
      top: 26 * nthItem,
      behavior: shouldAnimateScroll.current ? 'smooth' : 'auto',
    });
  };

  useEffect(() => {
    if (selectedHour !== undefined) scrollTo(hourRef, selectedHour);
  }, [selectedHour]);

  useEffect(() => {
    if (selectedMinute !== undefined) scrollTo(minuteRef, (Math.floor(selectedMinute / 5) * 5) / 5);
  }, [selectedMinute]);

  const onHourChange = (hours: number) => {
    const day = firstSelected ? cloneDate(firstSelected) : new Date();
    if (!firstSelected) day.setHours(0, 0, 0, 0);

    day.setHours(hours);

    shouldAnimateScroll.current = true;
    onChange([day]);
  };

  const onMinuteChange = (minutes: number) => {
    const day = firstSelected ? cloneDate(firstSelected) : new Date();
    if (!firstSelected) day.setHours(0, 0, 0, 0);

    day.setMinutes(minutes);

    shouldAnimateScroll.current = true;

    onChange([day]);
  };

  const onCurrentClick = () => {
    const now = new Date();
    const day = firstSelected ? new Date(firstSelected) : new Date();

    day.setHours(now.getHours(), now.getMinutes(), 0, 0);

    onChange([day]);
  };

  return (
    <div className="timepicker">
      <div className="timepicker__caption">
        <ActionButton onClick={onCurrentClick} icon="timer" title={t.currentTime} />
      </div>

      <div className="timepicker__header">
        <div className="timepicker__header__label">
          <abbr title={formatMessage(t.hours)}>
            <FormattedMessage {...t.hh} />
          </abbr>
        </div>
        <div className="timepicker__header__label">
          <abbr title={formatMessage(t.minutes)}>
            <FormattedMessage {...t.mm} />
          </abbr>
        </div>
      </div>

      <div className="timepicker__columns">
        <ul className="timepicker__list" ref={hourRef}>
          {hourOptions.map((hour) => (
            <li
              key={hour}
              value={hour}
              className={cx('timepicker__list-item', {
                '-is-selected': selectedHour === hour,
              })}
              onClick={() => onHourChange(hour)}
              data-value={hour}
            >
              {String(hour).padStart(2, '0')}
            </li>
          ))}
          <li className="timepicker__list-item" />
        </ul>

        <ul className="timepicker__list" ref={minuteRef}>
          {minuteOptions.map((minute) => (
            <li
              key={minute}
              value={minute}
              className={cx('timepicker__list-item', {
                '-is-selected': selectedMinute === minute,
              })}
              onClick={() => onMinuteChange(minute)}
              data-value={minute}
            >
              {String(minute).padStart(2, '0')}
            </li>
          ))}
          <li className="timepicker__list-item" />
        </ul>
      </div>
    </div>
  );
}

interface DayPickerCaptionProps {
  shownMonth: Date;
  setShownMonth: (date: Date) => void;
  monthOptions: string[];
  yearOptions: number[];
}

function DayPickerCaption({ shownMonth, setShownMonth, monthOptions, yearOptions }: DayPickerCaptionProps) {
  const onMonthChange = (event: ReactChangeEvent<HTMLSelectElement>) => {
    const month = parseInt(event.target.value, 10);
    const newMonth = new Date(shownMonth);
    newMonth.setDate(1);
    newMonth.setMonth(month);
    setShownMonth(newMonth);
  };

  const onYearChange = (event: ReactChangeEvent<HTMLSelectElement>) => {
    const year = parseInt(event.target.value, 10);
    const newYear = new Date(shownMonth);
    newYear.setDate(1);
    newYear.setFullYear(year);
    setShownMonth(newYear);
  };

  const onClickPrev = () => {
    setShownMonth(shiftMonth(shownMonth, -1));
  };

  const onCurrentClick = () => {
    setShownMonth(new Date());
  };

  const onClickNext = () => {
    setShownMonth(shiftMonth(shownMonth, 1));
  };

  return (
    <div className={getClassName('caption')}>
      <div className={getClassName('caption__inner')}>
        <select className={getClassName('caption__select')} onChange={onMonthChange} value={shownMonth.getMonth()}>
          {monthOptions.map((month, index) => (
            <option key={month} value={index}>
              {month}
            </option>
          ))}
        </select>

        <select className={getClassName('caption__select')} onChange={onYearChange} value={shownMonth.getFullYear()}>
          {yearOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>

        <div className={getClassName('caption__spacer')} />

        <div className={getClassName('caption__actions')}>
          <ActionButton icon="chevron_left" title={t.previousMonth} onClick={onClickPrev} />
          <ActionButton icon="event" title={t.currentMonth} onClick={onCurrentClick} />
          <ActionButton icon="chevron_right" title={t.nextMonth} onClick={onClickNext} />
        </div>
      </div>
    </div>
  );
}

function useModifiers(
  disablePast: boolean,
  disableFuture: boolean,
  disableToday: boolean,
  disableDate: ((day: Date) => boolean) | undefined
): Partial<Modifiers> {
  const disabled: ((day: Date) => boolean)[] = [];

  if (disablePast) {
    disabled.push((day) => isPastDay(day));
  }

  if (disableFuture) {
    disabled.push((day) => !isSameDay(today(), day) && !isPastDay(day));
  }

  if (disableToday) {
    disabled.push((day) => isSameDay(today(), day));
  }

  if (disableDate) {
    disabled.push(disableDate);
  }

  return {
    [datePickerClassNames.disabled]: (day: Date) => disabled.some((fn) => fn(day)),
  };
}

export function useDisplayValue(value: Date[], ui: UI) {
  const { formatDate, formatTime } = useIntl();

  return value
    .map((date) =>
      omitNullable([
        dateUis.includes(ui) ? formatDate(date) : null,
        timeUis.includes(ui) ? formatTime(date) : null,
      ]).join(' ')
    )
    .join(', ');
}

function isDateDisabled(modifiers: Partial<Modifiers>, day: Date) {
  const disabledModifier = modifiers[datePickerClassNames.disabled];

  if (disabledModifier && typeof disabledModifier === 'function') {
    return disabledModifier(day);
  }

  return false;
}

const t = defineMessages({
  selectDate: {
    id: 'datetime_input_select_date',
    defaultMessage: 'Select date...',
  },
  selectTime: {
    id: 'datetime_input_select_time',
    defaultMessage: 'Select time...',
  },
  selectDateTime: {
    id: 'datetime_input_select_date_time',
    defaultMessage: 'Select date and time...',
  },
  selectMultipleDates: {
    id: 'datetime_input_select_multiple_dates',
    defaultMessage: 'Select date(s)...',
  },
  previousMonth: {
    id: 'datetime_input_previous_month',
    defaultMessage: 'Previous month',
  },
  currentMonth: {
    id: 'datetime_input_current_month',
    defaultMessage: 'Current month',
  },
  nextMonth: {
    id: 'datetime_input_next_month',
    defaultMessage: 'Next month',
  },
  hh: {
    id: 'datetime_input_hh',
    defaultMessage: 'HH',
  },
  mm: {
    id: 'datetime_input_mm',
    defaultMessage: 'MM',
  },
  hours: {
    id: 'datetime_input_hours',
    defaultMessage: 'Hours',
  },
  minutes: {
    id: 'datetime_input_minutes',
    defaultMessage: 'Minutes',
  },
  currentTime: {
    id: 'datetime_input_current_time',
    defaultMessage: 'Current time',
  },
  placeholder: {
    id: 'datetime_input_placeholder',
    defaultMessage: 'Select...',
  },
  clear: {
    id: 'datetime_input_clear',
    defaultMessage: 'Clear',
  },
  closeMenu: {
    id: 'datetime_input_close_menu',
    defaultMessage: 'Close menu',
  },
  openMenu: {
    id: 'datetime_input_open_menu',
    defaultMessage: 'Open menu',
  },
  inputDatePlaceholder: {
    id: 'datetime_input_input_date_placeholder',
    defaultMessage: 'Type to select a date.',
  },
  inputTimePlaceholder: {
    id: 'datetime_input_input_time_placeholder',
    defaultMessage: 'Type to select a time.',
  },
});
