import { FunctionComponent, ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';

import Waiting from 'components/Waiting';

import { CLASSNAME } from '../constants';
import { t } from '../translations';
import { Text } from './Text';

export interface LoadingOptionsProps {
  text: MessageDescriptor | string | undefined;
  children?: ReactNode;
}

export const LoadingOptions: FunctionComponent<LoadingOptionsProps> = ({ text, children }) => {
  return (
    <div className={`${CLASSNAME}__option-message -is-loading-options`}>
      {children || (
        <span>
          <Text text={text || t.loadingOptions} />
          <Waiting />
        </span>
      )}
    </div>
  );
};
