import { FunctionComponent, ReactNode } from 'react';

import { SelectInputOption, Value } from '../types';
import { MultiValueProps } from './MultiValue';

export interface MultiValueContainerProps {
  value: Value | null;
  options: SelectInputOption[];
  Component: FunctionComponent<MultiValueProps>;
  toggleOption: (option: SelectInputOption) => void;
  children?: ReactNode;
}

export const MultiValueContainer: FunctionComponent<MultiValueContainerProps> = ({
  value,
  options,
  Component,
  toggleOption,
  children,
}) => {
  return (
    <>
      {children ||
        (!value || !Array.isArray(value)
          ? null
          : options
              .filter((option) => value.includes(option.value))
              .map((option) => (
                <Component key={option.value} option={option} deselectOption={() => toggleOption(option)} />
              )))}
    </>
  );
};
