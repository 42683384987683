import cx from 'classnames';
import { CSSProperties, ReactNode } from 'react';

import injectType from 'utils/injectType';

import NavBar from 'components/NavBar';

export interface TabListProps {
  children: ReactNode;
  isSectionTitle?: boolean;
  style?: CSSProperties;
}

function TabList({ children, isSectionTitle = false, style }: TabListProps) {
  return (
    <NavBar.List className={cx('tablist', { '-is-section-title': isSectionTitle })} style={style}>
      {children}
    </NavBar.List>
  );
}

const [TypedTabList, isTabListElement] = injectType(TabList, 'tabs.tablist');

export default TypedTabList;
export { isTabListElement };
