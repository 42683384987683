import cx from 'classnames';
import { ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

function Page({ children }: { children?: ReactNode }) {
  return <main className="page">{children}</main>;
}

function PageHeader({ children }: { children?: ReactNode }) {
  return <header className="page__header">{children}</header>;
}

function PageHeaderDataList({ children }: { children?: ReactNode }) {
  return <dl className="page__header__datalist">{children}</dl>;
}

function PageHeaderDataListItem({
  label,
  noWrap = false,
  children,
}: {
  label: string | MessageDescriptor;
  noWrap?: boolean;
  children: ReactNode;
}) {
  return (
    <div className={cx('page__header__datalist__item', { '-no-wrap': noWrap })}>
      <dt>{isMessageDescriptor(label) ? <FormattedMessage {...label} /> : label}</dt>
      <dd>{children}</dd>
    </div>
  );
}

function PageContent({
  noScroll = false,
  withoutPadding = false,
  className,
  children,
}: {
  noScroll?: boolean;
  withoutPadding?: boolean;
  className?: string;
  children?: ReactNode;
}) {
  return (
    <div className={cx('page__content', { '-no-scroll': noScroll, '-without-padding': withoutPadding }, className)}>
      {children}
    </div>
  );
}

export default Object.assign(Page, {
  Header: Object.assign(PageHeader, {
    DataList: Object.assign(PageHeaderDataList, {
      Item: PageHeaderDataListItem,
    }),
  }),
  Content: PageContent,
});
