import cx from 'classnames';

interface Props {
  isSmall?: boolean;
}

export default function Waiting({ isSmall = false }: Props) {
  return (
    <div className={cx('waiting', { '-is-small': isSmall })}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </div>
  );
}
