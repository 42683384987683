import { ChangeEvent as ReactChangeEvent, ReactNode, WheelEvent as ReactWheelEvent } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import { Affix } from './shared';
import { BaseInputProps } from './types';

type BaseProps = BaseInputProps<number>;

type CustomProps = {
  prefix?: ReactNode;
  suffix?: MessageDescriptor | ReactNode;
  autoComplete?: boolean;
  placeholder?: MessageDescriptor;
};

type HtmlProps = Omit<JSX.IntrinsicElements['input'], keyof BaseProps | keyof CustomProps | 'name'>;

export type IntegerInputProps = BaseProps & CustomProps & HtmlProps;

export default function IntegerInput({
  defaultValue: externalDefaultValue,
  value: externalValue,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  id,
  disabled = false,
  autoFocus = false,
  autoComplete = false,
  placeholder: rawPlaceholder,
  step = 1,
  prefix,
  suffix,
}: IntegerInputProps) {
  const { formatMessage } = useIntl();

  const isControlled = !!externalOnChange;

  const defaultValue = (() => {
    if (isControlled) return undefined;
    if (!externalDefaultValue) return '';

    return String(Math.round(externalDefaultValue));
  })();

  const value = (() => {
    if (!isControlled) return undefined;
    if (externalValue === null || externalValue === undefined) return '';

    return String(Math.round(externalValue));
  })();

  const onChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    const int = parseInt(event.target.value, 10);
    externalOnChange?.(Number.isNaN(int) ? null : int);
  };

  const onBlur = () => {
    externalOnBlur?.();
  };

  const formattedPlaceholder = isMessageDescriptor(rawPlaceholder) ? formatMessage(rawPlaceholder) : rawPlaceholder;

  return (
    <div className="base-input -type-integer">
      {prefix ? <Affix prefix>{prefix}</Affix> : null}
      <input
        id={id}
        type="number"
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        autoFocus={autoFocus}
        autoComplete={autoComplete ? 'on' : 'off'}
        placeholder={rawPlaceholder ? formattedPlaceholder : undefined}
        step={step}
        onWheel={onWheel}
      />
      {suffix ? <Affix suffix>{isMessageDescriptor(suffix) ? formatMessage(suffix) : suffix}</Affix> : null}
    </div>
  );
}

function onWheel(event: ReactWheelEvent<HTMLInputElement>) {
  event.currentTarget.blur();
}
