import { useIntl } from 'react-intl';
import { useSlate } from 'slate-react';

import Title from 'components/Title';

import t from '../translations';
import Popout from './Popout';

export default function VariableRow() {
  const { formatMessage } = useIntl();
  const editor = useSlate();

  const variables = Object.entries(editor.variables);

  if (!variables.length) return null;

  return (
    <Popout
      isActive={false}
      buttonIcon="functions"
      buttonTitle={formatMessage(t.variables)}
      direction="column"
      maxSize={200}
      buttonIsDisabled={!editor.isCursorInTextNode()}
    >
      {(setIsOpen) =>
        variables.map(([key, value]) => (
          <button
            key={key}
            type="button"
            className="rte-variable-option"
            onClick={() => {
              editor.insertVariable(key);
              setIsOpen(false);
            }}
          >
            <Title subtext={`{{${key}}}`}>{value.label}</Title>
          </button>
        ))
      }
    </Popout>
  );
}
