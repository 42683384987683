import cx from 'classnames';

interface Props {
  isWhite?: boolean;
  className?: string;
}

export default function Logo({ isWhite = false, className }: Props) {
  const classNames = cx('logo', className, { '-is-white': isWhite });

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" className={classNames}>
      <path
        className="logo__left"
        d="M16,12Q30.8,25.7,46.3,41Q53.2,48,48.5,51.7Q34,60.3,19,53.5Q4,47,1.5,30Q-1,9,9,8.5Q12.2,8.3,16,12"
      />
      <path
        className="logo__shadow"
        clipPath="url(#shadow)"
        d="M16,12Q30.8,25.7,46.3,41Q53.2,48,48.5,51.7Q34,60.3,19,53.5Q4,47,1.5,30Q-1,9,9,8.5Q12.2,8.3,16,12"
      />
      <path
        className="logo__right"
        d="M48,12Q33.2,25.7,17.7,41Q10.8,48,15.5,51.7Q30,60.3,45,53.5Q60,47,62.5,30Q65,9,55,8.5Q51.8,8.3,48,12"
      />
      <defs>
        <clipPath id="shadow">
          <path d="M64,5L0,45L0,64L64,64L64,5" />
        </clipPath>
      </defs>
    </svg>
  );
}
