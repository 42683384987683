import { createContext, ReactNode, useContext } from 'react';

import assert from 'utils/assert';

import { FormT } from 'components/Form/schema';

const ConfigContext = createContext<FormT | undefined>(undefined);

export function useConfig() {
  const config = useContext(ConfigContext);

  assert(config, 'The hook `useConfig` must be used within a `ConfigProvider`.');

  return config;
}

interface ConfigProviderProps {
  value: FormT;
  children: ReactNode;
}

export function ConfigProvider({ value, children }: ConfigProviderProps) {
  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
}
