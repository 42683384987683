import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { store } from 'store';

import {
  AuthProvider,
  CapabilitiesProvider,
  CurrentUserProvider,
  DatePickerLocaleUtilsProvider,
  DefaultQueryClientProvider,
  ErrorBoundary,
  LocaleProvider,
  RouteWatcher,
  ToastProvider,
  useAuth,
} from 'providers';

import { locale } from 'locale';

import AuthenticatedApp from './components/Authenticated';
import UnauthenticatedApp from './components/Unauthenticated';

export default function Root() {
  useEffect(() => {
    if (document.documentElement) {
      document.documentElement.lang = locale;
    }
  }, []);

  return (
    <StoreProvider store={store}>
      <DefaultQueryClientProvider>
        <LocaleProvider locale={locale}>
          <DatePickerLocaleUtilsProvider>
            <ErrorBoundary>
              <Router>
                <RouteWatcher />

                <AuthProvider>
                  <App />
                </AuthProvider>

                <ToastProvider />
              </Router>
            </ErrorBoundary>
          </DatePickerLocaleUtilsProvider>
        </LocaleProvider>

        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </DefaultQueryClientProvider>
    </StoreProvider>
  );
}

function App() {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <CurrentUserProvider>
      <CapabilitiesProvider mode="authenticated">
        <AuthenticatedApp />
      </CapabilitiesProvider>
    </CurrentUserProvider>
  ) : (
    <CapabilitiesProvider mode="unauthenticated">
      <UnauthenticatedApp />
    </CapabilitiesProvider>
  );
}
