import { init } from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import { version } from '../../package.json';

export function initSentry() {
  if (import.meta.env.PROD && import.meta.env.REACT_APP_SENTRY_DSN) {
    init({
      dsn: import.meta.env.REACT_APP_SENTRY_DSN,
      release: version,
      environment: 'production',
      integrations: [new BrowserTracing()],
      tracesSampleRate: parseFloat(import.meta.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE ?? '0.0'),
    });
  }
}
