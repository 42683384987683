import { QueryClient, useQuery } from '@tanstack/react-query';
import { ReactElement } from 'react';

import { http } from 'api/client';
import { deserialize, parseDateTime } from 'api/deserialize';

import assert from 'utils/assert';
import { MINUTE_IN_MILLIS } from 'utils/date';

import Loading from 'components/Loading';

const QUERY_KEY = ['currentUser'];

export default function CurrentUserProvider({ children }: { children: ReactElement }) {
  // Fetch on AuthenticatedApp mount
  // Refetch on window focus after 5 minutes of stale data
  const { isLoading, data: user } = useQuery(QUERY_KEY, () => fetchCurrentUser(), {
    refetchOnWindowFocus: true,
    staleTime: 5 * MINUTE_IN_MILLIS,
    onError: () => {},
  });

  if (isLoading || !user) {
    return <Loading />;
  }

  return children;
}

export function useCurrentUser(refetch = false) {
  // Refetch on hook mount after 30 minutes of stale data
  const { data: user } = useQuery(QUERY_KEY, () => fetchCurrentUser(), {
    staleTime: refetch ? 0 : 30 * MINUTE_IN_MILLIS,
  });

  assert(user, 'The hook `useCurrentUser` must be used within an `CurrentUserProvider`.');

  return user;
}

export function refetchCurrentUser(queryClient: QueryClient) {
  queryClient.invalidateQueries(QUERY_KEY);
}

async function fetchCurrentUser() {
  const { data } = await http.get<{ user: UserT }>('current_user');

  return deserialize(data.user, {
    'updatedAt': parseDateTime,
    'createdAt': parseDateTime,
    'identifiers[].updatedAt': parseDateTime,
  });
}
