import cx from 'classnames';
import { CSSProperties, HTMLProps } from 'react';

interface CustomGridProps {
  repeat?: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  columns?: CSSProperties['gridTemplateColumns'];
  rows?: CSSProperties['gridTemplateRows'];
  areas?: CSSProperties['gridTemplateAreas'];
  withoutMargin?: boolean;
  stretchContent?: boolean;
}

type GridProps = CustomGridProps & Omit<HTMLProps<HTMLDivElement>, keyof CustomGridProps>;

function Grid({
  className,
  repeat,
  columns,
  rows,
  areas,
  withoutMargin,
  stretchContent = false,
  style,
  ...rest
}: GridProps) {
  return (
    <div
      {...rest}
      className={cx('grid', className, {
        '-without-margin': withoutMargin,
        '-stretch-content': stretchContent,
        '-repeat': repeat,
        '-columns': columns,
        '-rows': rows,
        '-areas': areas,
      })}
      style={{
        '--repeat': repeat,
        '--repeat-print': repeat ? Math.floor(repeat / 2) : undefined,
        '--columns': columns,
        '--rows': rows,
        '--areas': areas,
        ...style,
      }}
    />
  );
}

interface CustomItemProps {
  span?: number;
  area?: string;
}

type ItemProps = CustomItemProps & Omit<HTMLProps<HTMLDivElement>, keyof CustomItemProps>;

function Item({ className, span, area, style, ...rest }: ItemProps) {
  const getStyle = () => {
    const result: CSSProperties = { ...style };

    // Only set properties when we have a value for them.
    // This differs from setting the properties to undefined.
    // React will process these and come up with something
    // completely wrong.
    // Eg: style = { gridArea: area, gridColumnEnd: span ? `span ${span}` : undefined }
    // will not work correctly.
    if (area !== undefined) result.gridArea = area;
    if (span !== undefined) result.gridColumnEnd = `span ${span}`;

    return result;
  };

  return <div {...rest} className={cx('grid__item', className)} style={getStyle()} />;
}

export default Object.assign(Grid, { Item });
