import { fromJS, Map } from 'immutable';
import { Action } from 'redux';

import { combineReducers } from 'store/utils';

import codeSystems from './codeSystems';
import conditions from './conditions';
import patientConditions from './conditions/patient';
import entityCodes from './entityCodes';
import exportCenter from './exportCenter';
import invalidPrescriptionsOverview from './medication/invalidPrescriptionsOverview';
import ambulatoryMedicationSchedule from './medication/schedule';
import medicinalTherapyPlans from './medication/therapyPlans';
import medicationImport from './medicationImport';
import medicationManagement from './medicationManagement';
import patients from './patients';

export type EntitiesState = Map<any, any>;

interface EntitiesAction extends Action<string> {
  payload?: any;
}

export const initialState: EntitiesState = fromJS({
  ambulatoryMedicationSchedule: {},
  intradialyticPrescribableMedicinalProductItems: { byId: {} },
  codes: { byId: {}, byCodeSystem: {} },
  conditions: { byId: {}, byResult: [] },
  entityCodes: { byId: {}, byEntity: {} },
  codeSystems: {},
  deactivations: { byId: {}, byPatientId: {} },
  exportCenter: { exportables: { byId: {}, byResult: [] }, billingItems: {}, patients: {} },
  invalidPrescriptionsOverview: { byId: {}, ambulatory: [], intradialytic: [] },
  labResults: {
    results: { byId: {}, byTherapyPlanPatientId: {}, byTherapyPlanId: {} },
    observationTypes: { byId: {}, byTherapyPlanPatientId: {} },
  },
  medicinalAdministrationRoutes: { byId: {}, byProductId: {} },
  medicationValidations: {},
  medicinalTherapyPlans: { byId: {}, byPatientId: {} },
  medicinalTherapyPlanCalendars: {},
  medicinalTherapyPlanCalendarDates: {},
  medicinalTherapyPlanCalendarDateEntries: {},
  medicinalIngredients: {},
  medicinalConsumptionSets: { byId: {}, byProductId: {} },
  medicinalConsumptionSetItems: { byId: {} },
  medicinalProductParentConsumptionSetItems: { byId: {} },
  medicinalImportItems: { importExecution: {}, searchExecution: {}, items: {} },
  medicinalProductIngredients: {},
  medicinalPackages: {},
  medicinalPrices: {},
  medicinalProductItems: { byId: {}, byResult: [] },
  medicinalProductProviders: {},
  patients: {},
  patientIdentifiers: {},
  patientInsurances: { byId: {}, byPatientId: {} },
  patientConditions: { byId: {}, byPatientId: {} },
  prescribableUnits: { byId: {}, byProductId: {} },
  unitAliases: {},
});

export default function reducer(state: EntitiesState = initialState, action: EntitiesAction): EntitiesState {
  const entitiesReducer = combineReducers(
    ambulatoryMedicationSchedule,
    codeSystems,
    conditions,
    entityCodes,
    exportCenter,
    invalidPrescriptionsOverview,
    medicationImport,
    medicationManagement,
    medicinalTherapyPlans,
    patientConditions,
    patients
  );

  return entitiesReducer(state, action);
}
