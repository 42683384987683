import { defineMessages } from 'react-intl';

export const t = defineMessages({
  placeholder: {
    id: 'nf_select_placeholder',
    defaultMessage: 'Select...',
  },
  loadingOptions: {
    id: 'nf_select_loading_options',
    defaultMessage: 'Loading options',
  },
  noOptions: {
    id: 'nf_select_no_options',
    defaultMessage: 'No options',
  },
  clear: {
    id: 'nf_select_clear',
    defaultMessage: 'Clear',
  },
  closeMenu: {
    id: 'nf_select_close_menu',
    defaultMessage: 'Close menu',
  },
  openMenu: {
    id: 'nf_select_open_menu',
    defaultMessage: 'Open menu',
  },
  searchPlaceholder: {
    id: 'nf_select_search_placeholder',
    defaultMessage: 'Search...',
  },
  createOption: {
    id: 'nf_create_option',
    defaultMessage: 'Create "{text}"',
  },
});
