import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { resetError as resetErrorAction } from 'store/modules/errors';

import { useAppDispatch, useAppSelector } from 'hooks/redux';

import Modal from 'components/Modal';

const t = defineMessages({
  errorTitle: {
    id: 'error_modal_title',
    defaultMessage: 'An error has occurred',
  },
  refreshMessage: {
    id: 'error_modal_refresh',
    defaultMessage: 'Please refresh the app',
  },
  unknownError: {
    id: 'error_modal_unknown_error',
    defaultMessage: 'An unknown error',
  },
  forbiddenTitle: {
    id: 'error_modal_forbidden_title',
    defaultMessage: 'Insufficient rights',
  },
  forbiddenDetail: {
    id: 'error_modal_forbidden_detail',
    defaultMessage: 'You do not have the required rights to use this feature.',
  },
  notFoundTitle: {
    id: 'error_modal_not_found_title',
    defaultMessage: 'Not found',
  },
  notFoundDetail: {
    id: 'error_modal_not_found_detail',
    defaultMessage: 'The requested resource was not found.',
  },
  methodNotAllowedTitle: {
    id: 'error_modal_method_not_allowed_title',
    defaultMessage: 'Not allowed',
  },
  methodNotAllowedDetail: {
    id: 'error_modal_method_not_allowed_detail',
    defaultMessage: 'This operation is not allowed!',
  },
  payloadTooLargeTitle: {
    id: 'error_modal_payload_too_large_title',
    defaultMessage: 'Payload too large',
  },
  payloadTooLargeDetail: {
    id: 'error_modal_payload_too_large_detail',
    defaultMessage: 'The passed attachments or files are too big!',
  },
});

export default function ErrorModal() {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const resetError = () => dispatch(resetErrorAction());

  const errors = useAppSelector((state) => state.errors);

  const forbidden = errors?.some((error) => error.handlerType === 'forbidden') || false;

  const notFound = errors?.some((error) => error.handlerType === 'not_found') || false;

  const methodNotAllowed = errors?.some((error) => error.handlerType === 'method_not_allowed') || false;

  const payloadTooLarge = errors?.some((error) => error.handlerType === 'payload_too_large') || false;

  const allUnhandled = errors.length ? errors.every((err) => err.handlerType === 'unhandled') : false;

  const showModal = forbidden || notFound || methodNotAllowed || payloadTooLarge || allUnhandled;

  return (
    <Modal show={showModal} onClose={resetError}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...t.errorTitle} />
        </Modal.Title>

        <Modal.HeaderActions>
          <Modal.CloseButton onClick={resetError} />
        </Modal.HeaderActions>
      </Modal.Header>

      <Modal.Body>
        {!(forbidden || notFound || methodNotAllowed || payloadTooLarge) && (
          <>
            <a href={window.location.origin} title={formatMessage(t.refreshMessage)}>
              <h6>
                <FormattedMessage {...t.refreshMessage} />
              </h6>
            </a>
            <hr style={{ margin: '15px 0' }} />
          </>
        )}

        {errors?.map((error: ErrorT, index: number) => (
          <Error
            key={error.failedAction || index}
            error={error}
            forbidden={forbidden}
            notFound={notFound}
            methodNotAllowed={methodNotAllowed}
            payloadTooLarge={payloadTooLarge}
          />
        )) || null}
      </Modal.Body>
    </Modal>
  );
}

type Props = {
  error: ErrorT;
  forbidden: boolean;
  notFound: boolean;
  methodNotAllowed: boolean;
  payloadTooLarge: boolean;
};

function Error({ error, forbidden, notFound, methodNotAllowed, payloadTooLarge }: Props) {
  const { formatMessage } = useIntl();

  const { detail, title } = error;

  if (title && detail && title !== detail) {
    return <FormatError title={title} detail={detail} />;
  }

  if (forbidden) {
    return <FormatError title={formatMessage(t.forbiddenTitle)} detail={formatMessage(t.forbiddenDetail)} />;
  }

  if (notFound) {
    return <FormatError title={formatMessage(t.notFoundTitle)} detail={formatMessage(t.notFoundDetail)} />;
  }

  if (methodNotAllowed) {
    return (
      <FormatError title={formatMessage(t.methodNotAllowedTitle)} detail={formatMessage(t.methodNotAllowedDetail)} />
    );
  }

  if (payloadTooLarge) {
    return (
      <FormatError title={formatMessage(t.payloadTooLargeTitle)} detail={formatMessage(t.payloadTooLargeDetail)} />
    );
  }

  return <p>{title || detail || formatMessage(t.unknownError)}</p>;
}

function FormatError({ title, detail }: { title: string; detail: string }) {
  return (
    <p>
      <strong>{title}</strong>
      <br />
      {detail}
    </p>
  );
}
