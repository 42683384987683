import { startActivity } from 'store/modules/activity';
import { registerFormErrorAction, registerUuidFormErrorAction } from 'store/modules/errors';

export const EDIT_PROFILE_DATA = 'EDIT_PROFILE_DATA';
export const EDIT_PROFILE_PASSWORD = 'EDIT_PROFILE_PASSWORD';

export const UPDATE_CURRENT_USER_FAILURE = registerUuidFormErrorAction('UPDATE_CURRENT_USER_FAILURE');
export const UPDATE_CURRENT_USER_PASSWORD_FAILURE = registerFormErrorAction('UPDATE_CURRENT_USER_PASSWORD_FAILURE');

export const startEditProfileDataActivity = () => startActivity(EDIT_PROFILE_DATA);

export const startEditProfilePasswordActivity = () => startActivity(EDIT_PROFILE_PASSWORD);
