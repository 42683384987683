import cx from 'classnames';
import { ReactNode } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import FormError from './FormError';

interface BaseFormFieldProps {
  id?: string;
  label?: MessageDescriptor | string;
  error?: string;
  subtext?: string;
  readOnly?: boolean;
  optional?: boolean;
  children: ReactNode;
  className?: string;
}

export default function BaseFormField({
  id,
  label,
  error,
  subtext,
  optional = false,
  readOnly = false,
  children,
  className,
}: BaseFormFieldProps) {
  return (
    <div className={cx('formv3__field', className)}>
      {label ? (
        <label className="formv3__field__label" htmlFor={id}>
          <div className="formv3__field__label__inner">
            <span>{isMessageDescriptor(label) ? <FormattedMessage {...label} /> : label}</span>

            <LabelSubtext subtext={subtext} readOnly={readOnly} optional={optional} />
          </div>
        </label>
      ) : null}

      <div className="formv3__field__input">{children}</div>

      {error ? <FormError error={error} /> : null}
    </div>
  );
}

function LabelSubtext({ subtext, readOnly, optional }: { subtext?: string; readOnly: boolean; optional: boolean }) {
  if (subtext) {
    return <span className="formv3__field__label__subtext">{subtext}</span>;
  }

  if (readOnly) {
    return (
      <span className="formv3__field__label__subtext">
        <FormattedMessage {...t.readOnly} />
      </span>
    );
  }

  if (optional) {
    return (
      <span className="formv3__field__label__subtext">
        <FormattedMessage {...t.optional} />
      </span>
    );
  }

  return null;
}

const t = defineMessages({
  optional: {
    id: 'form_field_optional',
    defaultMessage: 'Optional',
  },
  readOnly: {
    id: 'form_field_readonly',
    defaultMessage: 'Read-only',
  },
});
