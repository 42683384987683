import { networkActionTypes } from 'store/utils';

export const UPDATE_PATIENT_TRANSPLANT_ELIGIBILITY_STATUS = networkActionTypes(
  'UPDATE_PATIENT_TRANSPLANT_ELIGIBILITY_STATUS'
);

export type UpdateTransplantStatusArgsT = {
  patientId: string;
  status: string;
  note?: string;
};

export const updateTransplantStatus = ({ patientId, status, note }: UpdateTransplantStatusArgsT): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_PATIENT_TRANSPLANT_ELIGIBILITY_STATUS,
    url: `patients/${patientId}/transplant_eligibility`,
    method: 'PUT',
    payload: {
      patientId,
      status,
      note: note || null,
    },
    normalizeSchema: {},
  },
});
