import cx from 'classnames';
import { CSSProperties, forwardRef, ReactNode, useEffect, useRef, useState } from 'react';

import Icon from 'components/Icon';

import Stackable from './Stackable';

function NavBar({
  className,
  innerClassName,
  children,
}: {
  className?: string;
  innerClassName?: string;
  children: ReactNode;
}) {
  const [prevEnabled, setPrevEnabled] = useState(false);
  const [nextEnabled, setNextEnabled] = useState(false);
  const navRef = useRef<HTMLUListElement>(null);

  const onPrev = () => {
    const nav = navRef.current;

    if (nav) {
      nav.scrollTo({
        left: nav.scrollLeft - nav.clientWidth * 0.8,
        behavior: 'smooth',
      });
    }
  };

  const onNext = () => {
    const nav = navRef.current;

    if (nav) {
      nav.scrollTo({
        left: nav.scrollLeft + nav.clientWidth * 0.8,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const nav = navRef.current;

    const handleScroll = () => {
      if (nav) {
        setPrevEnabled(nav.scrollLeft > 0);
        setNextEnabled(nav.scrollLeft < nav.scrollWidth - nav.clientWidth);
      }
    };

    if (nav) {
      handleScroll();
      nav.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
    }

    return () => {
      if (nav) {
        nav.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      }
    };
  }, []);

  return (
    <Stackable className={cx('navbar', className)}>
      {prevEnabled && (
        <button onClick={onPrev} className="navbar__arrow -is-left">
          <Icon>keyboard_arrow_left</Icon>
        </button>
      )}

      <NavBarList ref={navRef} className={innerClassName}>
        {children}
      </NavBarList>

      {nextEnabled && (
        <button onClick={onNext} className="navbar__arrow -is-right">
          <Icon>keyboard_arrow_right</Icon>
        </button>
      )}
    </Stackable>
  );
}

const NavBarList = forwardRef<HTMLUListElement, { className?: string; style?: CSSProperties; children: ReactNode }>(
  function NavBarList({ className, style, children }, forwardedRef) {
    return (
      <ul ref={forwardedRef} className={cx('navbar__list', className)} style={style}>
        {children}
      </ul>
    );
  }
);

export default Object.assign(NavBar, {
  List: NavBarList,
});
