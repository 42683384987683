import { defineMessages } from 'react-intl';

export default defineMessages({
  allCenterLabel: {
    id: 'user_form_all_center_label',
    defaultMessage: 'All centers',
  },
  identifierPlaceholder: {
    id: 'user_form_identifiers_placeholder',
    defaultMessage: 'Enter value...',
  },
  profileSection: {
    id: 'user_form_profile_section',
    defaultMessage: 'Profile',
  },
  avatarLabel: {
    id: 'user_form_avatar_label',
    defaultMessage: 'Avatar',
  },
  titleLabel: {
    id: 'user_form_title_label',
    defaultMessage: 'Title',
  },
  titlePlaceholder: {
    id: 'user_form_title_placeholder',
    defaultMessage: 'Select title...',
  },
  firstNameLabel: {
    id: 'user_form_first_name_label',
    defaultMessage: 'First Name',
  },
  firstNamePlaceholder: {
    id: 'user_form_first_name_placeholder',
    defaultMessage: 'Enter first name...',
  },
  lastNameLabel: {
    id: 'user_form_last_name_label',
    defaultMessage: 'Last Name',
  },
  rolesLabel: {
    id: 'user_form_roles_label',
    defaultMessage: 'Role',
  },
  professionLabel: {
    id: 'user_form_profession_label',
    defaultMessage: 'Profession',
  },
  lastNamePlaceholder: {
    id: 'user_form_last_name_placeholder',
    defaultMessage: 'Enter last name...',
  },
  rolesPlaceholder: {
    id: 'user_form_roles_placeholder',
    defaultMessage: 'Select role...',
  },
  professionPlaceholder: {
    id: 'user_form_profession_placheholder',
    defaultMessage: 'Select profession...',
  },
  multicenterSection: {
    id: 'user_form_multicenter_section',
    defaultMessage: 'Multicenter access rights',
  },
  userPermission: {
    id: 'user_form_access_type',
    defaultMessage: 'Access type',
  },
  assignedCenters: {
    id: 'user_form_assigned_centers',
    defaultMessage: 'Assigned center(s)',
  },
  assignedCentersPlaceholder: {
    id: 'user_form_assigned_centers_placeholder',
    defaultMessage: 'Select assigned center(s)...',
  },
  identifiersSection: {
    id: 'user_form_identifiers_section',
    defaultMessage: 'Identifiers',
  },
  accountSection: {
    id: 'user_form_account_section',
    defaultMessage: 'Account',
  },
  emailLabel: {
    id: 'user_form_email_label',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'user_form_email_placeholder',
    defaultMessage: 'Enter email...',
  },
  usernameLabel: {
    id: 'user_form_username_label',
    defaultMessage: 'Username',
  },
  usernamePlaceholder: {
    id: 'user_form_username_placeholder',
    defaultMessage: 'Enter username...',
  },
  oldPasswordLabel: {
    id: 'user_form_old_password_label',
    defaultMessage: 'Old password',
  },
  oldPasswordPlaceholder: {
    id: 'user_form_old_password_placeholder',
    defaultMessage: 'Enter your old password...',
  },
  passwordLabel: {
    id: 'user_form_password_label',
    defaultMessage: 'New password',
  },
  passwordPlaceholder: {
    id: 'user_form_password_placeholder',
    defaultMessage: 'Enter new password...',
  },
  passwordConfirmationLabel: {
    id: 'user_form_password_confirmation_label',
    defaultMessage: 'Repeat password',
  },
  passwordConfirmationPlaceholder: {
    id: 'user_form_password_confirmation_placeholder',
    defaultMessage: 'Repeat the new password...',
  },
  passwordInfo: {
    id: 'user_form_password_info',
    defaultMessage: 'Please do not use your username in the password',
  },
});
