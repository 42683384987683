import { defineMessages } from 'react-intl';

export default defineMessages({
  clear: {
    id: 'filters_clear',
    defaultMessage: 'Clear',
  },
  close: {
    id: 'filters_close',
    defaultMessage: 'Close',
  },
  allFilters: {
    id: 'filters_all_filters',
    defaultMessage: 'All filters',
  },
  resetAll: {
    id: 'filters_reset_all',
    defaultMessage: 'Reset all',
  },
});
