import { SelectInputOption } from './types';
import { filterOptions } from './utils';

export function createReducer() {
  return function reducer(state: State, action: Action): State {
    switch (action.type) {
      case 'OPEN_MENU': {
        return { ...state, isMenuOpen: true };
      }

      case 'CLOSE_MENU': {
        return { ...state, isMenuOpen: false };
      }

      case 'SET_INPUT': {
        return { ...state, inputValue: action.payload };
      }

      case 'CLEAR_INPUT': {
        return { ...state, inputValue: '' };
      }

      case 'SET_OPTIONS': {
        return { ...state, options: action.payload };
      }

      case 'START_FETCHING_OPTIONS': {
        return { ...state, isFetchingOptions: true };
      }

      case 'STOP_FETCHING_OPTIONS': {
        return { ...state, isFetchingOptions: false };
      }

      case 'START_CREATING_OPTION': {
        return { ...state, isCreatingOption: true };
      }

      case 'STOP_CREATING_OPTION': {
        return { ...state, isCreatingOption: false };
      }

      case 'RESET_FILTERED_OPTIONS': {
        return { ...state, filteredOptions: null };
      }

      case 'SET_FILTERED_OPTIONS': {
        return {
          ...state,
          filteredOptions: Array.isArray(action.payload)
            ? action.payload
            : filterOptions(selectAllOptions(state), action.payload),
        };
      }

      case 'ADD_OPTION': {
        return { ...state, createdOptions: [...state.createdOptions, action.payload] };
      }

      default: {
        return state;
      }
    }
  };
}

export function createInitialState({ options }: { options: SelectInputOption[] }): State {
  return {
    options,
    filteredOptions: null,
    createdOptions: [],
    inputValue: '',
    isMenuOpen: false,
    isFetchingOptions: false,
    isCreatingOption: false,
  };
}

export function selectAllOptions(state: State) {
  return [...state.options, ...state.createdOptions];
}

export function selectVisibleOptions(state: State) {
  return state.filteredOptions ? state.filteredOptions : selectAllOptions(state);
}

export interface State {
  options: SelectInputOption[];
  filteredOptions: SelectInputOption[] | null;
  createdOptions: SelectInputOption[];
  inputValue: string;
  isMenuOpen: boolean;
  isFetchingOptions: boolean;
  isCreatingOption: boolean;
}

export type Action =
  | OpenMenuAction
  | CloseMenuAction
  | SetInputAction
  | ClearInputAction
  | SetOptionsAction
  | StartFetchingOptionsAction
  | StopFetchingOptionsAction
  | StartCreatingOptionAction
  | StopCreatingOptionAction
  | ResetFilteredOptionsAction
  | SetFilteredOptionsAction
  | AddOptionAction;

interface OpenMenuAction {
  type: 'OPEN_MENU';
}

interface CloseMenuAction {
  type: 'CLOSE_MENU';
}

interface SetInputAction {
  type: 'SET_INPUT';
  payload: string;
}

interface ClearInputAction {
  type: 'CLEAR_INPUT';
}

interface SetOptionsAction {
  type: 'SET_OPTIONS';
  payload: SelectInputOption[];
}

interface StartFetchingOptionsAction {
  type: 'START_FETCHING_OPTIONS';
}

interface StopFetchingOptionsAction {
  type: 'STOP_FETCHING_OPTIONS';
}

interface StartCreatingOptionAction {
  type: 'START_CREATING_OPTION';
}

interface StopCreatingOptionAction {
  type: 'STOP_CREATING_OPTION';
}

interface ResetFilteredOptionsAction {
  type: 'RESET_FILTERED_OPTIONS';
}

interface SetFilteredOptionsAction {
  type: 'SET_FILTERED_OPTIONS';
  payload: SelectInputOption[] | string;
}

interface AddOptionAction {
  type: 'ADD_OPTION';
  payload: SelectInputOption;
}
