import { defineMessages } from 'react-intl';

import Button, { Props } from 'components/Button';

export default function CancelButton({ children, ...buttonProps }: Props) {
  return <Button {...buttonProps}>{children || t.cancel}</Button>;
}

const t = defineMessages({
  cancel: {
    id: 'modal_cancel_button',
    defaultMessage: 'Cancel',
  },
});
