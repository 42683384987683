import { forwardRef } from 'react';

import Button, { Props as ButtonProps } from './Button';

// Make the provided keys required
type RequireSome<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

// Disallow 'children'
// Require 'icon', 'title'
type Props = RequireSome<Omit<ButtonProps, 'children'>, 'icon' | 'title'>;

const ActionButton = forwardRef<HTMLButtonElement, Props>(function ActionButton(props, ref) {
  return (
    <Button size="compact" importance="tertiary" {...props} ref={ref}>
      {null}
    </Button>
  );
});

ActionButton.displayName = 'ActionButton';

export default ActionButton;
export type { Props };
