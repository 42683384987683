interface Accessor<T> {
  label?: keyof T;
  value?: keyof T;
}

export default function labelize<T extends Record<string, any>>(accessor?: Accessor<T>) {
  return (item: T): OptionT => ({
    label: item[accessor?.label || 'name'],
    value: item[accessor?.value || 'id'],
  });
}
