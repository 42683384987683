import { LocalStorage } from 'utils/storage';

import { UserToken } from './types';

export function retrieveToken(): UserToken {
  return LocalStorage.get<UserToken>('user_token') || {};
}

export function persistToken(token: UserToken, merge = false) {
  const data = merge ? { ...retrieveToken(), ...token } : token;
  LocalStorage.set<UserToken>('user_token', data);
}

export function forgetToken() {
  LocalStorage.remove('user_token');
}
