import cx from 'classnames';
import { forwardRef } from 'react';

const Stackable = forwardRef<HTMLDivElement, Omit<JSX.IntrinsicElements['div'], 'ref'>>(function Stackable(
  { className, ...props },
  forwardedRef
) {
  return <div ref={forwardedRef} className={cx('stackable', className)} {...props} />;
});

Stackable.displayName = 'Stackable';

export default Stackable;
