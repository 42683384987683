import { schema } from 'normalizr';

import { parseDateTime, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

export const entityCodeSchema = new schema.Entity(
  'codes',
  {},
  {
    processStrategy: schemaDeserializers({
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    }),
  }
);

export const GET_ENTITY_CODES = networkActionTypes('GET_ENTITY_CODES');

type GetEntityCodesArgsT = {
  codableType: string;
  codableId: string;
};

export const getEntityCodes = ({ codableType, codableId }: GetEntityCodesArgsT): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: GET_ENTITY_CODES,
    url: `codables/${codableType}/${codableId}/codes`,
    method: 'GET',
    actionPayload: {
      codable: { type: codableType, id: codableId },
    },
    normalizeSchema: { codes: [entityCodeSchema] },
  },
});

export const ATTACH_ENTITY_CODE = networkActionTypes('ATTACH_ENTITY_CODE');

type AttachEntityCodeArgsT = {
  codableType: string;
  codableId: string;
  codeSystemSlug: string;
  code: string;
  description?: string;
};

export const attachEntityCode = ({
  codableType,
  codableId,
  codeSystemSlug,
  code,
  description,
}: AttachEntityCodeArgsT): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: ATTACH_ENTITY_CODE,
    url: `codables/${codableType}/${codableId}/codes`,
    method: 'POST',
    payload: {
      slug: codeSystemSlug,
      code: {
        code,
        description,
      },
    },
    actionPayload: {
      codable: { id: codableId, type: codableType },
    },
    normalizeSchema: { code: entityCodeSchema },
  },
});

export const DETACH_ENTITY_CODE = networkActionTypes('DETACH_ENTITY_CODE');

type DetachEntityCodeArgsT = {
  codableType: string;
  codableId: string;
  codeId: string;
};

export const detachEntityCode = ({ codableType, codableId, codeId }: DetachEntityCodeArgsT) => ({
  type: 'CALL_API',
  payload: {
    types: DETACH_ENTITY_CODE,
    url: `codables/${codableType}/${codableId}/${codeId}`,
    method: 'DELETE',
    actionPayload: {
      codableType,
      codableId,
      codeId,
    },
  },
});
