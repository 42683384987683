import { defineMessages, IntlShape } from 'react-intl';

const t = defineMessages({
  january: {
    id: 'date_month_january',
    defaultMessage: 'January',
  },
  february: {
    id: 'date_month_february',
    defaultMessage: 'February',
  },
  march: {
    id: 'date_month_march',
    defaultMessage: 'March',
  },
  april: {
    id: 'date_month_april',
    defaultMessage: 'April',
  },
  may: {
    id: 'date_month_may',
    defaultMessage: 'May',
  },
  june: {
    id: 'date_month_june',
    defaultMessage: 'June',
  },
  july: {
    id: 'date_month_july',
    defaultMessage: 'July',
  },
  august: {
    id: 'date_month_august',
    defaultMessage: 'August',
  },
  september: {
    id: 'date_month_september',
    defaultMessage: 'September',
  },
  october: {
    id: 'date_month_october',
    defaultMessage: 'October',
  },
  november: {
    id: 'date_month_november',
    defaultMessage: 'November',
  },
  december: {
    id: 'date_month_december',
    defaultMessage: 'December',
  },
});

export const months = (intl: IntlShape) => Object.keys(t).map<string>((month) => intl.formatMessage(t[month]));

export const monthOptions = (intl: IntlShape) => {
  const orderedMonths = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  return orderedMonths.map<OptionT>((month, index) => ({
    label: intl.formatMessage(t[month]),
    value: String(index),
  }));
};
