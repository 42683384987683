import { components, DropdownIndicatorProps } from 'react-select';

import DropdownIcon from '../DropdownIcon';

export default function DropdownIndicator(props: DropdownIndicatorProps<any>) {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon />
    </components.DropdownIndicator>
  );
}
