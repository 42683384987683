import { useRef } from 'react';
import { defineMessages } from 'react-intl';

import useKeydownListener from 'hooks/useKeydownListener';

import ActionButton from 'components/ActionButton';

export default function CloseButton({ onClick }: { onClick: () => void }) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useKeydownListener('Escape', () => buttonRef.current?.focus());

  return <ActionButton ref={buttonRef} importance="secondary" title={t.close} icon="close" onClick={onClick} />;
}

const t = defineMessages({
  close: {
    id: 'modal_close_title',
    defaultMessage: 'Close',
  },
});
