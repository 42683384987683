import { useQuery } from '@tanstack/react-query';

import { http } from 'api/client';

import { MINUTE_IN_MILLIS } from 'utils/date';

import { useRecordEnabled } from 'hooks/useConfig';

export interface IssuesT {
  administrationValidations: number;
  invalidPrescriptions?: number;
  prescriptionValidations?: number;
  unsignedSessions: number;
  lettersToValidate?: number;
  appointmentConflicts?: number;
}

export async function fetchSessionDashboardIssues() {
  const { data } = await http.get<{ dashboardIssues: IssuesT }>('session_dashboard_issues');

  return data.dashboardIssues;
}

export function useSessionDashboardIssues({ refetch }: { refetch: boolean }) {
  const recordEnabled = useRecordEnabled();

  const { data: issues, isLoading: isLoadingIssues } = useQuery(
    ['sessions', 'dashboard/issues'],
    () => fetchSessionDashboardIssues(),
    {
      refetchInterval: refetch ? MINUTE_IN_MILLIS : false,
      enabled: recordEnabled,
    }
  );

  return { issues, isLoadingIssues };
}
