import useConstant from 'hooks/useConstant';
import useSyncedRef from 'hooks/useSyncedRef';

type AsyncFn = (...args: any[]) => Promise<any>;
type DebouncedAsyncFn<T extends AsyncFn> = {
  (...args: Parameters<T>): ReturnType<T>;
  cancel: () => void;
};

function useDebouncedAsyncFn<T extends AsyncFn>(
  asyncFn: T | null | undefined,
  wait: number
): DebouncedAsyncFn<T> | null {
  const asyncFnRef = useSyncedRef(asyncFn);

  const debounced = useConstant<DebouncedAsyncFn<T>>(() => {
    let timeout: number | undefined;

    function cancel() {
      window.clearTimeout(timeout);
    }

    function debounced(...args: Parameters<T>) {
      cancel();

      return new Promise<Awaited<ReturnType<T>>>((resolve, reject) => {
        timeout = window.setTimeout(() => {
          asyncFnRef
            .current?.(...args)
            .then(resolve)
            .catch(reject);
        }, wait);
      });
    }

    debounced.cancel = cancel;

    return debounced as any;
  });

  return asyncFn ? debounced : null;
}

export type { DebouncedAsyncFn };
export { useDebouncedAsyncFn };
