import { http } from 'api/client';

export async function requestAccountUnlock({ email }: { email: string }) {
  const payload = { user: { username: email } };

  await http.post('users/unlock', payload);
}

export async function unlockAccount({ token }: { token: string }) {
  const payload = { user: { unlockToken: token } };

  await http.put('users/unlock', payload);
}

export async function requestPasswordReset({ email }: { email: string }) {
  const payload = { user: { username: email } };

  await http.post('users/password', payload);
}

export async function resetPassword({
  token,
  password,
  passwordConfirmation,
}: {
  token: string;
  password: string;
  passwordConfirmation: string;
}) {
  const payload = {
    user: {
      resetPasswordToken: token,
      password,
      passwordConfirmation,
    },
  };

  await http.put('users/password', payload);
}
