import { RightClaim } from 'rights';

export interface CheckAuthOptions {
  currentUser?: UserT | null;
  patient?: PatientT | null;
  rights?: RightClaim[];
  patientStatus?: 'enrolled' | 'not_enrolled';
  booleanMatch?: boolean;
  partialRights?: boolean;
}

export default function checkAuth({
  currentUser,
  patient,
  rights,
  patientStatus,
  booleanMatch = true,
  partialRights = true,
}: CheckAuthOptions) {
  if (!booleanMatch) return false;

  if (!patient && patientStatus) return false;

  if (Array.isArray(rights)) {
    if (!currentUser) return false;

    const userRights = currentUser.rights.map((right) => right.name);
    const userHasRight = (right: RightClaim) => userRights.includes(right);

    const rightsMatch = partialRights ? rights.some(userHasRight) : rights.every(userHasRight);

    if (!rightsMatch) return false;
  }

  const patientStatusMatch = patientStatus ? patient?.enrollmentStatus === patientStatus : true;

  if (!patientStatusMatch) return false;

  return true;
}
