import cx from 'classnames';
import { ChangeEvent as ReactChangeEvent, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import Icon from 'components/Icon';

import { BaseInputProps } from './types';

export interface CheckboxOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface CheckboxGroupInputProps<O extends CheckboxOption = CheckboxOption>
  extends BaseInputProps<O['value'][], O['value'][]> {
  options: O[];
  orientation?: 'row' | 'column';
  disableToggleAll?: boolean;
}

export default function CheckboxGroupInput<O extends CheckboxOption = CheckboxOption>({
  defaultValue,
  value,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  disabled = false,
  autoFocus = false,
  orientation = 'row',
  disableToggleAll = false,
  options,
}: CheckboxGroupInputProps<O>) {
  const isControlled = !!externalOnChange;

  const { formatMessage } = useIntl();

  const [selected, setSelected] = useState<O['value'][]>(() => {
    const val = isControlled ? value : defaultValue;

    return val || [];
  });

  const shouldAutoFocus = useRef(!disabled && autoFocus);

  const nonExistingOptions = selected
    .filter((value) => !options.find((option) => option.value === value))
    .map((value) => ({ label: value, value } as O));

  const allOptions = [...options, ...nonExistingOptions];
  const allOptionsDisabled = !allOptions.every((option) => option.disabled);
  const allSelected = allOptions.length === selected.length;

  useEffect(() => {
    if (isControlled) {
      setSelected(value || []);
    }
  }, [isControlled, value]);

  const onChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    const val = allOptions
      .filter(({ value }) => (value === input.value ? input.checked : selected.includes(value)))
      .map(({ value }) => value);

    setSelected(val);
    externalOnChange?.(val);
  };

  const onBlur = () => {
    externalOnBlur?.();
  };

  const toggleAll = () => {
    const selectedDisabled = selected.filter((value) => options.find((option) => option.value === value)?.disabled);

    const val = allSelected
      ? selectedDisabled
      : allOptions
          .filter((option) => !option.disabled)
          .map(({ value }) => value)
          .concat(selectedDisabled);

    setSelected(val);
    externalOnChange?.(val);
  };

  const renderCheckbox = (option: O, index: number) => (
    <label key={option.value} className="checkboxgroup__item">
      <input
        className={cx('checkboxgroup__item__input', {
          '-is-checked': selected.includes(option.value),
          '-is-disabled': disabled || option.disabled,
        })}
        type="checkbox"
        name={option.value}
        id={option.value}
        value={option.value}
        checked={selected.includes(option.value)}
        disabled={disabled || option.disabled}
        autoFocus={index === 0 && shouldAutoFocus.current}
        onChange={onChange}
        onBlur={onBlur}
      />
      <Icon className="checkboxgroup__item__button">
        {selected.includes(option.value) ? 'check_box' : 'check_box_outline_blank'}
      </Icon>
      <span className="checkboxgroup__item__label">{option.label}</span>
    </label>
  );

  return (
    <div className="base-input -type-checkboxgroup">
      <div className={`checkboxgroup -orientation-${orientation}`}>
        {options.map(renderCheckbox)}
        {nonExistingOptions.map((option, index) => renderCheckbox(option, options.length + index))}
        {!disableToggleAll && options.length > 0 && allOptionsDisabled ? (
          <button type="button" className="checkboxgroup__toggle-all" onClick={toggleAll}>
            {formatMessage(allSelected ? t.deselectAll : t.selectAll)}
          </button>
        ) : null}
      </div>
    </div>
  );
}

const t = defineMessages({
  selectAll: {
    id: 'checkboxgroup_input_select_all',
    defaultMessage: 'Select all',
  },
  deselectAll: {
    id: 'checkboxgroup_input_deselect_all',
    defaultMessage: 'Deselect all',
  },
});
