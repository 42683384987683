import cx from 'classnames';
import { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import useDelayedAnimation from 'hooks/useDelayedAnimation';
import { useFocusFirst, useRestoreFocus, useTrapFocus } from 'hooks/useFocus';

import Portal from 'components/Portal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  width?: number;
  duration?: number;
  className?: string;
  backdropClassName?: string;
  sheetClassName?: string;
  trapFocus?: boolean;
  focusFirst?: boolean;
  children: ReactNode | ((isVisible: boolean) => ReactNode);
}

export default function SideSheet({
  isOpen,
  onClose,
  width = 512,
  duration = 200,
  className,
  backdropClassName,
  sheetClassName,
  trapFocus = true,
  focusFirst = true,
  children,
}: Props) {
  const rootRef = useRef<HTMLDivElement>(null);
  const sheetRef = useRef<HTMLDivElement>(null);
  const shouldRender = useDelayedAnimation(isOpen, duration);

  useFocusFirst(sheetRef, focusFirst && isOpen);
  useTrapFocus(sheetRef, trapFocus && isOpen);
  useRestoreFocus(isOpen);

  return (
    <Portal>
      <CSSTransition nodeRef={rootRef} classNames="-trans" in={isOpen} timeout={duration}>
        {isOpen || shouldRender ? (
          <div
            ref={rootRef}
            className={cx('sidesheet', className)}
            style={{
              '--sheet-width': `${width}px`,
              '--sheet-transition-duration': `${duration}ms`,
            }}
          >
            <div className={cx('sidesheet__backdrop', backdropClassName)} onClick={onClose} />
            <div className={cx('sidesheet__sheet', sheetClassName)} ref={sheetRef}>
              {typeof children === 'function' ? children(isOpen || shouldRender) : children}
            </div>
          </div>
        ) : (
          <></>
        )}
      </CSSTransition>
    </Portal>
  );
}
