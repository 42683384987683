import { http } from 'api/client';
import { deserialize, parseDateTime } from 'api/deserialize';

import sortInline from 'utils/sort';

import { IdentifierTypeAssignmentT } from './identifiers';

export interface PersonTypeT {
  id: string;
  name: string;
  key: string;
  isProfession: boolean;
  createdAt: Date;
  updatedAt: Date;
  identifierTypeAssignments: IdentifierTypeAssignmentT[];
}

export async function fetchPersonTypes() {
  const { data } = await http.get<{ personTypes: PersonTypeT[] }>('person_types');

  return data.personTypes
    .map((type) =>
      deserialize(type, {
        'createdAt': parseDateTime,
        'updatedAt': parseDateTime,
        'identifierTypeAssignments[].createdAt': parseDateTime,
        'identifierTypeAssignments[].updatedAt': parseDateTime,
      })
    )
    .sort(sortInline(['isProfession', 'name']));
}
