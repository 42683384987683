import { MutableRefObject, useEffect } from 'react';

import { CLASSNAME } from '../constants';
import { State } from '../state';

export function useSetMenuVisibility({
  state,
  setIsVisible,
  listBoxRef,
}: {
  state: State;
  setIsVisible: (isVisible: boolean) => void;
  listBoxRef: MutableRefObject<HTMLDivElement | null>;
}) {
  useEffect(() => {
    const scrollToFirstSelectedOption = () => {
      const listBox = listBoxRef.current;
      if (!listBox) return;

      const firstSelectedOption = listBox.querySelector<HTMLDivElement>(`.${CLASSNAME}__option.-is-selected`);
      if (firstSelectedOption) {
        const optionAbove = firstSelectedOption.previousElementSibling;
        const extraOffset = optionAbove ? optionAbove.getBoundingClientRect().height / 2 - 2 : 0;

        listBox.scrollTop = firstSelectedOption.offsetTop - extraOffset;
      }
    };

    if (state.isMenuOpen) {
      requestAnimationFrame(() => {
        scrollToFirstSelectedOption();
        setIsVisible(true);
      });
    } else {
      setIsVisible(false);
    }
  }, [state.isMenuOpen, setIsVisible, listBoxRef]);
}
