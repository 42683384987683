import { useField, useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import toPath from 'lodash/toPath';
import { Component, ComponentType, ReactNode, useCallback, useEffect, useId, useRef } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import useSyncedRef from 'hooks/useSyncedRef';

import { BaseInputProps } from 'components/Inputs/types';

import BaseFormField from './components/BaseFormField';
import { isDescriptionObject, validate, Validation } from './validation';

type FormFieldProps<P extends BaseInputProps<any>> = Omit<P, 'id'> & {
  name: string;
  label: MessageDescriptor | string | false;
  validation?: Validation;
  Input: ComponentType<P & { name: string }>;
  neverOptional?: boolean;
  fieldClassName?: string;
};

export default function FormField<P extends BaseInputProps<any>>(props: FormFieldProps<P>) {
  const { formatMessage } = useIntl();

  const {
    label: rawLabel,
    validation,
    neverOptional = false,
    fieldClassName,
    onChange: externalOnChange,
    onBlur: externalOnBlur,
    ...inputProps
  } = props;
  const uniqueId = `${props.name}-${useId()}`;

  const { value, error, onChange, onBlur } = useEnhancedField(props.name, validation);
  const isRequired = useIsRequired(validation);

  const combinedOnChange = useCallback(
    (value: unknown) => {
      onChange(value);
      externalOnChange?.(value);
    },
    [onChange, externalOnChange]
  );

  const combinedOnBlur = useCallback(() => {
    onBlur();
    externalOnBlur?.();
  }, [onBlur, externalOnBlur]);

  const label = isMessageDescriptor(rawLabel) ? formatMessage(rawLabel) : rawLabel;

  return (
    <BaseFormField
      id={uniqueId}
      label={label || undefined}
      optional={!isRequired && !neverOptional}
      error={error}
      className={fieldClassName}
    >
      <FieldInput value={value} onChange={combinedOnChange} onBlur={combinedOnBlur} {...inputProps} id={uniqueId} />
    </BaseFormField>
  );
}

type FieldInputProps = BaseInputProps<unknown> & { Input: ComponentType<any>; children?: ReactNode };

/**
 * Use a class component here so we can use `shouldComponentUpdate`.
 * There we check if the current props and the next props are equal.
 * If so, we do not re-render the component.
 *
 * This makes sure we do not render all fields again and again whenever the
 * value of one field in the form changes.
 */
class FieldInput extends Component<FieldInputProps> {
  shouldComponentUpdate(nextProps: FieldInputProps) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const { Input, children, ...props } = this.props;

    return <Input {...props} />;
  }
}

function useEnhancedField(name: string, validation: Validation | undefined) {
  const validate = useValidateCallback(validation);

  const { setFieldValue, setFieldTouched, setFieldError, submitCount, errors } = useFormikContext();

  const [field, meta] = useField({ name, validate });
  const { value } = field;

  const nameRef = useSyncedRef(name);
  const valueRef = useSyncedRef(value);
  const onChangeRef = useSyncedRef(field.onChange);
  const onBlurRef = useSyncedRef(field.onBlur);

  const valueBeforeCleanupRef = useRef(undefined);

  useEffect(() => {
    if (valueBeforeCleanupRef.current !== undefined) {
      setFieldValue(nameRef.current, valueBeforeCleanupRef.current, false);
    }

    return () => {
      /**
       * Disable eslint rule here saying that "The ref value will likely have
       * changed by the time this effect cleanup function runs".
       * This is what we want (we need the latest value) and not a "cached"
       * value we create at the top of the effect.
       */

      /* eslint-disable react-hooks/exhaustive-deps */
      valueBeforeCleanupRef.current = valueRef.current;
      const name = nameRef.current;
      /* eslint-enable react-hooks/exhaustive-deps */

      setFieldValue(name, undefined, false);
      setFieldTouched(name, false, false);
      setFieldError(name, undefined);
    };
  }, [nameRef, valueRef, setFieldValue, setFieldTouched, setFieldError]);

  const error = getIn(errors, name);
  const showError = meta.touched || submitCount > 0;
  const hasError = error !== undefined && typeof error === 'string';

  return {
    value,
    error: showError && hasError ? (error as string) : undefined,
    onChange: useCallback(
      (value: unknown) => {
        onChangeRef.current({ target: { name, value } });
      },
      [name, onChangeRef]
    ),
    onBlur: useCallback(() => {
      onBlurRef.current({ target: { name } });
    }, [name, onBlurRef]),
  };
}

function useIsRequired(validation: Validation | undefined) {
  const requiredArg = validation?.required;

  if (requiredArg === undefined) {
    return false;
  }

  return isDescriptionObject(requiredArg) ? requiredArg.value === true : requiredArg === true;
}

function useValidateCallback(validation: Validation | undefined) {
  const intl = useIntl();
  const validationRef = useSyncedRef(validation);

  return useCallback((value: unknown) => validate(validationRef.current, intl, value), [intl, validationRef]);
}

/**
 * Copied from https://github.com/formium/formik/blob/master/packages/formik/src/utils.ts#L69
 *
 * Do not use `meta.error` but instead call `getIn(errors, fieldName)` which is
 * what `meta.error` comes from. We do however need a slightly different `getIn`
 * that does not try to read indexes from a string.
 * This prevents us from using the first character of an error for eg. tabular
 * as the error for the first item in that field array.
 *
 * The line `if (typeof obj...` is the modification. The rest is unchanged.
 *
 * Example:
 *     formik:    getIn({ path: 'value' }, 'path[0]') => "v"
 *     manager:   getIn({ path: 'value' }, 'path[0]') => undefined
 */
export function getIn(obj: any, key: string | string[], def?: any, p = 0) {
  const path = toPath(key);
  while (obj && p < path.length) {
    obj = obj[path[p++]];
    if (typeof obj === 'string' && p < path.length - 1) return def;
  }

  return obj === undefined ? def : obj;
}
