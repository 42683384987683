import cx from 'classnames';
import { CSSProperties, ReactNode } from 'react';

export interface TitleProps {
  subtext?: ReactNode;
  children: ReactNode;
  style?: CSSProperties;
  title?: string;
  isBold?: boolean;
  dimmedSubtext?: boolean;
  withGap?: boolean;
}

export default function Title({
  subtext,
  children,
  style,
  title,
  isBold = true,
  dimmedSubtext = true,
  withGap = false,
}: TitleProps) {
  return (
    <div
      className={cx('title', {
        '-is-bold': isBold,
        '-with-gap': withGap,
      })}
      style={style}
      title={title}
    >
      <span className="title__children">{children}</span>
      {subtext && (
        <span
          className={cx('title__subtext', {
            '-is-dimmed': dimmedSubtext,
          })}
        >
          {subtext}
        </span>
      )}
    </div>
  );
}
