import { Dispatch, useEffect, useRef } from 'react';

import { Action } from '../state';
import { Value } from '../types';

export function useClearInputOnMultipleChange({
  multiple,
  setValue,
  updateMenu,
  dispatch,
}: {
  multiple: boolean;
  setValue: (value: Value | null) => void;
  updateMenu: () => void;
  dispatch: Dispatch<Action>;
}) {
  const lastMultipleRef = useRef(multiple);

  useEffect(() => {
    if (lastMultipleRef.current !== multiple) {
      lastMultipleRef.current = multiple;

      setValue(null);
      dispatch({ type: 'CLEAR_INPUT' });
      dispatch({ type: 'RESET_FILTERED_OPTIONS' });
      updateMenu();
    }
  }, [setValue, multiple, updateMenu, dispatch]);
}
