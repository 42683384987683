import { FormikValues, getIn, useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useRef, useState } from 'react';

import { isDayAfter, isDayBefore } from 'utils/date';

import { fetchAuthenticatedBlob } from 'hooks/assets/useAuthenticatedAsset';
import useIsMounted from 'hooks/useIsMounted';
import useSyncedRef from 'hooks/useSyncedRef';

import { InitialValues } from '.';

export default function useFormContext<FormState extends FormikValues>() {
  const context = useFormikContext<FormState>();

  /**
   * useFieldValue
   *
   * @example
   * const fieldValue = useFieldValue('fieldName');
   */
  function useFieldValue<Path extends string, Value = GetTypeAtPath<InitialValues<FormState>, Path>>(path: Path) {
    const { values } = useFormikContext<FormState>();
    const value: Value = getIn(values, path);
    const [cachedValue, setCachedValue] = useState(value);

    useEffect(() => {
      if (!isEqual(cachedValue, value)) setCachedValue(value);
    }, [cachedValue, value]);

    return cachedValue;
  }

  /**
   * useFieldsResetter
   *
   * @example
   * useFieldsResetter(
   *  {
   *    dependentId: null,
   *    dependentQuantity: 1,
   *  },
   *  'example'
   * );
   */
  function useFieldsResetter<Fields extends Record<string, any>, Dep extends string>(
    fields: {
      [Path in keyof Fields]: PathExists<FormState, Path> extends true ? any : never;
    },
    dependency: PathExists<FormState, Dep> extends true ? Dep : never
  ) {
    const { setFieldValue } = useFormikContext<FormState>();
    const dependencyValue: any = useFieldValue(dependency);
    const previousDependencyValue = useRef(dependencyValue);

    useEffect(() => {
      if (dependencyValue !== previousDependencyValue.current) {
        Object.entries(fields).forEach(([name, value]) => setFieldValue(name, value, false));
      }

      previousDependencyValue.current = dependencyValue;
    }, [dependencyValue, fields, setFieldValue]);
  }

  /**
   * useDisabledDateRange
   *
   * @example
   * const [startDateDisabled, endDateDisabled] = useDisabledDateRange()
   * const [arrivalDateDisabled, leaveDateDisabled] =
   *   useDisabledDateRange({ startDate: 'arrivalDate', endDate: 'leaveDate' })
   */
  function useDisabledDateRange({ startDate, endDate } = { startDate: 'startDate', endDate: 'endDate' }) {
    const startDateValue: any = useFieldValue(startDate);
    const endDateValue: any = useFieldValue(endDate);

    const disableStartDate = useCallback(
      (date: Date) => (endDateValue instanceof Date ? isDayAfter(date, endDateValue) : false),
      [endDateValue]
    );

    const disableEndDate = useCallback(
      (date: Date) => (startDateValue instanceof Date ? isDayBefore(date, startDateValue) : false),
      [startDateValue]
    );

    return [disableStartDate, disableEndDate] as const;
  }

  /**
   * useFileFieldSetter
   *
   * Fetch the avatar so we can make a `File` to pass to the `FileInput`.
   * Optimally the `FileInput` should also accept a data URI so this is not needed.
   *
   * @example
   * useFileFieldSetter('avatar', user.avatarUrl);
   */
  function useFileFieldSetter(fieldName: string, url: string | null) {
    const { setFieldValue, values } = useFormikContext<FormState>();
    const isMounted = useIsMounted();

    const valuesRef = useSyncedRef(values);

    useEffect(() => {
      async function fetchFile(url: string) {
        const response = await fetchAuthenticatedBlob(url);

        const filename = url.match(/([^/?]+)(?:\?.*)?$/)?.[1];
        const contentType = response.headers['content-type'];

        if (!filename || !contentType) throw new Error();

        return new File([response.data], filename, { type: contentType });
      }

      if (url) {
        fetchFile(url)
          .then((file) => {
            if (isMounted() && !getIn(valuesRef.current, fieldName)) setFieldValue(fieldName, file, false);
          })
          .catch(() => {});
      }
    }, [url, fieldName, setFieldValue, isMounted, valuesRef]);
  }

  return {
    ...context,
    useFieldValue,
    useFieldsResetter,
    useDisabledDateRange,
    useFileFieldSetter,
  };
}

// Based on https://itnext.io/advanced-typescript-reinventing-lodash-get-db82eac3345e
type GetTypeAtPath<Obj, Path> = Path extends `${infer Left}.${infer Right}`
  ? Left extends keyof Obj
    ? GetTypeAtPath<Exclude<Obj[Left], undefined>, Right> | Extract<Obj[Left], undefined>
    : undefined
  : Path extends keyof Obj
  ? Obj[Path]
  : undefined;

// Based on https://itnext.io/advanced-typescript-reinventing-lodash-get-db82eac3345e
// Equals `true` when the Path exists
// Equals `false` when it doesn't
type PathExists<Obj, Path> = Path extends `${infer Left}.${infer Right}`
  ? Left extends keyof Obj
    ? PathExists<Exclude<Obj[Left], undefined>, Right>
    : false
  : Path extends keyof Obj
  ? true
  : false;
