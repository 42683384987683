import cx from 'classnames';
import { ReactNode } from 'react';

import fullName from 'utils/fullName';

import Avatar from './Avatar';

export interface IdentityProps {
  person: {
    title?: string | null | undefined;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    fullName?: string | null | undefined;
    avatarThumbUrl?: string;
  };
  withTitle?: boolean;
  className?: string;
  children?: ReactNode;
}

export default function Identity({ person, withTitle = false, className, children }: IdentityProps) {
  return (
    <div className={cx('identity', className)}>
      <Avatar className="is-small" url={person.avatarThumbUrl} />
      <div>
        {person.fullName || fullName(person, withTitle)}
        {children}
      </div>
    </div>
  );
}
