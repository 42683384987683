import { forwardRef, ChangeEvent as ReactChangeEvent, useEffect, useRef, useState } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import { BaseInputProps } from './types';

type BaseProps = BaseInputProps<string>;

type CustomProps = {
  delay?: number;
  minQueryLength?: number;
  disableEmptyQuery?: boolean;
  autoComplete?: boolean;
  placeholder?: MessageDescriptor | string;
};

type HtmlProps = Omit<JSX.IntrinsicElements['input'], keyof BaseProps | keyof CustomProps | 'ref' | 'name'>;

export type SearchInputProps = BaseProps & CustomProps & HtmlProps;

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(function SearchInput(props, ref) {
  const {
    defaultValue,
    value: externalValue,
    onChange: externalOnChange,
    onBlur: externalOnBlur,
    id,
    disabled = false,
    autoFocus = false,
    delay = 700,
    minQueryLength = 3,
    disableEmptyQuery = false,
    autoComplete = false,
    placeholder: rawPlaceholder,
    ...inputProps
  } = props;

  const isControlled = !!externalOnChange;

  const { formatMessage } = useIntl();

  const [value, setValue] = useState(isControlled ? externalValue || '' : defaultValue || '');
  const timeout = useRef<number>();

  const cancelTimeout = () => {
    if (timeout.current) {
      window.clearTimeout(timeout.current);
      timeout.current = undefined;
    }
  };

  const placeholder = isMessageDescriptor(rawPlaceholder) ? formatMessage(rawPlaceholder) : rawPlaceholder;

  const onChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    if (isControlled) {
      const { value } = event.target;

      cancelTimeout();

      const isEmptyQuery = disableEmptyQuery ? false : value === '';

      if (value.length >= minQueryLength || isEmptyQuery) {
        const callback = () => externalOnChange?.(value);

        if (delay > 0) {
          timeout.current = window.setTimeout(callback, delay);
        } else {
          callback();
        }
      }

      setValue(value);
    }
  };

  const onBlur = () => {
    externalOnBlur?.();
  };

  useEffect(() => {
    if (isControlled) {
      cancelTimeout();
      setValue(externalValue || '');
    }
  }, [externalValue, isControlled]);

  return (
    <input
      ref={ref}
      id={id}
      className="base-input -type-search"
      type="text"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      autoFocus={autoFocus}
      autoComplete={autoComplete ? 'on' : 'off'}
      placeholder={placeholder || formatMessage(t.placeholder)}
      {...inputProps}
    />
  );
});

SearchInput.displayName = 'SearchInput';

export default SearchInput;

const t = defineMessages({
  placeholder: {
    id: 'search_input_placeholder',
    defaultMessage: 'Search...',
  },
});
