import { http } from 'api/client';
import { deserialize, parseDateTime } from 'api/deserialize';
import { IdentifierTypeAssignmentT } from 'api/endpoints/identifiers';

import labelize from 'utils/labelize';
import sortInline from 'utils/sort';

export interface IdentifierTypeT {
  id: string;
  name: string;
  slug: string;
  description: string | null | undefined;
  enforceUniqueUsage: boolean;
  hasValidations: boolean;
  updatedAt: Date;
  createdAt: Date;
  identifierTypeRules: any[];
}

export async function fetchIdentifierTypes() {
  const { data } = await http.get<{
    identifierTypes: IdentifierTypeT[];
  }>('identifiers/identifier_types');

  return data.identifierTypes
    .map((type) =>
      deserialize(type, {
        'createdAt': parseDateTime,
        'updatedAt': parseDateTime,
        'identifierTypeRules[].createdAt': parseDateTime,
        'identifierTypeRules[].updatedAt': parseDateTime,
        'identifierTypeAssignments[].createdAt': parseDateTime,
        'identifierTypeAssignments[].updatedAt': parseDateTime,
      })
    )
    .sort(sortInline('contextLinkId'));
}

export interface CreateIdentifierTypeAssignmentArgs {
  personTypeId: string;
  identifierTypeId: string;
  primary: boolean;
  required: boolean;
  printOnReports: boolean;
  identifierUsages: string[];
}

export async function createIdentifierTypeAssignment(identifierTypeAssignment: CreateIdentifierTypeAssignmentArgs) {
  await http.post<{
    identifierTypeAssignment: IdentifierTypeAssignmentT;
  }>('identifiers/identifier_type_assignments', {
    identifierTypeAssignment,
  });
}

export interface UpdateIdentifierTypeAssignmentArgs {
  id: string;
  personTypeId: string;
  identifierTypeId: string;
  primary: boolean;
  required: boolean;
  printOnReports: boolean;
  identifierUsages: string[];
}

export async function updateIdentifierTypeAssignment({
  id,
  ...identifierTypeAssignment
}: UpdateIdentifierTypeAssignmentArgs) {
  await http.put<{
    identifierTypeAssignment: IdentifierTypeAssignmentT;
  }>(`identifiers/identifier_type_assignments/${id}`, {
    identifierTypeAssignment,
  });
}

export async function deleteIdentifierTypeAssignment(id: string) {
  await http.delete(`identifiers/identifier_type_assignments/${id}`);
}

export async function fetchIdentifierTypeAssignments(personTypeId?: string) {
  const { data } = await http.get<{
    identifierTypeAssignments: IdentifierTypeAssignmentT[];
  }>('identifiers/identifier_type_assignments', { params: { personTypeId } });

  return data.identifierTypeAssignments
    .map((assignment) =>
      deserialize(assignment, {
        createdAt: parseDateTime,
        updatedAt: parseDateTime,
      })
    )
    .sort(sortInline('contextLinkId'));
}

export interface IdentifierTypeAssignmentUsageTypeT {
  id: string;
  key: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}

export async function fetchIdentifierTypeAssignmentUsageTypes(personTypeId?: string) {
  const { data } = await http.get<{
    identifierTypeAssignmentUsageTypes: IdentifierTypeAssignmentUsageTypeT[];
  }>('identifiers/identifier_type_assignment_usage_types', {
    params: { personTypeId },
  });

  return data.identifierTypeAssignmentUsageTypes.map((type) =>
    deserialize(type, {
      createdAt: parseDateTime,
      updatedAt: parseDateTime,
    })
  );
}

export interface CreateIdentifierTypeArgs {
  name: string;
  description: string | null | undefined;
  enforceUniqueUsage: boolean;
  rules: any[];
}

export async function createIdentifierType(identifierType: CreateIdentifierTypeArgs) {
  const { data } = await http.post<{
    identifierType: IdentifierTypeT;
  }>('identifiers/identifier_types', {
    identifierType,
  });

  return deserialize(data.identifierType, {
    createdAt: parseDateTime,
    updatedAt: parseDateTime,
  });
}

export interface UpdateIdentifierTypeArgs {
  id: string;
  name: string;
  description: string | null | undefined;
  enforceUniqueUsage: boolean;
  rules: any[];
}

export async function updateIdentifierType({ id, ...identifierType }: UpdateIdentifierTypeArgs) {
  const { data } = await http.put<{
    identifierType: IdentifierTypeT;
  }>(`identifiers/identifier_types/${id}`, {
    identifierType,
  });

  return deserialize(data.identifierType, {
    createdAt: parseDateTime,
    updatedAt: parseDateTime,
  });
}

export async function deleteIdentifierType(id: string) {
  await http.delete(`identifiers/identifier_types/${id}`);
}

export async function fetchIdentifierTypeOptions() {
  const { data } = await http.get<{
    options: OptionT[];
  }>('identifiers/identifier_types_options', {
    params: { type: 'national_number' },
  });

  return data.options.map(labelize()).sort(sortInline('label'));
}
