import cx from 'classnames';

import Logo from 'sharedComponents/Logo';

export type Props = {
  className?: string;
  type?: 'default' | 'static' | 'small' | 'small-white';
};

export default function Loading({ className, type = 'default' }: Props) {
  return (
    <div
      className={cx('loader', className, {
        '-is-static': type === 'static',
        '-is-small': type === 'small',
        '-is-small-white': type === 'small-white',
      })}
    >
      <div
        className={cx('loader__content', {
          '-is-small-white': type === 'small-white',
          '-is-small': type === 'small',
        })}
      >
        <Logo />
      </div>
    </div>
  );
}
