import { FunctionComponent, ReactNode } from 'react';
import { MessageDescriptor } from 'react-intl';

import { CLASSNAME } from '../constants';
import { t } from '../translations';
import { Text } from './Text';

export interface NoOptionsProps {
  text: MessageDescriptor | string | undefined;
  children?: ReactNode;
}

export const NoOptions: FunctionComponent<NoOptionsProps> = ({ text, children }) => {
  return (
    <div className={`${CLASSNAME}__option-message -is-no-options`}>
      {children || <Text text={text || t.noOptions} />}
    </div>
  );
};
