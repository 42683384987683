import { ChangeEvent, FormEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from 'components/Button';

import t from '../translations';

interface RersetPasswordFormProps {
  onSubmit: (values: { password: string; passwordConfirmation: string }) => void;
}

export default function ResetPasswordForm({ onSubmit }: RersetPasswordFormProps) {
  const { formatMessage } = useIntl();

  const [formData, setFormData] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="password" className="sr-only">
        <FormattedMessage {...t.password} />
      </label>
      <input
        type="password"
        id="password"
        name="password"
        placeholder={formatMessage(t.password)}
        value={formData.password}
        onChange={handleChange}
      />

      <label htmlFor="password" className="sr-only">
        <FormattedMessage {...t.password} />
      </label>
      <input
        type="password"
        id="passwordConfirmation"
        name="passwordConfirmation"
        placeholder={formatMessage(t.passwordConfirmation)}
        value={formData.passwordConfirmation}
        onChange={handleChange}
      />

      <Button type="submit">{t.resetPasswordSubmit}</Button>
    </form>
  );
}
