import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { months } from 'translatedResources/Date/Months';
import { weekDaysLong, weekDaysShort } from 'translatedResources/Date/WeekDays';

import { useWeekDayOffset } from 'hooks/useConfig';

import { LocaleUtilsContextProvider } from 'components/DatePicker/LocaleUtilsContext';

export default function DatePickerLocaleUtilsProvider({ children }: { children: ReactNode }) {
  const intl = useIntl();
  const weekDayOffset = useWeekDayOffset();

  const localeUtils = {
    formatDay: (date: Date) =>
      intl.formatDate(date, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    formatWeekdayShort: (index: number) => weekDaysShort(intl)[index],
    formatWeekdayLong: (index: number) => weekDaysLong(intl)[index],
    getFirstDayOfWeek: () => weekDayOffset,
    getMonths: () => months(intl) as any,
    formatMonthTitle: (date: Date) => `${months(intl)[date.getMonth()]} ${date.getFullYear()}`,
  };

  return <LocaleUtilsContextProvider localeUtils={localeUtils}>{children}</LocaleUtilsContextProvider>;
}
