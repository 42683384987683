import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useExportsEnabled, useLetterBuilderEnabled, useRecordEnabled } from 'hooks/useConfig';

import Page from 'components/Page';

import RequireAuthRoute from 'sharedComponents/RequireAuthRoute';

import { RIGHTS } from 'rights';

import { useToolsEnabled } from './tools/utils';

const Issues = lazy(() => import('./sessions/Issues'));
const ActiveSessions = lazy(() => import('./sessions/active'));
const RecentSessions = lazy(() => import('./sessions/recent'));
const Patients = lazy(() => import('./patients'));
const Planning = lazy(() => import('./planning'));
const Reports = lazy(() => import('./reports'));
const Letters = lazy(() => import('./letters'));
const ExportCenter = lazy(() => import('./exportCenter'));
const Settings = lazy(() => import('./settings'));
const Tools = lazy(() => import('./tools'));

export default function RecordRoutes() {
  const recordEnabled = useRecordEnabled();
  const exportsEnabled = useExportsEnabled();
  const { someToolsEnabled } = useToolsEnabled();
  const letterBuilderEnabled = useLetterBuilderEnabled();

  return (
    <Routes>
      <Route
        path=""
        element={
          <Page.Content>
            <Issues />
            <ActiveSessions />
          </Page.Content>
        }
      />
      <Route path="recent_sessions" element={<RecentSessions />} />
      <Route path="patients/*" element={<Patients />} />

      {recordEnabled ? (
        <>
          <Route
            path="planning"
            element={
              <RequireAuthRoute rights={[RIGHTS.VIEW_PLANNING]}>
                <Planning />
              </RequireAuthRoute>
            }
          />
          <Route path="reports" element={<Reports />} />

          {letterBuilderEnabled ? <Route path="letters/*" element={<Letters />} /> : null}

          {exportsEnabled ? (
            <Route
              path="export_center/*"
              element={
                <RequireAuthRoute rights={[RIGHTS.MANAGE_EXPORTS]}>
                  <ExportCenter />
                </RequireAuthRoute>
              }
            />
          ) : null}
        </>
      ) : null}

      {someToolsEnabled ? <Route path="tools/*" element={<Tools />} /> : null}

      <Route
        path="settings/*"
        element={
          <RequireAuthRoute
            rights={[RIGHTS.MANAGE_APPLICATION_DATA, RIGHTS.EDIT_ADMINISTRATIVE_RECORD, RIGHTS.MANAGE_SYSTEM]}
          >
            <Settings />
          </RequireAuthRoute>
        }
      />

      <Route path="*" element={<Navigate to="/not_found" replace />} />
    </Routes>
  );
}
