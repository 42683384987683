import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { useAccountLockable } from 'hooks/useConfig';

import Button from 'components/Button';
import Flash from 'components/Flash';

import ErrorFlash from 'sharedComponents/ErrorFlash';

import { requestAccountUnlock, requestPasswordReset } from '../fetchers';
import SigninContent from '../SigninContent';
import t from '../translations';
import ResetRequestForm from './Form';

export default function RecoverAccount() {
  const { formatMessage } = useIntl();

  const isAccountLockable = useAccountLockable();

  const [optionChosen, setOptionChosen] = useState(!isAccountLockable);
  const [recoverType, setRecoverType] = useState(isAccountLockable ? 'none' : 'password');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();

  const requestPasswordResetMutation = useMutation(requestPasswordReset, {
    onSuccess: () => {
      setSuccess(true);
      setError(undefined);
    },
    onError: (error: any) => {
      setError(error?.response?.data?.errors?.[0]?.detail || formatMessage(t.errorMessageFallback));
    },
  });

  const requestAccountUnlockMutation = useMutation(requestAccountUnlock, {
    onSuccess: () => {
      setSuccess(true);
      setError(undefined);
    },
    onError: (error: any) => {
      setError(error?.response?.data?.errors?.[0]?.detail || formatMessage(t.errorMessageFallback));
    },
  });

  if (!optionChosen) {
    return (
      <div className="login">
        <SigninContent title={formatMessage(t.recoverAccount)}>
          <div>
            <Button
              onClick={() => {
                setOptionChosen(true);
                setRecoverType('password');
              }}
            >
              {t.forgotPassword}
            </Button>
          </div>

          <div>
            <Button
              onClick={() => {
                setOptionChosen(true);
                setRecoverType('unlock');
              }}
            >
              {t.requestAccountUnlock}
            </Button>
          </div>

          <Link style={{ marginTop: '10px' }} to="/login">
            <FormattedMessage {...t.returnLogin} />
          </Link>
        </SigninContent>
      </div>
    );
  }

  return (
    <div className="login">
      <SigninContent title={formatMessage(recoverType === 'password' ? t.forgotPassword : t.requestAccountUnlock)}>
        <ErrorFlash close={false} substituteMessage={formatMessage(t.errorMessageFallback)} />

        {error ? (
          <Flash close={false} type="danger">
            {error}
          </Flash>
        ) : null}

        {success ? (
          <FormattedMessage {...t.recoverSuccessMessage} tagName="p" />
        ) : (
          <ResetRequestForm
            onSubmit={(values) =>
              recoverType === 'password'
                ? requestPasswordResetMutation.mutate(values)
                : requestAccountUnlockMutation.mutate(values)
            }
          />
        )}

        <Link style={{ marginTop: '10px' }} to="/login">
          <FormattedMessage {...t.returnLogin} />
        </Link>
      </SigninContent>
    </div>
  );
}
