import cx from 'classnames';
import { CSSProperties, ReactElement, ReactNode } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';
import omitNullable from 'utils/omitNullable';

export interface VerticalDataListValue {
  label: MessageDescriptor | ReactNode;
  value: ReactNode;
  hasTable?: boolean;
  wrap?: boolean;
  actionButton?: ReactElement;
}

export interface VerticalDataListProps {
  values: (VerticalDataListValue | null | undefined)[];
  long?: boolean;
  labelsRight?: boolean;
  labelsBold?: boolean;
}

function DataListVertical({ values, long = false, labelsRight = false, labelsBold = false }: VerticalDataListProps) {
  return (
    <dl
      className={cx('vertical-datalist', {
        '-is-long': long,
      })}
    >
      {omitNullable(values).map(({ label, value, hasTable, wrap, actionButton }, index) => (
        <div
          key={index}
          className={cx('vertical-datalist__row', {
            '-is-wrap': wrap,
            '-has-table': hasTable,
          })}
        >
          <dt className={cx('vertical-datalist__label', { '-align-right': labelsRight, '-bold': labelsBold })}>
            {isMessageDescriptor(label) ? <FormattedMessage {...label} /> : label}
          </dt>
          <dd>
            <div className="vertical-datalist__value">{value}</div>
            {actionButton}
          </dd>
        </div>
      ))}
    </dl>
  );
}

export interface HorizontalDataListValue {
  label: MessageDescriptor | ReactNode;
  value: ReactNode;
  maxWidth?: CSSProperties['maxWidth'];
}

export interface HorizontalDataListProps {
  values: (HorizontalDataListValue | null | undefined)[];
}

function DataListHorizontal({ values }: HorizontalDataListProps) {
  return (
    <dl className="horizontal-datalist">
      {omitNullable(values).map(({ label, value, maxWidth }, index) => (
        <div key={index} className="horizontal-datalist__item" style={{ maxWidth }}>
          <dt>{isMessageDescriptor(label) ? <FormattedMessage {...label} /> : label}</dt>
          <dd>{value}</dd>
        </div>
      ))}
    </dl>
  );
}

const DataList = {
  Vertical: DataListVertical,
  Horizontal: DataListHorizontal,
};

export default DataList;
