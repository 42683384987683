import { getInt } from 'styles/vars';

export type FlipConfig = {
  node: HTMLElement;
  duration: number;
  move: () => void;
  before?: () => void;
  after?: () => void;
};

export type Rect = {
  x: number;
  y: number;
  width: number;
  height: number;
};

/**
 * Get the difference between two Rect objects.
 */
const getDelta = (rectA: Rect, rectB: Rect): Rect => ({
  x: rectA.x - rectB.x,
  y: rectA.y - rectB.y,
  width: rectA.width / rectB.width,
  height: rectA.height / rectB.height,
});

/*
 * This animates the transition.
 *
 * Paul Lewis FLIP
 * https://aerotwist.com/blog/flip-your-animations/
 */
const animateMove = ({ node, duration, move, before, after }: FlipConfig) => {
  const from: Rect = node.getBoundingClientRect() as any;

  node.style.willChange = 'transform';
  if (typeof before === 'function') before();

  move();

  const to: Rect = node.getBoundingClientRect() as any;

  const { x, y, width, height } = getDelta(from, to);

  const animation = node.animate(
    [{ transform: `translate(${x}px, ${y}px) scale(${width}, ${height})` }, { transform: 'none' }],
    {
      duration,
      easing: 'ease-out',
      fill: 'both',
    }
  );

  animation.onfinish = () => {
    node.style.willChange = 'auto';
    if (typeof after === 'function') after();
  };
};

/**
 * Apply CSS styles from an object to a DOM node.
 */
const applyStyles = (node: HTMLElement, styles: any) => {
  Object.keys(styles).forEach((prop: any) => {
    node.style[prop] = styles[prop];
  });
};

/**
 * Get the Rect properties for the fullscreen position.
 */
const getFullScreenRect = (): Rect => {
  const { innerWidth, innerHeight } = window;
  const maxWidth = 1220;

  const width = Math.min(innerWidth - getInt('--size-sidebar-width'), maxWidth);
  const height = innerHeight - getInt('--size-header-height');
  const x = innerWidth - width;
  const y = getInt('--size-header-height');

  return { height, width, x, y };
};

/**
 * Apply CSS styles that move the node to the desired position.
 */
const move = (node: HTMLElement, rect: Rect) => {
  const { innerWidth, innerHeight } = window;

  applyStyles(node, {
    position: 'fixed',
    zIndex: getInt('--z-rte-full-screen'),
    top: `${rect.y}px`,
    left: `${rect.x}px`,
    bottom: `${innerHeight - (rect.y + rect.height)}px`,
    right: `${innerWidth - (rect.x + rect.width)}px`,
  });
};

/**
 * Toggle the visibility of the RTE's content.
 */
const toggleContentVisibility = (node: HTMLElement, visible: boolean) => {
  const content = node.querySelector<HTMLElement>('.rte-content');
  const toolbar = node.querySelector<HTMLElement>('.rte-toolbar');

  if (content) content.style.opacity = visible ? '1' : '0';
  if (toolbar) toolbar.style.opacity = visible ? '1' : '0';
};

/**
 * Reset CSS properties that were set during the animation.
 */
const resetMove = (node: HTMLElement) => {
  ['position', 'z-index', 'top', 'left', 'bottom', 'right'].forEach((prop) => {
    node.style.removeProperty(prop);
  });
};

export const enableFullScreen = (node: HTMLElement, duration: number): Promise<void> => {
  return new Promise((resolve) => {
    animateMove({
      node,

      duration,

      move: () => move(node, getFullScreenRect()),

      before: () => {
        node.classList.remove('is-mini');
        toggleContentVisibility(node, false);
      },

      after: () => {
        node.classList.add('is-fullscreen');
        toggleContentVisibility(node, true);
        resetMove(node);
        resolve();
      },
    });
  });
};

export const disableFullScreen = (node: HTMLElement, originalRect: Rect, duration: number): Promise<void> => {
  return new Promise((resolve) => {
    animateMove({
      node,

      duration,

      move: () => move(node, originalRect),

      before: () => {
        node.classList.remove('is-fullscreen');
        toggleContentVisibility(node, false);
      },

      after: () => {
        node.classList.add('is-mini');
        toggleContentVisibility(node, true);
        resetMove(node);
        resolve();
      },
    });
  });
};
