import { ChangeEvent as ReactChangeEvent } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import { BaseInputProps } from './types';

type BaseProps = BaseInputProps<string>;

type CustomProps = {
  placeholder?: MessageDescriptor | string;
};

type HtmlProps = Omit<JSX.IntrinsicElements['input'], keyof BaseProps | keyof CustomProps | 'name'>;

export type PasswordInputProps = BaseProps & CustomProps & HtmlProps;

export default function PasswordInput({
  defaultValue,
  value,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  id,
  disabled = false,
  autoFocus = false,
  placeholder: rawPlaceholder,
}: PasswordInputProps) {
  const isControlled = !!externalOnChange;
  const { formatMessage } = useIntl();

  const onChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    externalOnChange?.(event.target.value);
  };

  const onBlur = () => {
    externalOnBlur?.();
  };

  const placeholder = isMessageDescriptor(rawPlaceholder) ? formatMessage(rawPlaceholder) : rawPlaceholder;

  return (
    <input
      id={id}
      className="base-input -type-password"
      type="password"
      defaultValue={isControlled ? undefined : defaultValue || ''}
      value={isControlled ? value || '' : undefined}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      autoFocus={autoFocus}
      placeholder={rawPlaceholder ? placeholder : undefined}
    />
  );
}
