import { ChangeEvent as ReactChangeEvent } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import { BaseInputProps } from './types';

type BaseProps = BaseInputProps<string>;

type CustomProps = {
  autoComplete?: boolean;
  placeholder?: MessageDescriptor | string;
};

type HtmlProps = Omit<JSX.IntrinsicElements['input'], keyof BaseProps | keyof CustomProps | 'name'>;

export type EmailInputProps = BaseProps & CustomProps & HtmlProps;

export default function EmailInput({
  defaultValue,
  value,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  id,
  disabled = false,
  autoFocus = false,
  autoComplete = false,
  placeholder: rawPlaceholder,
}: EmailInputProps) {
  const isControlled = !!externalOnChange;
  const { formatMessage } = useIntl();

  const onChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    externalOnChange?.(event.target.value.trim());
  };

  const onBlur = () => {
    externalOnBlur?.();
  };

  const placeholder = isMessageDescriptor(rawPlaceholder) ? formatMessage(rawPlaceholder) : rawPlaceholder;

  return (
    <input
      id={id}
      className="base-input -type-email"
      type="email"
      defaultValue={isControlled ? undefined : defaultValue ? defaultValue.trim() : ''}
      value={isControlled ? (value ? value.trim() : '') : undefined}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      autoFocus={autoFocus}
      autoComplete={autoComplete ? 'on' : 'off'}
      placeholder={placeholder}
    />
  );
}
