export const RTE_MARK = {
  bold: 'bold',
  italic: 'italic',
  underline: 'underline',
} as const;

export const RTE_HEADING = {
  h1: 'heading-1',
  h2: 'heading-2',
  h3: 'heading-3',
  h4: 'heading-4',
  h5: 'heading-5',
  h6: 'heading-6',
} as const;

export const RTE_LIST = {
  ol: 'numbered-list',
  ul: 'bulleted-list',
} as const;

export const RTE_BLOCK = {
  ...RTE_HEADING,
  ...RTE_LIST,
  listItem: 'list-item',
  paragraph: 'paragraph',
} as const;

export const RTE_INLINE = {
  variable: 'variable',
} as const;

export const RTE_ELEMENT = {
  ...RTE_BLOCK,
  ...RTE_INLINE,
} as const;
