export default function schemaDeserializers(serializersByProperty: Record<string, (value: any) => any>) {
  return (value: Record<string, any>) =>
    Object.keys(value || {}).reduce(
      (acc, key) => ({
        ...acc,
        [key]: serializersByProperty[key] ? serializersByProperty[key](value[key]) : value[key],
      }),
      value
    );
}
