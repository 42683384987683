import { combineReducers } from 'redux';

import activity from 'store/modules/activity';
import entities from 'store/modules/entities/reducers';
import errors from 'store/modules/errors';
import form from 'store/modules/form';
import network from 'store/modules/network';
import pagination from 'store/modules/pagination';
import search from 'store/modules/search';
import success from 'store/modules/success';

export default combineReducers({
  activity,
  entities,
  errors,
  form,
  network,
  pagination,
  search,
  success,
});
