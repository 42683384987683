import { defineMessages, IntlShape } from 'react-intl';

const t = defineMessages({
  nephrologist: {
    id: 'user_profession_nephrologist',
    defaultMessage: 'Nephrologist',
  },
  head_nurse: {
    id: 'user_profession_head_nurse',
    defaultMessage: 'Head nurse',
  },
  nurse: {
    id: 'user_profession_nurse',
    defaultMessage: 'Nurse',
  },
  psychologist: {
    id: 'user_profession_psychologist',
    defaultMessage: 'Psychologist',
  },
  dietician: {
    id: 'user_profession_dietician',
    defaultMessage: 'Dietitian',
  },
  social_worker: {
    id: 'user_profession_social_worker',
    defaultMessage: 'Social worker',
  },
  secretary: {
    id: 'user_profession_secretary',
    defaultMessage: 'Secretary',
  },
  engineer: {
    id: 'user_profession_engineer',
    defaultMessage: 'Engineer',
  },
  system: {
    id: 'user_profession_system',
    defaultMessage: 'System',
  },
  unknown: {
    id: 'user_profession_unknown',
    defaultMessage: 'Unknown',
  },
});

export const userProfessions: string[] = Object.keys(t);

export const formatUserProfession = (
  intl: IntlShape,
  profession: string | null | undefined
): string | null | undefined => {
  if (!profession) return null;
  if (!intl || !t[profession]) return null;

  return intl.formatMessage(t[profession]);
};

export const userProfessionOptions = (intl: IntlShape): OptionT[] =>
  Object.keys(t).map((key) => ({
    label: intl.formatMessage(t[key]),
    value: key,
  }));
