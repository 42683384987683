import {
  ChangeEvent as ReactChangeEvent,
  KeyboardEvent as ReactKeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { defineMessages, FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { selectPatientOptions } from 'api/endpoints/patients';
import { searchPatients } from 'api/endpoints/patients/search';

import { CancelablePromise, useCancelableAsyncFn } from 'hooks/useCancelableAsyncFn';
import usePopupMenu from 'hooks/usePopupMenu';

import Age from 'components/Age';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import Portal from 'components/Portal';
import Sex from 'components/Sex';
import Title from 'components/Title';

export default function PatientNavigator() {
  const { formatMessage } = useIntl();

  const [query, setQuery] = useState('');
  const [options, setOptions] = useState<PatientOptionT[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isSpinning = isLoading;
  const cleanQuery = query.trim();

  const { setActuator, actuatorRef, setMenu, menuRef, isOpen, menuStyles, open, close } = usePopupMenu({
    onClose: () => {
      if (query !== '') setQuery('');
      if (options.length) setOptions([]);
    },
  });

  const cancelablePromiseRef = useRef<CancelablePromise<PatientOptionT[]> | null>(null);

  const searchUsers = useCancelableAsyncFn(
    useCallback(async (query: string) => {
      const patients = await searchPatients({ query });

      return selectPatientOptions(patients);
    }, [])
  )!;

  const onInputChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setQuery(query);
    if (query.trim() === '' && options.length) setOptions([]);
  };

  const onInputKeyDown = (event: ReactKeyboardEvent) => {
    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        menuRef.current?.querySelector('a')?.focus();
        break;
      }

      case 'Escape': {
        event.preventDefault();
        close();
        break;
      }
    }
  };

  const onInputClick = () => {
    if (!isOpen) open();
  };

  const onInputFocus = () => {
    if (!isOpen) open();
  };

  const onOptionKeyDown = (event: ReactKeyboardEvent<HTMLAnchorElement>) => {
    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        const nextOption = event.currentTarget.nextElementSibling as HTMLAnchorElement | null;
        nextOption?.focus();
        break;
      }

      case 'ArrowUp': {
        event.preventDefault();
        const prevOption = event.currentTarget.previousElementSibling as HTMLAnchorElement | null;
        prevOption ? prevOption.focus() : actuatorRef.current?.focus();
        break;
      }

      case 'Escape': {
        event.preventDefault();
        actuatorRef.current?.focus();
        break;
      }

      case ' ': {
        event.preventDefault();
        event.currentTarget.click();
      }
    }
  };

  useEffect(() => {
    const cancel = () => {
      cancelablePromiseRef.current?.cancel();
      cancelablePromiseRef.current = null;
      setIsLoading(false);
    };

    cancel();

    if (cleanQuery !== '') {
      setIsLoading(true);
    }

    const timeout = window.setTimeout(() => {
      if (cleanQuery !== '') {
        cancelablePromiseRef.current = searchUsers(cleanQuery);
        cancelablePromiseRef.current
          .then((options) => {
            setOptions(options);
            setIsLoading(false);
            open();
          })
          .catch(() => {});
      }
    }, 500);

    return () => {
      cancel();
      window.clearTimeout(timeout);
    };
  }, [cleanQuery, searchUsers, open]);

  return (
    <div className="patient-navigator">
      <Icon size="small" isSpinning={isSpinning}>
        {!isSpinning ? 'search' : 'autorenew'}
      </Icon>

      <input
        type="search"
        ref={setActuator}
        value={query}
        onChange={onInputChange}
        className="patient-navigator__input"
        placeholder={formatMessage(t.placeholder)}
        onKeyDown={onInputKeyDown}
        onClick={onInputClick}
        onFocus={onInputFocus}
      />

      {isOpen ? (
        <Portal>
          <div
            ref={setMenu}
            style={{
              ...menuStyles,
              width: '100%',
              backgroundColor: 'var(--gray-dark)',
              border: 'none',
              borderRadius: 0,
            }}
            className="patient-navigator__menu"
          >
            {options.length === 0 ? (
              <div className="patient-navigator__message">
                <FormattedMessage {...t.noResults} />
              </div>
            ) : null}

            {options.map(({ value: id, label, avatarThumbUrl, avatarUrl, birthdate, sex }) => (
              <NavLink
                to={`/record/patients/${id}/dashboard`}
                key={id}
                className="patient-navigator__option select-input-single-value-patient"
                tabIndex={0}
                onKeyDown={onOptionKeyDown}
              >
                <Avatar url={avatarThumbUrl || avatarUrl} />
                <Title
                  subtext={
                    <>
                      {birthdate ? (
                        <span>
                          <FormattedDate value={birthdate} />
                        </span>
                      ) : null}
                      {birthdate ? <Age birthdate={birthdate} /> : null}
                      {sex ? <Sex value={sex} /> : null}
                    </>
                  }
                >
                  {label}
                </Title>
              </NavLink>
            ))}
          </div>
        </Portal>
      ) : null}
    </div>
  );
}

const t = defineMessages({
  noResults: {
    id: 'app_header_user_search_no_results',
    defaultMessage: 'No patients found',
  },
  placeholder: {
    id: 'app_header_user_search_placeholder',
    defaultMessage: 'Type to search for a patient...',
  },
});
