import { defineMessages, IntlShape } from 'react-intl';

const t = defineMessages({
  center_based: {
    id: 'user_permission_center_based',
    defaultMessage: 'Center-based',
  },
  global: {
    id: 'user_permission_global',
    defaultMessage: 'Global',
  },
  integration: {
    id: 'user_permission_integration',
    defaultMessage: 'Integration',
  },
});

export function userPermissionOptions(intl: IntlShape, filterIntegration = true) {
  return Object.keys(t)
    .map<OptionT>((value) => ({
      value,
      label: intl.formatMessage(t[value]),
    }))
    .filter((o) => (filterIntegration ? o.value !== 'integration' : true));
}
