import cx from 'classnames';
import { FunctionComponent, KeyboardEvent as ReactKeyboardEvent } from 'react';

import Icon from 'components/Icon';
import { LoadingIcon } from 'components/Inputs/shared';

import { CLASSNAME, TESTID } from '../constants';
import { SelectInputOption } from '../types';
import { OptionProps } from './Option';

export interface OptionWrapperProps {
  option: SelectInputOption;
  index: number;
  showCheckbox: boolean;
  Component: FunctionComponent<OptionProps>;
  isLoading: boolean;
  isMultiple: boolean;
  isSelected: (value: SelectInputOption['value']) => boolean;
  onChoose: () => void;
  onKeyDown: (event: ReactKeyboardEvent) => void;
}

export const OptionWrapper: FunctionComponent<OptionWrapperProps> = ({
  option,
  index,
  showCheckbox,
  Component,
  isLoading,
  isMultiple,
  isSelected,
  onChoose,
  onKeyDown,
}) => {
  const isOptionSelected = isSelected(option.value);
  const isDisabled = !!option.disabled;

  return (
    <div
      role="option"
      tabIndex={0}
      aria-selected={isOptionSelected}
      data-index={index}
      className={cx(`${CLASSNAME}__option`, { '-is-selected': isOptionSelected, '-is-disabled': isDisabled })}
      onClick={onChoose}
      onKeyDown={onKeyDown}
      data-testid={`${TESTID}-option-${option.value}`}
    >
      <div className={`${CLASSNAME}__option__controls`}>
        {isOptionSelected ? (
          <Icon size="small" className={`${CLASSNAME}__option__checkbox-icon -is-checked`}>
            {isMultiple ? 'check_box' : 'radio_button_checked'}
          </Icon>
        ) : showCheckbox ? (
          <Icon size="small" className={`${CLASSNAME}__option__checkbox-icon`}>
            {isMultiple ? 'check_box_outline_blank' : 'radio_button_unchecked'}
          </Icon>
        ) : null}
      </div>

      <Component option={option} isSelected={isOptionSelected} />

      <div className={`${CLASSNAME}__option__controls`}>
        {isLoading ? <LoadingIcon className={`${CLASSNAME}__option__loading-icon`} /> : null}
      </div>
    </div>
  );
};
