export default function submitForm(element: HTMLElement | null | undefined) {
  if (!element) return;

  const form = element.nodeName === 'FORM' ? element : element.querySelector('form:not([data-no-submit])');
  if (!form) return;

  let submitButton = form.querySelector<HTMLButtonElement>('button[type="submit"]');

  if (submitButton) {
    submitButton.click();
  } else {
    submitButton = document.createElement('button');
    submitButton.type = 'submit';
    form.appendChild(submitButton);
    submitButton.click();
    submitButton.remove();
  }
}
