import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode, useEffect } from 'react';

import { useStoreErrorHandler } from 'api/storeErrorHandler';

const queryClient = new QueryClient();

export default function DefaultQueryClientProvider({ children }: { children: ReactNode }) {
  const errorHandler = useStoreErrorHandler();

  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error: any) => {
          const { method, url } = error?.config || {};

          const failureType =
            method && url
              ? `${method.toUpperCase()}${url.startsWith('/') ? '' : '_'}${url
                  .replaceAll('/', '_')
                  .toUpperCase()}_FAILURE`
              : 'GENERIC_FETCH_FAILURE';

          errorHandler({ error, failureType });
        },
      },
    });
  }, [errorHandler]);

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
