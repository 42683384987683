import { MutableRefObject, Ref, RefCallback, useCallback } from 'react';

export default function useComposedRefs<T>(...refs: (Ref<T> | undefined)[]): RefCallback<T> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(composeRefs(...refs), refs);
}

function composeRefs<T>(...refs: (Ref<T> | undefined)[]): RefCallback<T> {
  const composedRef = (node: T) => {
    refs.forEach((ref) => setRef(ref, node));
  };

  return composedRef;
}

function setRef<T>(ref: Ref<T> | undefined, value: T) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref !== null && ref !== undefined) {
    (ref as MutableRefObject<T>).current = value;
  }
}
