import { useSessionDashboardIssues } from 'api/endpoints/sessionDashboardIssues';

import checkAuth from 'utils/checkAuth';

import {
  useIntradialyticMedicationSuggestionsEnabled,
  useInvalidMedicationPrescriptionsEnabled,
  usePatientAgendaEnabled,
  useRecordEnabled,
  useTherapyPlansEnabled,
  useTrackEnabled,
  useTransportation,
} from 'hooks/useConfig';

import { useCurrentUser } from 'providers';

import { RIGHTS } from 'rights';

// TODO: Look at what rights are needed for what routes and place them inline so it's easier to manage.
export const TOOLS_MEDICATION_RIGHTS = [
  RIGHTS.MANAGE_INTRADIALYTIC_PRESCRIPTION,
  RIGHTS.MANAGE_NO_VALIDATION_INTRADIALYTIC_PRESCRIPTION,
  RIGHTS.MANAGE_PRE_VALIDATION_INTRADIALYTIC_PRESCRIPTION,
  RIGHTS.MANAGE_POST_VALIDATION_INTRADIALYTIC_PRESCRIPTION,
  RIGHTS.MANAGE_INTRADIALYTIC_PRESCRIPTION_INTERRUPTION,
  RIGHTS.VALIDATE_MEDICATION,
];

export function useToolsEnabled() {
  const currentUser = useCurrentUser();
  const recordEnabled = useRecordEnabled();
  const trackEnabled = useTrackEnabled();
  const intradialyticMedicationSuggestionsEnabled = useIntradialyticMedicationSuggestionsEnabled();
  const invalidMedicationPrescriptionsEnabled = useInvalidMedicationPrescriptionsEnabled();
  const therapyPlansEnabled = useTherapyPlansEnabled();
  const patientAgendaEnabled = usePatientAgendaEnabled();
  const transportEnabled = useTransportation();

  const { issues, isLoadingIssues } = useSessionDashboardIssues({ refetch: false });

  const suggestionsValidationRouteEnabled = checkAuth({
    currentUser,
    rights: TOOLS_MEDICATION_RIGHTS,
    booleanMatch: recordEnabled && intradialyticMedicationSuggestionsEnabled,
  });

  const invalidPrescriptionsRouteEnabled =
    issues?.invalidPrescriptions ||
    checkAuth({
      currentUser,
      rights: TOOLS_MEDICATION_RIGHTS,
      booleanMatch: recordEnabled && invalidMedicationPrescriptionsEnabled,
    });

  const medicationTherapyPlanningRouteEnabled = checkAuth({
    currentUser,
    rights: TOOLS_MEDICATION_RIGHTS,
    booleanMatch: recordEnabled && therapyPlansEnabled,
  });

  const agendaConflictsRouteEnabled = checkAuth({
    booleanMatch: recordEnabled && patientAgendaEnabled,
  });

  const transportRouteEnabled = checkAuth({
    booleanMatch: recordEnabled && transportEnabled,
  });

  const unknownMachineSettingsRouteEnabled = checkAuth({
    currentUser,
    rights: [RIGHTS.ENROLL_PATIENT],
    booleanMatch: recordEnabled || trackEnabled,
  });

  const flags = {
    suggestionsValidationRouteEnabled,
    invalidPrescriptionsRouteEnabled,
    medicationTherapyPlanningRouteEnabled,
    agendaConflictsRouteEnabled,
    transportRouteEnabled,
    unknownMachineSettingsRouteEnabled,
  };

  return {
    ...flags,
    isBusy: isLoadingIssues,
    someToolsEnabled: Object.values(flags).some((flag) => flag === true),
  };
}
