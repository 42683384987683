import { http } from 'api/client';
import { deserialize, parseDate } from 'api/deserialize';

import { PositionT } from '../centers';

interface PatientSearch {
  id: string;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  sex: SexT;
  birthdate: Date | null;
  avatarUrl: string;
  avatarThumbUrl: string;

  postion: PositionT | null;
}

interface SearchPatientsArg {
  query: string;
  searchScope?: 'not_enrolled' | 'supported_treatment_only';
  excludeAssignedPatients?: boolean;
}

export async function searchPatients(params: SearchPatientsArg) {
  try {
    const { data } = await http.get<{ data: PatientSearch[] }>('patients/search', {
      params,
    });

    return data.data.map(deserializeSearchPatient);
  } catch {
    return [];
  }
}

export interface PatientSearchNotEnrolled {
  id: string;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  sex: SexT;
  birthdate: Date | null;
  avatarUrl: string;
  avatarThumbUrl: string;

  admissionId: string | null;
  enrollmentStatus: string;
  primaryIdentifier: string | null;

  lastUnenrollmentAt: Date | null;
  lastUnenrollmentReason: string | null;
  lastUnenrollmentNote: string | null;
}

export async function searchNotEnrolledPatients(params: { query: string }) {
  try {
    const { data } = await http.get<{ data: PatientSearchNotEnrolled[] }>('patients/search_not_enrolled', { params });

    return data.data.map(deserializeSearchPatient);
  } catch {
    return [];
  }
}

function deserializeSearchPatient<T>(patient: T): T {
  return deserialize(patient, { birthdate: parseDate, lastUnenrollmentAt: parseDate });
}
