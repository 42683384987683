import { KeyboardEvent } from 'react';
import { BaseEditor, Editor, Element, Range, Text, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import isCtrlPressed from '../utils/isCtrlPressed';
import { RTE_HEADING } from '.';
import { RTE_BLOCK, RTE_LIST, RTE_MARK } from './constants';
import { Rte } from './domain';
import { slateHelpers } from './slateHelpers';

export function withDomain<T extends BaseEditor & ReactEditor & HistoryEditor>(_editor: T) {
  const editor = _editor as any as Rte.Editor;

  editor.hasSelection = () => {
    return !!editor.selection && !Range.isCollapsed(editor.selection);
  };

  editor.isMarkActive = (format: Rte.MarkType) => {
    const marks = Editor.marks(editor) || {};

    return marks[format] === true;
  };

  editor.isBlockActive = (block: Rte.BlockType) => {
    const [match] = Editor.nodes(editor, {
      match: (node) => Element.isElement(node) && node.type === block,
    });

    return !!match;
  };

  editor.isHeadingActive = () => {
    const [match] = Editor.nodes(editor, {
      match: (node) => Element.isElement(node) && Object.values(RTE_HEADING).includes(node.type as Rte.HeadingType),
    });

    return !!match;
  };

  editor.isColorActive = (color?: string) => {
    const [match] = Editor.nodes(editor, {
      match: (node) => slateHelpers.isTextLike(node) && (color ? node.style?.color === color : !!node.style?.color),
    });

    return !!match;
  };

  editor.removeColor = () => {
    Transforms.setNodes(
      editor,
      { style: null },
      {
        match: (node) => slateHelpers.isTextLike(node),
        split: true,
        voids: true,
        mode: 'highest',
      }
    );
  };

  editor.removeHeading = () => {
    const isActive = editor.isHeadingActive();
    if (!isActive) return;

    Transforms.setNodes(editor, {
      type: RTE_BLOCK.paragraph,
    });
  };

  editor.toggleColor = (color: string) => {
    const isCollapsed = !editor.hasSelection();

    if (!isCollapsed) {
      const isActive = editor.isColorActive(color);

      Transforms.setNodes(
        editor,
        { style: isActive ? null : { color } },
        {
          match: (node) => slateHelpers.isTextLike(node),
          split: true,
          voids: true,
          mode: 'highest',
        }
      );
    }
  };

  editor.toggleMark = (format: Rte.MarkType) => {
    const marks = Editor.marks(editor) || {};
    const isActive = marks[format] === true;

    const { selection } = editor;
    const isExpanded = selection && Range.isExpanded(selection);

    if (isExpanded) {
      Transforms.setNodes(
        editor,
        { [format]: isActive ? null : true },
        {
          match: (node) => slateHelpers.isTextLike(node),
          split: true,
          voids: true,
          mode: 'highest',
        }
      );
    } else {
      if (isActive) {
        editor.removeMark(format);
      } else {
        editor.addMark(format, true);
      }
    }
  };

  editor.toggleBlock = (block: Rte.BlockType) => {
    const isActive = editor.isBlockActive(block);
    const isList = Object.values(RTE_LIST).includes(block as Rte.ListType);

    Transforms.unwrapNodes(editor, {
      match: (node) => Element.isElement(node) && Object.values(RTE_LIST).includes(node.type as Rte.ListType),
      split: true,
    });

    Transforms.setNodes(editor, {
      type: isActive ? RTE_BLOCK.paragraph : isList ? RTE_BLOCK.listItem : block,
    });

    if (!isActive) {
      Transforms.setNodes(
        editor,
        { style: null, bold: null, italic: null, underline: null },
        {
          match: (node) => Text.isText(node),
          split: true,
        }
      );
    }

    if (!isActive && isList) {
      Transforms.wrapNodes(editor, {
        type: block,
        children: [],
      });
    }
  };

  editor.insertParagraph = () => {
    Transforms.insertNodes(editor, slateHelpers.createParagraph());
  };

  editor.focus = () => {
    ReactEditor.focus(editor);
    Transforms.collapse(editor, {
      edge: 'end',
    });
  };

  editor.clearFormatting = () => {
    Transforms.setNodes(
      editor,
      { style: null, bold: null, italic: null, underline: null },
      {
        match: (node) => slateHelpers.isTextLike(node),
        split: true,
      }
    );

    Transforms.unwrapNodes(editor, {
      match: (node) => Element.isElement(node) && Object.values(RTE_LIST).includes(node.type as Rte.ListType),
      split: true,
    });

    Transforms.setNodes(editor, {
      type: RTE_BLOCK.paragraph,
    });
  };

  editor.keyDown = (event: KeyboardEvent<HTMLElement>) => {
    switch (event.key) {
      case 'Enter': {
        if (editor.isHeadingActive()) {
          event.preventDefault();
          editor.insertParagraph();
        }

        break;
      }

      default:
        break;
    }

    if (isCtrlPressed(event)) {
      switch (event.key) {
        case 'b':
          event.preventDefault();
          editor.toggleMark(RTE_MARK.bold);
          break;

        case 'i':
          event.preventDefault();
          editor.toggleMark(RTE_MARK.italic);
          break;

        case 'u':
          event.preventDefault();
          editor.toggleMark(RTE_MARK.underline);
          break;

        default:
          break;
      }
    }
  };

  editor.canUndo = () => {
    const { history } = editor;
    const { undos } = history;

    return undos.length > 0;
  };

  editor.canRedo = () => {
    const { history } = editor;
    const { redos } = history;

    return redos.length > 0;
  };

  return editor;
}
