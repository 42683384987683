import { forwardRef, ChangeEvent as ReactChangeEvent, ReactNode } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import { Affix } from './shared';
import { BaseInputProps } from './types';

type BaseProps = BaseInputProps<string>;

type CustomProps = {
  prefix?: ReactNode;
  suffix?: ReactNode;
  autoComplete?: boolean;
  placeholder?: MessageDescriptor | string;
};

type HtmlProps = Omit<JSX.IntrinsicElements['input'], keyof BaseProps | keyof CustomProps | 'ref' | 'name'>;

export type TextInputProps = BaseProps & CustomProps & HtmlProps;

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(function TextInput(props, ref) {
  const {
    defaultValue,
    value,
    onChange: externalOnChange,
    onBlur: externalOnBlur,
    id,
    disabled = false,
    autoFocus = false,
    autoComplete = false,
    prefix,
    suffix,
    placeholder: rawPlaceholder,
    ...inputProps
  } = props;

  const isControlled = !!externalOnChange;
  const { formatMessage } = useIntl();

  const onChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    externalOnChange?.(event.target.value);
  };

  const onBlur = () => {
    externalOnBlur?.();
  };

  const placeholder = isMessageDescriptor(rawPlaceholder) ? formatMessage(rawPlaceholder) : rawPlaceholder;

  return (
    <div className="base-input -type-text">
      {prefix ? <Affix prefix>{prefix}</Affix> : null}
      <input
        ref={ref}
        id={id}
        type="text"
        defaultValue={isControlled ? undefined : defaultValue || ''}
        value={isControlled ? value || '' : undefined}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        autoFocus={autoFocus}
        autoComplete={autoComplete ? 'on' : 'off'}
        placeholder={placeholder}
        {...inputProps}
      />
      {suffix ? <Affix suffix>{suffix}</Affix> : null}
    </div>
  );
});

TextInput.displayName = 'TextInput';

export default TextInput;
