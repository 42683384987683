import { ReactNode } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import Loading from 'components/Loading';

interface Props<T> {
  items: T[] | null | undefined;
  renderItem: (item: T, index: number) => ReactNode;
  actions?: ReactNode;
  noItemsText?: string;
}

export default function List<T>({ items, renderItem, actions, noItemsText }: Props<T>) {
  const renderList = () => {
    if (!items) {
      return <Loading type="static" />;
    }

    if (items.length === 0) {
      return <div className="modal-list-item no-content">{noItemsText || <FormattedMessage {...t.noItems} />}</div>;
    }

    return items.map(renderItem);
  };

  return (
    <div className="modal-list">
      {renderList()}
      {actions && <div className="modal-list-actions">{actions}</div>}
    </div>
  );
}

const t = defineMessages({
  noItems: {
    id: 'modal_list_no_items',
    defaultMessage: 'No items',
  },
});
