import { useMutation, useQueryClient } from '@tanstack/react-query';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { useStoreErrorHandler } from 'api/storeErrorHandler';

import { formatUserProfession } from 'translatedResources/userProfession';

import { useAppDispatch } from 'hooks/redux';
import useCloseActionBar from 'hooks/useCloseActionBar';
import { usePasswordEnforcingAuthentication } from 'hooks/useConfig';
import useToast from 'hooks/useToast';

import { useCurrentUser } from 'providers';

import ActionBar from 'components/ActionBar';
import ActionBarManager from 'components/ActionBarManager';
import ActionButton from 'components/ActionButton';
import Avatar from 'components/Avatar';
import DataList from 'components/DataList';
import Page from 'components/Page';
import Section from 'components/Section';

import UpdatePasswordForm from '../UpdatePasswordForm';
import UserForm from '../UserForm';
import {
  EDIT_PROFILE_DATA,
  EDIT_PROFILE_PASSWORD,
  startEditProfileDataActivity,
  startEditProfilePasswordActivity,
  UPDATE_CURRENT_USER_FAILURE,
  UPDATE_CURRENT_USER_PASSWORD_FAILURE,
} from './actions';
import {
  updateCurrentUser,
  UpdateCurrentUserArgsT,
  updateCurrentUserPassword,
  UpdateCurrentUserPasswordArgsT,
} from './fetchers';

const t = defineMessages({
  profileTitle: {
    id: 'profile_title',
    defaultMessage: 'Profile',
  },
  settingsTitle: {
    id: 'settings_title',
    defaultMessage: 'Settings',
  },
  updateProfileActionBarTitle: {
    id: 'update_profile_action_bar_title',
    defaultMessage: 'Edit profile',
  },
  updatePasswordActionBarTitle: {
    id: 'update_profile_password_action_bar_title',
    defaultMessage: 'Edit password',
  },
  updateLanguageActionBarTitle: {
    id: 'update_language_action_bar_title',
    defaultMessage: 'Language settings',
  },
  profileAvatarLabel: {
    id: 'profile_avatar_label',
    defaultMessage: 'Profile image',
  },
  profileTitleLabel: {
    id: 'profile_title_label',
    defaultMessage: 'Title',
  },
  profileFirstNameLabel: {
    id: 'profile_first_name_label',
    defaultMessage: 'First name',
  },
  profileLastNameLabel: {
    id: 'profile_last_name_label',
    defaultMessage: 'Last name',
  },
  profileProfessionLabel: {
    id: 'profile_profession_label',
    defaultMessage: 'Profession',
  },
  profileUsernameLabel: {
    id: 'profile_username_label',
    defaultMessage: 'Username',
  },
  profileEmailLabel: {
    id: 'profile_email_label',
    defaultMessage: 'Email',
  },
  profileLanguageSetting: {
    id: 'profile_language_settings',
    defaultMessage: 'Language',
  },
  basicInformationTitle: {
    id: 'profile_basic_information_title',
    defaultMessage: 'Basic information',
  },
  identifiersTitle: {
    id: 'profile_identifiers_title',
    defaultMessage: 'Identifiers',
  },
  noIdentifiers: {
    id: 'profile_no_identifiers',
    defaultMessage: 'No identifiers yet',
  },
  editButtonTitle: {
    id: 'profile_edit_profile_button',
    defaultMessage: 'Edit profile',
  },
  editPasswordTitle: {
    id: 'profile_edit_password_button',
    defaultMessage: 'Edit password',
  },
  updateProfileSuccess: {
    id: 'profile_update_profile_success',
    defaultMessage: 'Successfully updated your profile.',
  },
  updatePasswordSuccess: {
    id: 'profile_update_password_success',
    defaultMessage: 'Successfully updated your password.',
  },
});

export default function Profile() {
  const intl = useIntl();
  const { formatMessage } = intl;
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();
  const toast = useToast({ type: 'success' });
  const errorHandler = useStoreErrorHandler();
  const closeActionBar = useCloseActionBar();

  const user = useCurrentUser(true);
  const passwordEnforcingAuthentication = usePasswordEnforcingAuthentication();

  const { id, avatarThumbUrl, title, firstName, lastName, username, email, profession } = user;

  const updateCurrentUserMutation = useMutation(updateCurrentUser, {
    onSuccess: (user) => {
      queryClient.setQueryData(['currentUser'], user);
      toast(formatMessage(t.updateProfileSuccess));
      closeActionBar();
    },
    onError: (error: any) => {
      errorHandler({ error, failureType: UPDATE_CURRENT_USER_FAILURE });
    },
  });

  const onUpdateProfile = (values: UpdateCurrentUserArgsT) => {
    updateCurrentUserMutation.mutate({ ...values, username });
  };

  const updateCurrentUserPasswordMutation = useMutation(updateCurrentUserPassword, {
    onSuccess: () => {
      toast(formatMessage(t.updatePasswordSuccess));
      closeActionBar();
    },
    onError: (error: any) => {
      errorHandler({
        error,
        failureType: UPDATE_CURRENT_USER_PASSWORD_FAILURE,
      });
    },
  });

  const onUpdatePassword = (values: UpdateCurrentUserPasswordArgsT) => {
    updateCurrentUserPasswordMutation.mutate(values);
  };

  return (
    <Page.Content>
      <Section>
        <Section.Title
          secondaryContent={
            <>
              <ActionButton
                title={t.editButtonTitle}
                icon="edit"
                onClick={() => dispatch(startEditProfileDataActivity())}
              />
              {passwordEnforcingAuthentication && (
                <ActionButton
                  title={t.editPasswordTitle}
                  icon="vpn_key"
                  onClick={() => dispatch(startEditProfilePasswordActivity())}
                />
              )}
            </>
          }
        >
          <FormattedMessage {...t.profileTitle} />
        </Section.Title>

        <Section.SubContent withoutBorder>
          <Section.SubTitle>
            <FormattedMessage {...t.basicInformationTitle} />
          </Section.SubTitle>

          <DataList.Vertical
            values={[
              {
                label: formatMessage(t.profileAvatarLabel),
                value: <Avatar className="is-mini" url={avatarThumbUrl} />,
              },
              {
                label: formatMessage(t.profileTitleLabel),
                value: title,
              },
              {
                label: formatMessage(t.profileFirstNameLabel),
                value: firstName,
              },
              {
                label: formatMessage(t.profileLastNameLabel),
                value: lastName,
              },
              {
                label: formatMessage(t.profileUsernameLabel),
                value: username,
              },
              {
                label: formatMessage(t.profileEmailLabel),
                value: email,
              },
              {
                label: formatMessage(t.profileProfessionLabel),
                value: formatUserProfession(intl, profession) || '-',
              },
            ]}
          />
        </Section.SubContent>

        <Section.SubContent withoutBorder>
          <Section.SubTitle>
            <FormattedMessage {...t.identifiersTitle} />
          </Section.SubTitle>

          {user.identifiers?.length ? (
            <DataList.Vertical
              values={user.identifiers.map(({ title, value }) => ({
                label: title,
                value: value || '-',
              }))}
            />
          ) : (
            <FormattedMessage {...t.noIdentifiers} />
          )}
        </Section.SubContent>
      </Section>

      <ActionBarManager
        formMapping={{
          [EDIT_PROFILE_DATA]: () => <EditProfileActionBar onSubmit={onUpdateProfile} userId={id} />,
          [EDIT_PROFILE_PASSWORD]: () => <EditProfilePasswordActionBar onSubmit={onUpdatePassword} />,
        }}
      />
    </Page.Content>
  );
}

function EditProfileActionBar({
  onSubmit,
  userId,
}: {
  onSubmit: (values: UpdateCurrentUserArgsT) => void;
  userId: string;
}) {
  return (
    <ActionBar title={t.updateProfileActionBarTitle}>
      <UserForm basicMode update userId={userId} onSubmit={onSubmit} />
    </ActionBar>
  );
}

function EditProfilePasswordActionBar({ onSubmit }: { onSubmit: (values: UpdateCurrentUserPasswordArgsT) => void }) {
  return (
    <ActionBar title={t.updatePasswordActionBarTitle}>
      <UpdatePasswordForm requiresOldPassword submit={onSubmit} />
    </ActionBar>
  );
}
