import { startActivity } from 'store/modules/activity';
import { registerFormErrorAction } from 'store/modules/errors';

import { PatientCard } from './fetchers';

export const ADD_PATIENT_CARD = 'ADD_PATIENT_CARD';
export const EDIT_PATIENT_CARD = 'EDIT_PATIENT_CARD';
export const REMOVE_PATIENT_CARD = 'REMOVE_PATIENT_CARD';

export const CREATE_PATIENT_CARD_FAILURE = registerFormErrorAction('CREATE_PATIENT_CARD_FAILURE');
export const UPDATE_PATIENT_CARD_FAILURE = registerFormErrorAction('UPDATE_PATIENT_CARD_FAILURE');
export const DELETE_PATIENT_CARD_FAILURE = 'DELETE_PATIENT_CARD_FAILURE';

export const addPatientCardActivity = () => startActivity(ADD_PATIENT_CARD);

export const editPatientCardActivity = (patientCard: PatientCard) => startActivity(EDIT_PATIENT_CARD, { patientCard });

export const removePatientCardActivity = (patientCard: PatientCard) =>
  startActivity(REMOVE_PATIENT_CARD, { patientCard });
