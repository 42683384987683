import { defineMessages, useIntl } from 'react-intl';

export const sexTranslations = defineMessages({
  M: {
    id: 'sex_male',
    defaultMessage: 'Male',
  },
  F: {
    id: 'sex_female',
    defaultMessage: 'Female',
  },
  O: {
    id: 'sex_other',
    defaultMessage: 'Other',
  },
  U: {
    id: 'sex_unknown',
    defaultMessage: 'Unknown',
  },
});

export function useSexOptions(options?: { binary?: boolean }) {
  const { formatMessage } = useIntl();

  const sexOptions = Object.keys(sexTranslations)
    .filter((key) => (options?.binary === true ? key === 'M' || key === 'F' : true))
    .map<OptionT>((key) => ({
      label: formatMessage(sexTranslations[key]),
      value: key,
    }));

  const formatSex = (sex: keyof typeof sexTranslations) => formatMessage(sexTranslations[sex]);

  return { sexOptions, formatSex };
}
