import { MouseEvent as ReactMouseEvent, useEffect, useId } from 'react';

const handlers = new Map<string, CustomClickHandler>();

type CustomClickEvent = ReactMouseEvent & { trail?: string[] };
type CustomClickHandler = (event: CustomClickEvent) => void;

export default function useClickOutside(handler: CustomClickHandler) {
  const id = useId();

  useEffect(() => {
    handlers.set(id, handler);

    return () => {
      handlers.delete(id);
    };
  }, [id, handler]);

  return (event: CustomClickEvent) => {
    event.trail ||= [];
    event.trail.push(id);
  };
}

export function useRootClickHandler() {
  return (event: CustomClickEvent) => {
    callHandlersNotInTrail(event.trail || [], event);
  };
}

function callHandlersNotInTrail(trail: string[], event: CustomClickEvent) {
  [...handlers].forEach(([id, handler]) => {
    if (!trail.includes(id)) handler(event);
  });
}
