import cx from 'classnames';
import { forwardRef, ReactNode } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

import Icon from './Icon';

type Importance = 'primary' | 'secondary' | 'tertiary';
type Meaning = 'neutral' | 'affirmative' | 'negative' | 'warning' | 'destructive';
type Size = 'normal' | 'compact';

type HtmlProps = Omit<JSX.IntrinsicElements['button'], keyof CustomProps | 'ref'>;
type CustomProps = {
  executing?: boolean;
  importance?: Importance;
  meaning?: Meaning;
  size?: Size;
  icon?: string;
  title?: MessageDescriptor | string;
  iconRotation?: number;
  children?: MessageDescriptor | ReactNode;
};
type Props = CustomProps & HtmlProps;

const Button = forwardRef<HTMLButtonElement, Props>(function Button(props, ref) {
  const {
    executing = false,
    importance = 'primary',
    meaning = 'neutral',
    size = 'normal',
    icon,
    title,
    iconRotation,
    children,
    disabled,
    type = 'button',
    className,
    style,
    ...buttonProps
  } = props;
  const { formatMessage } = useIntl();
  const translate = (arg: any) => (isMessageDescriptor(arg) ? formatMessage(arg) : arg);

  const isExecuting = executing;
  const isDisabled = executing || disabled;

  const hasContent = !!children;
  const hasIcon = executing || !!icon;

  return (
    <button
      {...buttonProps}
      ref={ref}
      disabled={isDisabled}
      type={type}
      title={translate(title)}
      className={cx(className, 'nf-button', `-importance-${importance}`, `-meaning-${meaning}`, `-size-${size}`, {
        '-is-executing': isExecuting,
        '-has-content': hasContent,
        '-has-icon': hasIcon,
      })}
      style={{
        ...style,
        '--icon-rotation': iconRotation ? `${iconRotation}deg` : undefined,
      }}
    >
      {executing ? <Icon>autorenew</Icon> : icon ? <Icon>{icon}</Icon> : null}
      {hasContent ? <span className="nf-button__content">{translate(children)}</span> : null}
    </button>
  );
});

Button.displayName = 'Button';

export default Button;
export type { Props, Importance, Meaning, Size };
