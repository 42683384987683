import { FunctionComponent, ReactNode } from 'react';

import { CLASSNAME } from '../constants';
import { SelectInputOption } from '../types';

export interface OptionProps {
  option: SelectInputOption;
  isSelected: boolean;
  children?: ReactNode;
}

export const Option: FunctionComponent<OptionProps> = ({ option, children }) => {
  return <div className={`${CLASSNAME}__option__content`}>{children || option.label}</div>;
};
