import { defineMessages } from 'react-intl';

export default defineMessages({
  fallback: {
    id: 'form_error_message_fallback',
    defaultMessage: 'Field is not valid',
  },
  required: {
    id: 'required_validator_error_message',
    defaultMessage: 'Value is required',
  },
  minValue: {
    id: 'min_value_validator_error_message',
    defaultMessage: 'Value must be greater than or equal to {min}',
  },
  minLength: {
    id: 'min_length_validator_error_message',
    defaultMessage: 'Length must be greater than or equal to {min}',
  },
  maxValue: {
    id: 'max_value_validator_error_message',
    defaultMessage: 'Value must be less than or equal to {max}',
  },
  maxLength: {
    id: 'max_length_validator_error_message',
    defaultMessage: 'Length must be less than or equal to {max}',
  },
  format: {
    id: 'format_validator_error_message',
    defaultMessage: 'Value should follow the following format: {regex}',
  },
  contentType: {
    id: 'content_type_validator_error_message',
    defaultMessage: 'Value should be one of the following content types: {types}',
  },
});
