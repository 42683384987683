import { defineMessages } from 'react-intl';

import Button, { Props } from 'components/Button';

export default function SubmitButton({ children, ...buttonProps }: Props) {
  return (
    <Button meaning="negative" {...buttonProps}>
      {children || t.submit}
    </Button>
  );
}

const t = defineMessages({
  submit: {
    id: 'modal_submit_title',
    defaultMessage: 'Submit',
  },
});
