import { components, MultiValueRemoveProps } from 'react-select';

import ClearIcon from '../ClearIcon';

export default function MultiValueRemove(props: MultiValueRemoveProps<any>) {
  return (
    <components.MultiValueRemove {...props}>
      <ClearIcon />
    </components.MultiValueRemove>
  );
}
