import { parse, stringify } from 'qs';

import { isIsoDate } from 'utils/date';

type Query = Record<string, any>;

function decode<Q extends Query>(str: string) {
  return parse(str, {
    ignoreQueryPrefix: true,
    decoder: (value) => {
      if (['true', 'false', 'null'].includes(value)) {
        return JSON.parse(value);
      }
      if (value === '[]' || value === encodeURI('[]')) {
        return [];
      }
      if (/^(\d+|\d*\.\d+)$/.test(value)) {
        return parseFloat(value);
      }

      let decodedValue = value.replace(/\+/g, ' ');
      try {
        decodedValue = decodeURIComponent(decodedValue);
      } catch (error) {
        if (import.meta.env.DEV) {
          // eslint-disable-next-line no-console
          console.error('Failed to decodeURIComponent:', error);
        }
      }

      if (isIsoDate(decodedValue)) {
        return new Date(decodedValue);
      }

      return decodedValue;
    },
  }) as Q;
}

function encode<Q extends Query>(obj: Q) {
  const modifiedObj = Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if ([true, false, null].includes(value)) {
        return [key, JSON.stringify(value)];
      }
      if (Array.isArray(value) && !value.length) {
        return [key, '[]'];
      }

      return [key, value];
    })
  );

  return stringify(modifiedObj, {
    arrayFormat: 'brackets',
  });
}

export default {
  decode,
  encode,
};
