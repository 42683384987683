import { ClearIndicatorProps, components } from 'react-select';

import ClearIcon from '../ClearIcon';

export default function ClearIndicator(props: ClearIndicatorProps<any>) {
  return (
    <components.ClearIndicator {...props}>
      <ClearIcon />
    </components.ClearIndicator>
  );
}
