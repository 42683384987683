import {
  arrow,
  autoUpdate,
  ComputePositionConfig,
  flip,
  offset,
  shift,
  size,
  useFloating,
} from '@floating-ui/react-dom';
import cx from 'classnames';
import {
  CSSProperties,
  forwardRef,
  MouseEvent as ReactMouseEvent,
  ReactNode,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';

import useClickOutside from 'hooks/useClickOutside';
import useDelayedAnimation from 'hooks/useDelayedAnimation';

import Portal from 'components/Portal';

const ANIMATION_DURATION = 200;

export interface PopoverProps {
  isOpen: boolean;
  content: ReactNode;
  children: ReactNode | ((isOpen: boolean) => ReactNode);
  placement?: ComputePositionConfig['placement'];
  floatingOffset?: number;
  persistContent?: boolean;
  floatingClassname?: string;
  floatingContentClassName?: string;
  floatingContentProps?: Omit<JSX.IntrinsicElements['div'], 'ref' | 'children' | 'classname'>;
  actuatorClassName?: string;
  actuatorProps?: Omit<JSX.IntrinsicElements['div'], 'ref' | 'children' | 'classname'>;
  onOutsideClick?: (event: ReactMouseEvent) => void;
}

export default function Popover({
  isOpen,
  content,
  children,
  placement: preferredPlacement = 'right',
  floatingOffset = 10,
  persistContent,
  floatingClassname,
  floatingContentClassName,
  floatingContentProps,
  actuatorClassName,
  actuatorProps,
  onOutsideClick,
}: PopoverProps) {
  const [entered, setEntered] = useState(false);

  const arrowRef = useRef<HTMLDivElement>(null);
  const { refs, placement, reference, floating, middlewareData } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: preferredPlacement,
    middleware: [
      offset(floatingOffset),
      flip(),
      shift(),
      arrow({ element: arrowRef }),
      size({
        apply({ x, y, strategy, placement, elements, rects }) {
          const style: Record<string, string> = {
            position: strategy,
          };

          if (x !== null) {
            if (placement.startsWith('left')) {
              style.right = `${window.innerWidth - rects.floating.width - x}px`;
            } else {
              style.left = `${x}px`;
            }
          }

          if (y !== null) {
            style.top = `${y}px`;
          }

          Object.assign(elements.floating.style, style);
        },
      }),
    ],
  });

  const arrowX = middlewareData.arrow?.x;
  const arrowY = middlewareData.arrow?.y;

  const [isVisible, setIsVisible] = useState(false);
  const [floatingElement, setFloatingElement] = useState<HTMLDivElement | null>(null);

  const shouldRender = useDelayedAnimation(isOpen, ANIMATION_DURATION);
  const shouldTransition = isOpen && !!floatingElement;

  useLayoutEffect(() => {
    if (shouldRender && !!floatingElement && !isVisible) {
      requestAnimationFrame(() => setIsVisible(true));
    }

    if (!shouldRender && isVisible) {
      setIsVisible(false);
    }
  }, [shouldRender, floatingElement, isVisible]);

  const onClick = useClickOutside((event) => {
    if (entered) onOutsideClick?.(event);
  });

  const floatingRef = useMemo(() => {
    return (element: HTMLDivElement | null) => {
      floating(element);
      setFloatingElement(element);
    };
  }, [floating]);

  return (
    <>
      <div {...actuatorProps} className={cx('popover-actuator', actuatorClassName)} ref={reference}>
        {typeof children === 'function' ? children(shouldRender) : children}
      </div>

      <Portal>
        <CSSTransition
          in={shouldTransition}
          timeout={ANIMATION_DURATION}
          classNames="-trans"
          nodeRef={refs.floating}
          onEntered={() => setEntered(true)}
          onExit={() => setEntered(false)}
        >
          {shouldRender || persistContent ? (
            <div
              onClick={onClick}
              ref={floatingRef}
              data-floating-placement={placement}
              className={cx('popover-floating', floatingClassname, { '-is-visible': true })}
            >
              <div {...floatingContentProps} className={cx('popover-floating__content', floatingContentClassName)}>
                <div className="popover-floating__inner-content">{content}</div>
                <Arrow ref={arrowRef} style={{ top: arrowY ?? '', left: arrowX ?? '' }} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </CSSTransition>
      </Portal>
    </>
  );
}

const Arrow = forwardRef<HTMLDivElement, { style: CSSProperties }>(function Arrow(props, ref) {
  const { style } = props;

  return (
    <div ref={ref} className="popover-floating__arrow" data-floating-arrow style={style}>
      <svg className="popover-floating__arrow__svg" width="7" height="14">
        <polygon className="popover-floating__arrow__polygon" points="7,0 0,7, 7,14" />
      </svg>
    </div>
  );
});

Arrow.displayName = 'Arrow';
