import cx from 'classnames';
import { ReactNode } from 'react';

import injectType from 'utils/injectType';

function SectionSubTitle({
  children,
  secondaryContent,
  withoutMargin,
}: {
  children: ReactNode;
  secondaryContent?: ReactNode;
  withoutMargin?: boolean;
}) {
  return (
    <h6 className={cx('section-sub-title', { '-without-margin': withoutMargin })}>
      <div>{children}</div>

      {secondaryContent && <div>{secondaryContent}</div>}
    </h6>
  );
}

const [TypedSectionSubTitle, isSectionSubTitleElement] = injectType(SectionSubTitle, 'section.sectionsubtitle');

export default TypedSectionSubTitle;
export { isSectionSubTitleElement };
