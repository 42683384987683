import { http } from 'api/client';
import { deserialize, parseDate, parseDateTime } from 'api/deserialize';
import { PaginationMeta } from 'api/types';

export interface PatientCard {
  id: string;
  machineId: string;
  deviceName: string;
  identifier: string;
  externalIdentifier: string | undefined;
  firstName: string;
  lastName: string;
  sex: 'M' | 'F';
  birthdate: Date;
  createdAt: Date;
  updatedAt: Date;
  nephroflowIdUri: string | null;
}

function deserializePatientCard(data: any): PatientCard {
  return deserialize(data, {
    birthdate: parseDate,
    createdAt: parseDateTime,
    updatedAt: parseDateTime,
  });
}

interface PatientCardsEnvelope {
  data: PatientCard[];
  meta: PaginationMeta;
}

export async function fetchPatientCards(params: { query: string; centerId: string; page: number }) {
  const { data } = await http.get<PatientCardsEnvelope>('/devices/patient_cards', {
    params: {
      ...params,
      centerId: params.centerId !== 'all' ? params.centerId : undefined,
    },
  });

  return {
    data: data.data.map(deserializePatientCard),
    meta: data.meta,
  };
}

interface PatientCardEnvelope {
  'devices/patientCard': PatientCard;
}

export async function fetchPatientCard(id: string) {
  const { data } = await http.get<PatientCardEnvelope>(`/devices/patient_cards/${id}`);

  return deserializePatientCard(data['devices/patientCard']);
}

export async function createPatientCard(patientCard: PatientCard) {
  const { data } = await http.post<PatientCardEnvelope>('/devices/patient_cards', { patientCard });

  return data['devices/patientCard'];
}

export async function updatePatientCard({ id: patientCardId, externalIdentifier, ...card }: PatientCard) {
  const { data } = await http.put<PatientCardEnvelope>(`/devices/patient_cards/${patientCardId}`, {
    patientCard: { ...card, externalIdentifier: externalIdentifier || null },
  });

  return deserializePatientCard(data['devices/patientCard']);
}

export async function deletePatientCard(id: string) {
  await http.delete(`devices/patient_cards/${id}`);
}
