import { Dispatch, useEffect } from 'react';

import { Action, State } from '../state';
import { SelectInputOption } from '../types';
import { stringifyOption } from '../utils';

export function useSyncOptions({
  options,
  state,
  dispatch,
}: {
  options: SelectInputOption[];
  state: State;
  dispatch: Dispatch<Action>;
}) {
  useEffect(() => {
    if (!optionsAreEqual(options, state.options)) {
      dispatch({ type: 'SET_OPTIONS', payload: options });
    }
  }, [dispatch, options, state.options]);
}

function optionsAreEqual(options1: SelectInputOption[], options2: SelectInputOption[]) {
  return options1.length === options2.length && stringValue(options1) === stringValue(options2);
}

function stringValue(options: SelectInputOption[]) {
  return options.reduce((acc, option) => `${acc} ${stringifyOption(option)}`, '');
}
