import { useQuery } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';

import { fetchCenters } from 'api/endpoints/centers';
import { fetchPersonTypes } from 'api/endpoints/personTypes';
import { fetchUser } from 'api/endpoints/users/$userId';
import { fetchRoles } from 'api/endpoints/users/roles';

import labelize from 'utils/labelize';
import sortInline from 'utils/sort';

import titleOptions from 'translatedResources/titles';
import { userPermissionOptions } from 'translatedResources/userPermission';

import {
  useAuthenticationStrategy,
  useMulticenterAccessRights,
  usePasswordEnforcingAuthentication,
  useStrongPasswordPolicy,
  useUserAccessType,
} from 'hooks/useConfig';

import { useCurrentUser } from 'providers';

import Flash from 'components/Flash';
import Form, {
  BaseFormField,
  EmailField,
  FileField,
  InitialValues,
  ListField,
  PasswordField,
  TextField,
  useFormContext,
} from 'components/FormV3';
import { avatarFilePreviewer, ListInput } from 'components/Inputs';
import Loading from 'components/Loading';
import Section from 'components/Section';

import { fetchIdentifierTypeAssignments } from 'views/admin/iam/identifiers/fetchers';

import t from './translations';

interface Props {
  userId?: string;
  update?: boolean;
  basicMode?: boolean;
  onSubmit: (values: TransformedFormState) => void;
}

export interface FormState {
  avatar: File | null;
  title: string | null;
  firstName: string;
  lastName: string;
  roleId?: string;
  profession: string;
  permission?: string;
  centerIds?: string[];
  identifiers: Record<string, string>;
  email: string;
  username: string;
  password?: string;
  passwordConfirmation?: string;
}

export interface TransformedFormState extends Omit<FormState, 'identifiers'> {
  identifiers: {
    identifierTypeId: string;
    value: string | null;
  }[];
}

export default function UserForm({ userId, update = false, basicMode = false, onSubmit }: Props) {
  const currentUser = useCurrentUser();
  const showMulticenter = useMulticenterAccessRights();
  const defaultUserPermission = useUserAccessType();
  const strongPasswordPolicy = useStrongPasswordPolicy();
  const passwordEnforcingAuthentication = usePasswordEnforcingAuthentication();

  const data = useData({ userId, basicMode });

  const enforcePassword = !update && passwordEnforcingAuthentication;
  const isFetching = (!!userId && data.userIsLoading) || data.personTypesIsLoading || data.identifierTypeIsLoading;

  if (isFetching) {
    return <Loading type="static" />;
  }

  const initialValues: InitialValues<FormState> = {
    avatar: null,
    title: data.user?.title || '',
    firstName: data.user?.firstName || '',
    lastName: data.user?.lastName || '',
    ...(!basicMode && {
      roleId: data.user?.roleId || null,
    }),
    profession: data.user?.profession || null,
    ...(showMulticenter && {
      permission: currentUser.permission || defaultUserPermission,
      centerIds: (currentUser.permission === 'center_based' && currentUser.centerIds) || [],
    }),
    identifiers: Object.fromEntries(
      (data.user?.identifiers || []).map(({ value, identifierTypeAssignmentId }) => {
        const key = data.identifierTypeAssignments.find(
          (assignment) => assignment.id === identifierTypeAssignmentId
        )?.identifierTypeId;

        return [key, value];
      })
    ),
    email: data.user?.email || '',
    username: data.user?.username || '',
  };

  return (
    <>
      <Form initialValues={initialValues} onSubmit={(values: FormState) => onSubmit(transformValues(values))}>
        {strongPasswordPolicy && enforcePassword ? (
          <Section withoutPadding>
            <Flash type="info" close={false}>
              <FormattedMessage {...t.passwordInfo} />
            </Flash>
          </Section>
        ) : null}

        <Fields basicMode={basicMode} update={update} enforcePassword={enforcePassword} data={data} />
      </Form>
    </>
  );
}

interface FieldsProps {
  basicMode: boolean;
  update: boolean;
  enforcePassword: boolean;
  data: ReturnType<typeof useData>;
}

function Fields({ basicMode, update, enforcePassword, data }: FieldsProps) {
  const intl = useIntl();
  const { formatMessage } = intl;

  const showMulticenter = useMulticenterAccessRights();
  const authenticationStrategy = useAuthenticationStrategy();
  const protectLDAPAttributes = update && authenticationStrategy === 'ldap';

  const { useFieldValue } = useFormContext<FormState>();

  const { useFileFieldSetter } = useFormContext();
  useFileFieldSetter('avatar', data.user?.avatarUrl || null);

  const profession = useFieldValue('profession');
  const permission = useFieldValue('permission');

  const allCenterOption: OptionT = {
    label: formatMessage(t.allCenterLabel),
    value: 'all',
  };

  const filteredIdentifierTypeAssignments = data.identifierTypeAssignments.filter(({ personTypeId }) => {
    const professionOption = data.professionOptions.find((option) => option.personTypeId === personTypeId);

    if (!professionOption) return false;
    if (profession !== professionOption.value) return false;

    return true;
  });

  return (
    <>
      <Section>
        <Section.Title>
          <FormattedMessage {...t.profileSection} />
        </Section.Title>

        <FileField
          name="avatar"
          label={t.avatarLabel}
          previewer={avatarFilePreviewer}
          validation={{ contentType: 'image' }}
        />
        <ListField name="title" label={t.titleLabel} placeholder={t.titlePlaceholder} options={titleOptions(intl)} />
        <TextField
          name="firstName"
          label={t.firstNameLabel}
          placeholder={t.firstNamePlaceholder}
          validation={{ required: true }}
        />
        <TextField
          name="lastName"
          label={t.lastNameLabel}
          placeholder={t.lastNamePlaceholder}
          validation={{ required: true }}
        />
        {!basicMode ? (
          <ListField
            name="roleId"
            label={t.rolesLabel}
            placeholder={t.rolesPlaceholder}
            options={data.roleOptions}
            validation={{ required: true }}
          />
        ) : null}
        <ListField
          name="profession"
          label={t.professionLabel}
          placeholder={t.professionPlaceholder}
          options={data.professionOptions}
          validation={{ required: true }}
        />
      </Section>

      {showMulticenter ? (
        <Section>
          <Section.Title>
            <FormattedMessage {...t.multicenterSection} />
          </Section.Title>

          <>
            <ListField
              name="permission"
              label={t.userPermission}
              options={userPermissionOptions(intl)}
              validation={{ required: true }}
            />
            {permission === 'center_based' ? (
              <ListField
                name="centerIds"
                multiple
                label={t.assignedCenters}
                placeholder={t.assignedCentersPlaceholder}
                options={data.centerOptions}
                validation={{ required: true }}
              />
            ) : null}
            {permission === 'integration' || permission === 'global' ? (
              <BaseFormField id="allCenters" label={t.assignedCenters}>
                <ListInput id="allCenters" value="all" onChange={() => {}} options={[allCenterOption]} disabled />
              </BaseFormField>
            ) : null}
          </>
        </Section>
      ) : null}

      {filteredIdentifierTypeAssignments.length ? (
        <Section>
          <Section.Title>
            <FormattedMessage {...t.identifiersSection} />
          </Section.Title>

          {filteredIdentifierTypeAssignments.map(({ identifierTypeId, identifierTypeName, required }) => (
            <TextField
              key={identifierTypeId}
              name={`identifiers.${identifierTypeId}`}
              label={identifierTypeName}
              placeholder={t.identifierPlaceholder}
              validation={{ required }}
            />
          ))}
        </Section>
      ) : null}

      <Section>
        <Section.Title>
          <FormattedMessage {...t.accountSection} />
        </Section.Title>

        <EmailField
          name="email"
          label={t.emailLabel}
          placeholder={t.emailPlaceholder}
          validation={{ required: true }}
          disabled={protectLDAPAttributes}
        />
        <TextField
          name="username"
          label={t.usernameLabel}
          placeholder={t.usernamePlaceholder}
          validation={{ required: true }}
          disabled={protectLDAPAttributes || basicMode}
        />
        {enforcePassword ? (
          <>
            <PasswordField
              name="password"
              label={t.passwordLabel}
              placeholder={t.passwordPlaceholder}
              validation={{ required: true }}
            />
            <PasswordField
              name="passwordConfirmation"
              label={t.passwordConfirmationLabel}
              placeholder={t.passwordConfirmationPlaceholder}
              validation={{ required: true }}
            />
          </>
        ) : null}
      </Section>
    </>
  );
}

function transformValues(values: FormState): TransformedFormState {
  return {
    ...values,
    identifiers: Object.entries(values.identifiers).map(([key, value]) => ({
      value: value || null,
      identifierTypeId: key,
    })),
  };
}

function useData({ userId, basicMode }: { userId: string | undefined; basicMode: boolean }) {
  const { data: user, isLoading: userIsLoading } = useQuery(['user', userId], () => fetchUser(userId!), {
    enabled: !!userId,
    cacheTime: 0,
  });

  const { data: centerOptions = [] } = useQuery(['centers'], () => fetchCenters(), {
    select: (data) => data.centers.map(labelize()),
  });

  const { data: roleOptions = [] } = useQuery(['roles'], () => fetchRoles(), {
    enabled: !basicMode,
    select: (roles) => roles.map(labelize()).sort(sortInline('label')),
  });

  const { data: professionOptions = [], isLoading: personTypesIsLoading } = useQuery(
    ['personTypes'],
    () => fetchPersonTypes(),
    {
      select: (personTypes) =>
        personTypes
          .sort(sortInline('name'))
          .filter((type) => type.isProfession)
          .map((personType) => ({
            value: personType.key,
            label: personType.name,
            personTypeId: personType.id,
          })),
    }
  );

  const { data: identifierTypeAssignments = [], isLoading: identifierTypeIsLoading } = useQuery(
    ['identifierTypeAssignments'],
    () => fetchIdentifierTypeAssignments(),
    { select: (data) => data.sort(sortInline(['primary', 'name'], ['desc', 'asc'])) }
  );

  return {
    user,
    centerOptions,
    roleOptions,
    professionOptions,
    identifierTypeAssignments,

    userIsLoading,
    personTypesIsLoading,
    identifierTypeIsLoading,
  };
}
