import { FormattedMessage } from 'react-intl';

import { useAuthenticationStrategy } from 'hooks/useConfig';

import { useAuth } from 'providers';

export default function HeaderSignOut() {
  const { logout } = useAuth();

  const authenticationStrategy = useAuthenticationStrategy();

  if (authenticationStrategy === 'krb') {
    return null;
  }

  return (
    <button type="button" className="header-link" onClick={logout}>
      <FormattedMessage id="navbar_signOut" defaultMessage="Sign out" />
    </button>
  );
}
