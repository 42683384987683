import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { SECOND_IN_MILLIS } from 'utils/date';
import { LocalStorage } from 'utils/storage';

import { useSessionTimeoutDuration, useSessionTimeoutEnabled } from 'hooks/useConfig';

import { useAuth } from 'providers';

import Modal from 'components/Modal';

const COUNTDOWN_SECONDS = 60;
const LAST_ACTIVITY_KEY = 'last_activity';
const ACTIVITY_EVENTS: (keyof WindowEventMap)[] = ['mousemove', 'mousedown', 'keydown'];

export default function SessionTimeout() {
  const sessionTimeoutEnabled = useSessionTimeoutEnabled();

  return sessionTimeoutEnabled ? <SessionTimeoutModal /> : null;
}

function SessionTimeoutModal() {
  const [show, setShow] = useState(false);
  const [seconds, setSeconds] = useState(COUNTDOWN_SECONDS);
  const [lastActivity, setLastActivity] = useState<number>(Date.now());

  const { logout } = useAuth();
  const sessionTimeoutDuration = useSessionTimeoutDuration();

  // Keep track of activity
  useEffect(() => {
    const onActivity = leadingMaxWaitDebounce(() => {
      if (!show) setLastActivity(Date.now());
    }, 1000);

    ACTIVITY_EVENTS.forEach((event) => window.addEventListener(event, onActivity));

    return () => {
      ACTIVITY_EVENTS.forEach((event) => window.removeEventListener(event, onActivity));
    };
  }, [show]);

  // Show/hide modal when needed
  useEffect(() => {
    const refreshModalVisibility = () => {
      if (Date.now() - lastActivity >= sessionTimeoutDuration) {
        if (!show) setShow(true);
      } else {
        if (show) setShow(false);
      }
    };

    refreshModalVisibility();
    const interval = window.setInterval(refreshModalVisibility, SECOND_IN_MILLIS);

    return () => window.clearInterval(interval);
  }, [sessionTimeoutDuration, show, lastActivity]);

  // Update countdown
  useEffect(() => {
    let interval: number | undefined;

    if (show) {
      setSeconds(COUNTDOWN_SECONDS);
      interval = window.setInterval(() => setSeconds((currentSeconds) => currentSeconds - 1), SECOND_IN_MILLIS);
    }

    return () => window.clearInterval(interval);
  }, [show, logout]);

  // Logout when timer reaches 0
  useEffect(() => {
    if (show && seconds === 0) logout();
  }, [show, seconds, logout]);

  // Listen to activity changes from different tabs
  useEffect(() => {
    return LocalStorage.listen<number>(LAST_ACTIVITY_KEY, (event) => {
      if (event.newValue) setLastActivity(event.newValue);
    });
  });

  // Keep local storage up-to-date
  useEffect(() => {
    LocalStorage.set(LAST_ACTIVITY_KEY, lastActivity);
  }, [lastActivity]);

  const onLogOut = () => {
    logout();
  };

  const onStayLoggedIn = () => {
    setShow(false);
    setLastActivity(Date.now());
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage {...t.title} />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FormattedMessage {...t.message} values={{ seconds, b: (chunks) => <b>{chunks}</b> }} />
        <br />
        <FormattedMessage {...t.callToAction} />
      </Modal.Body>

      <Modal.Footer>
        <Modal.CancelButton onClick={onLogOut}>
          <FormattedMessage {...t.logOut} />
        </Modal.CancelButton>

        <Modal.SubmitButton onClick={onStayLoggedIn} meaning="neutral">
          <FormattedMessage {...t.stayLoggedIn} />
        </Modal.SubmitButton>
      </Modal.Footer>
    </Modal>
  );
}

function leadingMaxWaitDebounce(fn: (...args: any[]) => void, wait: number) {
  let timeout: number | undefined;

  return (...args: any[]) => {
    if (timeout) return;
    fn(args);
    timeout = window.setTimeout(() => (timeout = undefined), wait);
  };
}

const t = defineMessages({
  title: {
    id: 'session_timeout_modal_title',
    defaultMessage: 'Your session is about to expire',
  },
  message: {
    id: 'session_timeout_modal_message',
    defaultMessage: 'You will be logged out in {seconds, plural, one {<b>#</b> second} other {<b>#</b> seconds}}.',
  },
  callToAction: {
    id: 'session_timeout_modal_call_to_action',
    defaultMessage: 'Do you want to stay logged in?',
  },
  logOut: {
    id: 'session_timeout_modal_log_out',
    defaultMessage: 'Log out',
  },
  stayLoggedIn: {
    id: 'session_timeout_modal_stay_logged_in',
    defaultMessage: 'Stay logged in',
  },
});
