import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { isMessageDescriptor } from 'utils/intl';

export interface TextProps {
  text: MessageDescriptor | string;
}

export function Text({ text }: TextProps) {
  if (isMessageDescriptor(text)) {
    return <FormattedMessage {...text} />;
  }

  return <>{text}</>;
}
