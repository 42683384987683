// TODO: Remove once ActivityPopover and Snippet no longer use `react-select` inputs or fields

import { createContext, useContext } from 'react';

export const SelectMenuPortalTargetContext = createContext<HTMLElement | null>(null);

export default SelectMenuPortalTargetContext.Provider;

export function useSelectMenuPortalTargetContext() {
  return useContext(SelectMenuPortalTargetContext);
}
