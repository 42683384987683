import { schema } from 'normalizr';

import { parseDate, parseDateTime, parseFloat, schemaDeserializers } from 'api/deserialize';

import { networkActionTypes } from 'store/utils';

const serializeDateArray = (dates: string[]) => dates.map(parseDate);

const medicationValidationSchema = new schema.Entity(
  'medicationValidations',
  {},
  {
    processStrategy: (validation) => ({
      ...validation,
      ...schemaDeserializers({
        performedAt: parseDateTime,
        suggestedAt: parseDateTime,
        updatedAt: parseDateTime,
      })(validation),
      performedBy: validation.performedBy
        ? {
            ...validation.performedBy,
            ...schemaDeserializers({
              createdAt: parseDateTime,
              updatedAt: parseDateTime,
            })(validation.performedBy),
          }
        : null,
      suggestedBy: validation.suggestedBy
        ? {
            ...validation.suggestedBy,
            ...schemaDeserializers({
              createdAt: parseDateTime,
              updatedAt: parseDateTime,
            })(validation.suggestedBy),
          }
        : null,
    }),
  }
);

// Intradialytic medication
// ==========================

const intradialyticMedicationSchema = new schema.Entity(
  'intradialyticMedication',
  {
    medicationValidation: medicationValidationSchema,
  },
  {
    processStrategy: (medication) => ({
      ...medication,
      ...schemaDeserializers({
        quantity: parseFloat,
        startDate: parseDateTime,
        endDate: parseDateTime,
        createdAt: parseDateTime,
        updatedAt: parseDateTime,
        dates: serializeDateArray,
      })(medication),
      prescriber: medication.prescriber
        ? {
            ...medication.prescriber,
            ...schemaDeserializers({
              createdAt: parseDateTime,
              updatedAt: parseDateTime,
            })(medication.prescriber),
          }
        : null,
      scheduleInterruption: medication.scheduleInterruption
        ? {
            ...medication.scheduleInterruption,
            ...schemaDeserializers({
              startDate: parseDateTime,
              endDate: parseDateTime,
              createdAt: parseDateTime,
              updatedAt: parseDateTime,
            })(medication.scheduleInterruption),
          }
        : null,
    }),
  }
);

// Ambulatory medication
// =================

const ambulatoryMedicationSchema = new schema.Entity(
  'ambulatoryMedication',
  {},
  {
    processStrategy: (medication) => ({
      ...medication,
      ...schemaDeserializers({
        quantity: parseFloat,
        startDate: parseDateTime,
        endDate: parseDateTime,
        updatedAt: parseDateTime,
        createdAt: parseDateTime,
        dates: serializeDateArray,
      })(medication),
      prescriber: medication.prescriber
        ? {
            ...medication.prescriber,
            ...schemaDeserializers({
              createdAt: parseDateTime,
              deletedAt: parseDateTime,
              updatedAt: parseDateTime,
            })(medication.prescriber),
          }
        : null,
    }),
  }
);

// Ambulatory medication schedule
// ==========================

const serializeIntakeMoment = (intakeMoment: any) => ({
  ...intakeMoment,
  quantity: parseFloat(intakeMoment.quantity),
  updatedAt: parseDateTime(intakeMoment.updateAt),
  createdAt: parseDateTime(intakeMoment.createdAt),
});

const ambulatoryMedicationScheduleSchema = new schema.Entity(
  'ambulatoryMedicationSchedule',
  {},
  {
    processStrategy: schemaDeserializers({
      intakeMoments: (val: any[]): any[] => val.map(serializeIntakeMoment),
      dates: serializeDateArray,
      quantity: parseFloat,
      updatedAt: parseDateTime,
      createdAt: parseDateTime,
      startDate: parseDate,
      endDate: parseDate,
    }),
  }
);

export const GET_AMBULATORY_MEDICATION_SCHEDULE = networkActionTypes('GET_AMBULATORY_MEDICATION_SCHEDULE');

export const getAmbulatoryMedicationSchedule = (patientId: string): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: GET_AMBULATORY_MEDICATION_SCHEDULE,
    url: `patients/${patientId}/home_medication_schedule`,
    method: 'GET',
    actionPayload: { patientId },
    normalizeSchema: {
      'medications/homeMedicationSchedule': [ambulatoryMedicationScheduleSchema],
    },
  },
});

export const UPDATE_AMBULATORY_MEDICATION_SCHEDULE = networkActionTypes('UPDATE_AMBULATORY_MEDICATION_SCHEDULE');

export const updateAmbulatoryMedicationSchedule = (patientId: string, schedule: any): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: UPDATE_AMBULATORY_MEDICATION_SCHEDULE,
    url: `patients/${patientId}/home_medication_schedule`,
    method: 'PUT',
    payload: {
      home_medication_schedule: schedule,
    },
    actionPayload: {
      patientId,
    },
    normalizeSchema: {
      'medications/homeMedicationSchedule': [ambulatoryMedicationScheduleSchema],
    },
  },
});

export const CHANGE_AMBULATORY_MEDICATION_SCHEDULE_QUANTITY = 'CHANGE_AMBULATORY_MEDICATION_SCHEDULE_QUANTITY';

export const changeIntakeMomentQuantity = (
  patientId: string,
  prescriptionId: string,
  moment: string,
  quantity: number
) => ({
  type: CHANGE_AMBULATORY_MEDICATION_SCHEDULE_QUANTITY,
  payload: { patientId, prescriptionId, moment, quantity },
});

export const DISCARD_PENDING_INTAKE_MOMENTS = 'DISCARD_PENDING_INTAKE_MOMENTS';

export const discardPendingIntakeMoments = (patientId: string) => ({
  type: DISCARD_PENDING_INTAKE_MOMENTS,
  payload: { patientId },
});

// Invalid prescriptions
// =======================

export const GET_INVALID_INTRADIALYTIC_PRESCRIPTIONS = networkActionTypes('GET_INVALID_INTRADIALYTIC_PRESCRIPTIONS');

export const getInvalidIntradialyticPrescriptions = (page = 0): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: GET_INVALID_INTRADIALYTIC_PRESCRIPTIONS,
    url: 'medications/mismatching_intradialytic_prescriptions',
    method: 'GET',
    params: { page, pageSize: 30 },
    normalizeSchema: { data: [intradialyticMedicationSchema] },
  },
});

export const GET_INVALID_AMBULATORY_PRESCRIPTIONS = networkActionTypes('GET_INVALID_AMBULATORY_PRESCRIPTIONS');

export const getInvalidAmbulatoryPrescriptions = (page = 0): NetworkAction => ({
  type: 'CALL_API',
  payload: {
    types: GET_INVALID_AMBULATORY_PRESCRIPTIONS,
    url: 'medications/mismatching_ambulatory_prescriptions',
    method: 'GET',
    params: { page, pageSize: 30 },
    normalizeSchema: { data: [ambulatoryMedicationSchema] },
  },
});
