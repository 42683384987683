import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useAllModulesEnabled, useManualPatientCreationEnabled, useRecordEnabled } from 'hooks/useConfig';

import { useCurrentUser } from 'providers';

import RequireAuth from 'sharedComponents/RequireAuth';
import RequireAuthRoute from 'sharedComponents/RequireAuthRoute';

import { RightClaim, RIGHTS } from 'rights';

const IamSettings = lazy(() => import('./iam'));
const InfraSettings = lazy(() => import('./infrastructure'));
const DeviceSettings = lazy(() => import('./devices'));
const ImportSettings = lazy(() => import('./imports'));
const DocumentTemplateSettings = lazy(() => import('./documentTemplates'));
const SystemConfiguration = lazy(() => import('./system'));
const Adt = lazy(() => import('./adt'));

export default function AdminRoutes() {
  const isManualPatientCreationEnabled = useManualPatientCreationEnabled();
  const recordEnabled = useRecordEnabled();
  const allModulesEnabled = useAllModulesEnabled();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <RequireAuth rights={[RIGHTS.MANAGE_SYSTEM]}>
              <Navigate to="devices" replace />
            </RequireAuth>

            <RequireAuth rights={[RIGHTS.MANAGE_CENTERS]}>
              <Navigate to="infrastructure" replace />
            </RequireAuth>

            <RequireAuth rights={[RIGHTS.MANAGE_USERS, RIGHTS.MANAGE_APPLICATION_DATA]}>
              <Navigate to="iam" replace />
            </RequireAuth>

            <RequireAuth booleanMatch={!allModulesEnabled}>
              <Navigate to="system" replace />
            </RequireAuth>
          </>
        }
      />

      <Route
        path="iam/*"
        element={
          <RequireAuthRoute rights={[RIGHTS.MANAGE_USERS, RIGHTS.MANAGE_APPLICATION_DATA]}>
            <IamSettings />
          </RequireAuthRoute>
        }
      />

      <Route
        path="infrastructure/*"
        element={
          <RequireAuthRoute rights={[RIGHTS.MANAGE_CENTERS]}>
            <InfraSettings />
          </RequireAuthRoute>
        }
      />

      <Route
        path="devices/*"
        element={
          <RequireAuthRoute rights={[RIGHTS.MANAGE_SYSTEM, RIGHTS.MANAGE_APPLICATION_DATA]}>
            <DeviceSettings />
          </RequireAuthRoute>
        }
      />

      {!isManualPatientCreationEnabled ? (
        <Route
          path="adt"
          element={
            <RequireAuthRoute rights={[RIGHTS.MANAGE_APPLICATION_DATA]}>
              <Adt />
            </RequireAuthRoute>
          }
        />
      ) : null}

      <Route
        path="imports"
        element={
          <RequireAuthRoute rights={[RIGHTS.MANAGE_APPLICATION_DATA]}>
            <ImportSettings />
          </RequireAuthRoute>
        }
      />

      {recordEnabled && (
        <Route
          path="document_templates/*"
          element={
            <RequireAuthRoute rights={[RIGHTS.MANAGE_APPLICATION_DATA]}>
              <DocumentTemplateSettings />
            </RequireAuthRoute>
          }
        />
      )}

      <Route
        path="system"
        element={
          <RequireAuthRoute rights={[RIGHTS.MANAGE_SYSTEM, RIGHTS.MANAGE_APPLICATION_DATA]}>
            <SystemConfiguration />
          </RequireAuthRoute>
        }
      />

      <Route path="*" element={<Navigate to="/not_found" replace />} />
    </Routes>
  );
}

export const adminRights: RightClaim[] = [
  RIGHTS.MANAGE_SYSTEM,
  RIGHTS.MANAGE_APPLICATION_DATA,
  RIGHTS.MANAGE_USERS,
  RIGHTS.MANAGE_CENTERS,
];

export function useAdminEnabled(rights: RightClaim[]) {
  const currentUser = useCurrentUser();
  const userRights = currentUser.rights.map((right) => right.name);

  return rights.some((right) => userRights.includes(right));
}
