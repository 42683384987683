import { defineMessages, useIntl } from 'react-intl';

const t = defineMessages({
  year: {
    id: 'age_year',
    defaultMessage: 'years old',
  },
});

export default function Age({ birthdate }: { birthdate?: Date }) {
  const { formatMessage } = useIntl();

  if (!birthdate) return null;

  const ageDiffMs = Date.now() - birthdate.getTime();
  const ageDate = new Date(ageDiffMs);

  return <span>{`${Math.abs(ageDate.getUTCFullYear() - 1970)} ${formatMessage(t.year)}`}</span>;
}
