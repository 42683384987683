import { getInt } from 'styles/vars';

export default function Placeholder() {
  const borderRadius = getInt('--size-border-radius');

  return (
    <svg className="rte-placeholder">
      <rect x="2" y="2" width="24" height="24" rx={borderRadius} />
      <rect x="34" y="2" width="24" height="24" rx={borderRadius} />
      <rect x="66" y="2" width="24" height="24" rx={borderRadius} />
      <rect x="98" y="2" width="24" height="24" rx={borderRadius} />
      <rect x="130" y="2" width="24" height="24" rx={borderRadius} />
      <rect x="162" y="2" width="24" height="24" rx={borderRadius} />

      <rect x="0" y="32" width="100%" height="1" />
      <rect x="12" y="56" width="50%" height="14" rx={borderRadius} />
      <rect x="12" y="84" width="80%" height="14" rx={borderRadius} />
      <rect x="12" y="112" width="20%" height="14" rx={borderRadius} />
    </svg>
  );
}
