import cx from 'classnames';
import { ReactNode } from 'react';

type CustomProps = {
  className?: string;
  children: ReactNode;
};

type HtmlProps = Omit<JSX.IntrinsicElements['td'], keyof CustomProps>;

type Props = CustomProps & HtmlProps;

export default function TableFullWidthCell({ className, children, ...props }: Props) {
  return (
    <td {...props} colSpan={99} className={cx('table-cell', '-span-all-cols', className)}>
      {children}
    </td>
  );
}
