import { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { useSlate } from 'slate-react';

import Icon from 'components/Icon';

import t from '../translations';
import Button from './Button';
import HeadingButton from './HeadingButton';
import Popout from './Popout';

interface Props {
  headings: (1 | 2 | 3 | 4 | 5 | 6)[];
}

export default function HeadingRow({ headings }: Props) {
  const { formatMessage } = useIntl();
  const editor = useSlate();

  return (
    <Popout isActive={editor.isHeadingActive()} buttonTitle={formatMessage(t.headings)} buttonIcon="title">
      <Button
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          editor.removeHeading();
        }}
        title={formatMessage(t.removeHeading)}
      >
        <Icon>block</Icon>
      </Button>

      {headings.map((level) => (
        <HeadingButton key={level} level={`heading-${level}` as any} />
      ))}
    </Popout>
  );
}
