import { decamelizeKeys } from 'utils/camelize';
import searchString from 'utils/searchString';

export const BASE_PATH = '/api/';

export default function apiUrl(path?: string, params?: Record<string, any>) {
  if (!path) {
    path = '';
  }

  if (!path.startsWith('http') && !path.startsWith(BASE_PATH)) {
    if (!path.startsWith('/')) {
      path = `${BASE_PATH}${path}`;
    } else {
      path = `${BASE_PATH.slice(0, -1)}${path}`;
    }
  }

  if (path.endsWith('?') || path.endsWith('/')) {
    path = path.slice(0, -1);
  }

  if (params) {
    path = path.split('?')[0];

    const search = searchString.encode(decamelizeKeys(params));

    if (search) {
      path = `${path}?${search}`;
    }
  }

  return path;
}
