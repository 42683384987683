import { http } from 'api/client';
import { deserialize, parseDateTime } from 'api/deserialize';

export type UserPermissionT = 'center_based' | 'global' | 'integration';

export interface UserCompactT {
  id: string;
  email: string;
  title: string;
  firstName: string;
  lastName: string;
  username: string;
  avatarUrl: string;
  avatarThumbUrl: string;
  permission: UserPermissionT;
}

export async function fetchUser(id: string) {
  const { data } = await http.get<{ user: UserT }>(`users/${id}`);

  return deserializeUser(data.user);
}

export function deserializeUser<T extends UserT | UserCompactT>(user: T): T {
  return deserialize(user, {
    'createdAt': parseDateTime,
    'updatedAt': parseDateTime,
    'deletedAt': parseDateTime,
    'avatarUpdatedAt': parseDateTime,
    'identifiers[].updatedAt': parseDateTime,
  });
}
