const RIGHTS = {
  VIEW_RECORD: 'VIEW_RECORD',

  // Admin
  MANAGE_SYSTEM: 'MANAGE_SYSTEM',
  MANAGE_USERS: 'MANAGE_USERS',
  MANAGE_CENTERS: 'MANAGE_CENTERS',
  MANAGE_APPLICATION_DATA: 'MANAGE_APPLICATION_DATA',

  // Patient record
  EDIT_ADMINISTRATIVE_RECORD: 'EDIT_ADMINISTRATIVE_RECORD',
  EDIT_DIET_RECORD: 'EDIT_DIET_RECORD',
  EDIT_MEDICAL_RECORD: 'EDIT_MEDICAL_RECORD',
  EDIT_NURSING_RECORD: 'EDIT_NURSING_RECORD',
  ENROLL_PATIENT: 'ENROLL_PATIENT',

  // Dialysis
  MANAGE_DIALYSIS_PRESCRIPTION: 'MANAGE_DIALYSIS_PRESCRIPTION',
  PERFORM_SESSION: 'PERFORM_SESSION',
  SIGN_SESSION: 'SIGN_SESSION',

  // Intradialytic medication prescription rights
  MANAGE_INTRADIALYTIC_PRESCRIPTION: 'MANAGE_INTRADIALYTIC_PRESCRIPTION',
  MANAGE_NO_VALIDATION_INTRADIALYTIC_PRESCRIPTION: 'MANAGE_NO_VALIDATION_INTRADIALYTIC_PRESCRIPTION',
  MANAGE_PRE_VALIDATION_INTRADIALYTIC_PRESCRIPTION: 'MANAGE_PRE_VALIDATION_INTRADIALYTIC_PRESCRIPTION',
  MANAGE_POST_VALIDATION_INTRADIALYTIC_PRESCRIPTION: 'MANAGE_POST_VALIDATION_INTRADIALYTIC_PRESCRIPTION',
  MANAGE_INTRADIALYTIC_PRESCRIPTION_INTERRUPTION: 'MANAGE_INTRADIALYTIC_PRESCRIPTION_INTERRUPTION',
  VALIDATE_MEDICATION: 'VALIDATE_MEDICATION',

  // Intradialytic medication administration rights
  MANAGE_VALIDATED_INTRADIALYTIC_ADMINISTRATION: 'MANAGE_VALIDATED_INTRADIALYTIC_ADMINISTRATION',
  MANAGE_POST_VALIDATION_INTRADIALYTIC_ADMINISTRATION: 'MANAGE_POST_VALIDATION_INTRADIALYTIC_ADMINISTRATION',

  // Ambulatory medication prescription rights
  MANAGE_AMBULATORY_MEDICATION: 'MANAGE_AMBULATORY_MEDICATION',
  MANAGE_AMBULATORY_MEDICATION_SCHEDULE: 'MANAGE_AMBULATORY_MEDICATION_SCHEDULE',
  EXPORT_AMBULATORY_MEDICATION_SCHEDULE: 'EXPORT_AMBULATORY_MEDICATION_SCHEDULE',
  MANAGE_AMBULATORY_MEDICATION_PRESCRIPTION: 'MANAGE_AMBULATORY_MEDICATION_PRESCRIPTION',
  EXPORT_AMBULATORY_MEDICATION_PRESCRIPTION: 'EXPORT_AMBULATORY_MEDICATION_PRESCRIPTION',

  // Orders
  DESTROY_ORDER: 'DESTROY_ORDER',
  PLACE_ORDER: 'PLACE_ORDER',

  // Wound care
  MANAGE_WOUNDCARE_PRESCRIPTION: 'MANAGE_WOUNDCARE_PRESCRIPTION',

  // Journal
  MANAGE_MEDICAL_JOURNAL: 'MANAGE_MEDICAL_JOURNAL',
  MANAGE_NURSING_JOURNAL: 'MANAGE_NURSING_JOURNAL',
  MANAGE_DIETARY_JOURNAL: 'MANAGE_DIETARY_JOURNAL',
  MANAGE_PSYCHOLOGICAL_JOURNAL: 'MANAGE_PSYCHOLOGICAL_JOURNAL',
  MANAGE_SOCIAL_JOURNAL: 'MANAGE_SOCIAL_JOURNAL',
  READ_MEDICAL_JOURNAL: 'READ_MEDICAL_JOURNAL',
  READ_NURSING_JOURNAL: 'READ_NURSING_JOURNAL',
  READ_DIETARY_JOURNAL: 'READ_DIETARY_JOURNAL',
  READ_PSYCHOLOGICAL_JOURNAL: 'READ_PSYCHOLOGICAL_JOURNAL',
  READ_SOCIAL_JOURNAL: 'READ_SOCIAL_JOURNAL',
  EHR_PUBLISH_JOURNAL: 'EHR_PUBLISH_JOURNAL',

  // Observation
  CREATE_OBSERVATION: 'CREATE_OBSERVATION',

  // Planning
  VIEW_PLANNING: 'VIEW_PLANNING',
  MANAGE_PLANNING: 'MANAGE_PLANNING',

  // Exports
  MANAGE_EXPORTS: 'MANAGE_EXPORTS',
  MANAGE_EXPORT_RULES: 'MANAGE_EXPORT_RULES',

  // Patient agenda
  PATIENT_AGENDA_FULL_DETAIL_VIEW: 'PATIENT_AGENDA_FULL_DETAIL_VIEW',
  MANAGE_APPOINTMENT_CONFLICTS: 'MANAGE_APPOINTMENT_CONFLICTS',

  // Letters
  MANAGE_PATIENT_LETTER: 'MANAGE_PATIENT_LETTER',
  VALIDATE_PATIENT_LETTER: 'VALIDATE_PATIENT_LETTER',

  // TRANSPORTATION
  MANAGE_TRANSPORTATION_REGISTRATION: 'MANAGE_TRANSPORTATION_REGISTRATION',
  MANAGE_TRANSPORTATION_PRESCRIPTION: 'MANAGE_TRANSPORTATION_PRESCRIPTION',
  MANAGE_TRANSPORTATION_DOCUMENT: 'MANAGE_TRANSPORTATION_DOCUMENT',

  // Sessions
  MANAGE_SESSION_INFO: 'MANAGE_SESSION_INFO',
} as const;

export type RightClaim = keyof typeof RIGHTS;

export { RIGHTS };
