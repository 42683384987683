import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import { useAuthenticator } from 'auth/authenticator';
import { retrieveToken } from 'auth/tokenStorage';
import { AuthCredentials, UserToken } from 'auth/types';
import { authenticated, getJwtUserId } from 'auth/utils';

import { httpInterceptor } from 'api/client';

import assert from 'utils/assert';
import { LocalStorage, SessionStorage } from 'utils/storage';

interface AuthContextState {
  login: (credentials: AuthCredentials) => Promise<void>;
  logout: () => void;
  currentUserId: string | undefined;
  accessToken: string | null;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextState | undefined>(undefined);

AuthContext.displayName = 'AuthContext';

export default function AuthProvider({ children }: { children: ReactNode }) {
  const { userToken, isReady, login, logout } = useAuthSetup();

  const authValues = {
    login,
    logout,
    currentUserId: getJwtUserId(userToken),
    accessToken: userToken?.access || null,
    isAuthenticated: authenticated(userToken),
  };

  if (!isReady) {
    return null;
  }

  return <AuthContext.Provider value={authValues}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);

  assert(context, 'The hook `useAuth` must be used within an `AuthProvider`.');

  return context;
}

function useAuthSetup() {
  const authenticator = useAuthenticator();

  //Add this check, because interceptor was not yet set when fetching current user
  const [isReady, setIsReady] = useState(false);
  const [userToken, setUserToken] = useState<UserToken>(retrieveToken());

  const login = useCallback(
    async (credentials: AuthCredentials) => {
      const token = await authenticator().login(credentials);
      setUserToken(token);
    },
    [authenticator]
  );

  const logout = useCallback(async () => {
    await authenticator().logout();
    SessionStorage.clear();
    goToLogin();
  }, [authenticator]);

  useEffect(() => {
    return LocalStorage.listen<UserToken>('user_token', (event) => {
      const token = event.newValue;
      if (token) {
        setUserToken(token);
      } else {
        goToLogin();
      }
    });
  }, []);

  useEffect(() => {
    const refreshSuccess = (token: UserToken) => {
      setUserToken((prevToken) => ({
        ...prevToken,
        ...token,
      }));
    };

    httpInterceptor(refreshSuccess, goToLogin);
    setIsReady(true);
  }, []);

  return {
    isReady,
    userToken,
    login,
    logout,
  };
}

function goToLogin() {
  window.location.assign('/login');
}
