import { FunctionComponent, ReactNode } from 'react';

import { CLASSNAME } from '../constants';
import { SelectInputOption, Value } from '../types';

export interface SingleValueProps {
  value: Value | null;
  options: SelectInputOption[];
  children?: ReactNode;
}

export const SingleValue: FunctionComponent<SingleValueProps> = ({ value, options, children }) => {
  const option = options.find((o) => o.value === value);
  const label = option?.label || option?.value || value;

  return (
    <div className={`${CLASSNAME}__single-value`}>{children || (!value || Array.isArray(value) ? null : label)}</div>
  );
};
