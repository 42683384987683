import cx from 'classnames';
import { components, MultiValueGenericProps } from 'react-select';

import { ListOption } from 'components/Inputs/ListInput';

export default function MultiValueContainer(props: MultiValueGenericProps<any>) {
  const option = props.data as ListOption;
  const isDisabled = option.disabled === true;

  return (
    <components.MultiValueContainer
      {...props}
      innerProps={{
        ...props.innerProps,
        className: cx(props.innerProps.className, {
          'select__multi-value--is-disabled': isDisabled,
        }),
      }}
    >
      {props.children}
    </components.MultiValueContainer>
  );
}
