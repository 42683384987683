import cx from 'classnames';
import { CSSProperties, ReactNode } from 'react';

import { resetError } from 'store/modules/errors';
import { resetSuccess } from 'store/modules/success';

import { useAppDispatch } from 'hooks/redux';

import Icon from 'components/Icon';

interface FlashProps {
  list?: ReactNode[];
  children?: ReactNode;
  type?: AppColor;
  icon?: string;
  style?: CSSProperties;
  close?: boolean;
  onClose?: () => void;
  className?: string;
}

export default function Flash(props: FlashProps) {
  const dispatch = useAppDispatch();

  const { icon, children, type = 'danger', list, style, close = true, onClose: externalOnClose, className } = props;

  const onClose = () => {
    if (externalOnClose) {
      externalOnClose();
    } else {
      dispatch(resetError());
      dispatch(resetSuccess());
    }
  };

  if (!children && (!list || list.length === 0)) return null;

  return (
    <div
      className={cx('alert', className, `-type-${type}`, {
        '-has-close-button': close,
      })}
      style={style}
      role="alert"
    >
      <Icon>{icon || iconMap[type]}</Icon>
      <div className="alert__content">
        {children}
        {list && list.length > 0 ? (
          <ul>
            {list.map((node, index) => (
              <li key={index}>{node}</li>
            ))}
          </ul>
        ) : null}
      </div>
      {close ? (
        <button type="button" className="alert__close-button" onClick={onClose}>
          <Icon>clear</Icon>
        </button>
      ) : null}
    </div>
  );
}

const iconMap: Record<AppColor, string> = {
  info: 'info',
  success: 'check_circle',
  warning: 'warning',
  danger: 'error',
};
