import { fromJS } from 'immutable';

import { ATTACH_ENTITY_CODE, DETACH_ENTITY_CODE, GET_ENTITY_CODES } from 'store/modules/entities/actions/entityCodes';

const BY_ID = ['entityCodes', 'byId'];
const BY_ENTITY = ['entityCodes', 'byEntity'];

export default function entityCodesReducer(state: any, action: any) {
  switch (action.type) {
    case GET_ENTITY_CODES.SUCCESS: {
      const {
        response: {
          entities: { codes },
          result: { codes: codeIds },
        },
        codable,
      } = action.payload;

      return state.mergeDeepIn([...BY_ID], fromJS(codes)).setIn([...BY_ENTITY, codable.id], fromJS(codeIds || []));
    }

    case ATTACH_ENTITY_CODE.SUCCESS: {
      const {
        response: {
          entities: { codes },
          result: { code: codeId },
        },
        codable,
      } = action.payload;

      const codeIds = state.getIn([...BY_ENTITY, codable.id]).push(codeId);

      return state.mergeDeepIn([...BY_ID], fromJS(codes)).setIn([...BY_ENTITY, codable.id], fromJS(codeIds));
    }

    case DETACH_ENTITY_CODE.SUCCESS: {
      const {
        payload: { codableId, codeId },
      } = action;

      const codeIds = state.getIn([...BY_ENTITY, codableId]).filter((id) => id !== codeId);

      return state.deleteIn([...BY_ID, codeId]).setIn([...BY_ENTITY, codableId], codeIds);
    }

    default: {
      return state;
    }
  }
}

export const entityCodesSelector = (state: any, codableId: string): EntityCodeT[] | null | undefined => {
  const isFetching = !!state.network.GET_ENTITY_CODES || state.network.GET_ENTITY_CODES === undefined;

  if (isFetching) return undefined;

  const {
    entityCodes: { byId, byEntity },
  } = state.entities.toJS();

  const codeIds = byEntity[codableId];

  if (!codeIds) return undefined;

  return codeIds.map((id) => byId[id]);
};
